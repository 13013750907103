import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { authorizeDgets } from "utils/dgetsAuth";
import { useStyles } from "./styles";
import { Container, Box, Grid, useMediaQuery } from "@mui/material";
import logo from "assets/img/logo_white.svg";
import logoBalti from "assets/img/logo_balti_footer.png";
import logoUngheni from "assets/img/logo_ungheni_footer.png";
import primariaLogo from "assets/img/primaria_logo_white.svg";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Footer = () => {
  const currentInfoContext = useContext(CurrentInfoContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const isMob = useMediaQuery("(max-width: 960px)");
  const statistics = currentInfoContext.currentInfo?.statistics;
  const isAnonymous = currentInfoContext.roles.isAnonymous();

  const logoFooter =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? logoBalti
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? logoUngheni
      : logo;

  const maxWidth = process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1330px" : "1280px";
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const ungheniMarginTop = process.env.REACT_APP_BRANCH_NAME === "ungheni" && isMainPage;
  
  return (
    <Box className={classes.root} mt={ungheniMarginTop ? 26 : 'auto'}>
      <Container maxWidth={false} sx={{ maxWidth: maxWidth }}>
        <Grid container spacing={isMob ? 1 : 6}>
          <Grid
            item
            md={3}
            xs={12}
            className={classes.imgsMaxWidth}
            ml={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1 : 0}
          >
            {!isMob && <img src={logoFooter} className={classes.logo} alt="logoFooter" />}
            {process.env.REACT_APP_BRANCH_NAME !== "balti" && process.env.REACT_APP_BRANCH_NAME !== "ungheni" && (
              <img src={primariaLogo} className={classes.pLogo} alt="logoFooter" />
            )}
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1.1 : 0}
            ml={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1 : 0}
          >
            <div className={classes.title}>{t("Cereri noi")}</div>
            <div className={classes.list}>
              <div className={classes.listRow}>
                <span>{t("Azi")}:</span>
                <span>{statistics?.requestsNewToday}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Această săptămână")}:</span>
                <span>{statistics?.requestsNewWeek}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Această lună")}:</span>
                <span>{statistics?.requestsNewMonth}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Acest an")}:</span>
                <span>{statistics?.requestsNewYear}</span>
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1.1 : 0}
            ml={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1 : 0}
          >
            <div className={classes.title}>{t("Cereri acceptate")}</div>
            <div className={classes.list}>
              <div className={classes.listRow}>
                <span>{t("Azi")}:</span>
                <span>{statistics?.requestsRegisteredToday}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Această săptămână")}:</span>
                <span>{statistics?.requestsRegisteredWeek}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Această lună")}:</span>
                <span>{statistics?.requestsRegisteredMonth}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Acest an")}:</span>
                <span>{statistics?.requestsRegisteredYear}</span>
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1.1 : 0}
            ml={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -1 : 0}
          >
            <div className={classes.title}>{t("Alte statistici")}</div>
            <div className={classes.list}>
              <div className={classes.listRow}>
                <span>{t("Total cereri înregistrate de la trecerea online")}</span>
                <span>{statistics?.totalRequests}</span>
              </div>
              {/* <div className={classes.listRow}>
                <span>{t("de la trecerea online")}:</span>
              </div> */}
              <div className={classes.listRow}>
                <span>{t("Total acceptate în rând")}:</span>
                <span>{statistics?.totalQueue}</span>
              </div>
              <div className={classes.listRow}>
                <span>{t("Total înmatriculați acest an")}:</span>
                <span>{statistics?.totalYear}</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Box className={classes.menu} mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? 5 : 0}>
          <Link to="/institutions" className={classes.menuItem}>
            {t("Grădinițe")}
          </Link>
          <Link to={isAnonymous ? "/login" : "/"} className={classes.menuItem}>
            {t("Cabinet")}
          </Link>
          <Link to="/raport" className={classes.menuItem}>
            {t("Raportează abuz")}
          </Link>
          <Link to="/contacts" className={classes.menuItem}>
            {t("Contacte")}
          </Link>
          <Link to="/reglament" className={classes.menuItem}>
            {t("Termeni și Condiții")}
          </Link>
          <Link to="#" className={classes.menuItem} onClick={authorizeDgets}>
            {process.env.REACT_APP_BRANCH_NAME !== "balti" &&
              process.env.REACT_APP_BRANCH_NAME !== "ungheni" &&
              t("Autorizare DGETS")}
            {process.env.REACT_APP_BRANCH_NAME === "balti" && t("Autorizare DÎTS")}
            {process.env.REACT_APP_BRANCH_NAME === "ungheni" && t("Autorizare Mpass")}
          </Link>
        </Box>
        <Box className={classes.copyright}>© 2023 {t("elaborat de")} Studii.md</Box>
      </Container>
    </Box>
  );
};
