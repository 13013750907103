import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  tabs: {
    display: "flex",
    marginBottom: 50,
    "& .Mui-selected $tabCount": {
      color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
    },
    "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginLeft: 5,
      fontSize: 20,
    },
    "& .MuiTab-labelIcon": {
      fontSize: 22,
      flex: "auto",
      whiteSpace: "nowrap",
      lineHeight: 1.2,
    },
  },
  tabCount: {
    color: theme.colors.darkGray,
  },
}));
