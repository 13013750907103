import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    marginBottom: 20,
  },
  formGroup: {
    "@media (min-width: 961px)": {
      marginBottom: 30,
    },
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  requests: {
    marginTop: 20,
  },
}));
