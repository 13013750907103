import common from "../index";

const translationBalti = {
  nameSpaceRo: {
    ...common.nameSpaceRo,
    loginAuthLinkTitle: "DÎTS",
  },
  nameSpaceRu: {
    ...common.nameSpaceRu,
    loginAuthLinkTitle: "DÎTS",
  },
};

export default translationBalti;
