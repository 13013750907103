import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { EgButton } from "components/ui/button";
import { useStyles } from "./styles";

interface IProps {
  onRemove: () => void;
  onClose: () => void;
}

export const RemovePopup: FC<IProps> = ({ onRemove, onClose }) => {
  const c = useStyles();
  const { t } = useTranslation();

  return (
    <div className={c.dimmer}>
      <div className={c.popup}>
        <h3>{t("Sunteti sigur ca vreti sa stergeti administratorul")}</h3>
        <div className={c.btns}>
          <EgButton variant="contained" text={t("Sterge")} onClick={onRemove} className={c.btn} />
          <EgButton variant="outlined" text={t("Anulează")} onClick={onClose} className={c.btn} />
        </div>
      </div>
    </div>
  );
};
