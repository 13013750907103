import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    position: "relative",
    background: theme.colors.secondary,
    marginBottom: 20,
    color: "white",
    height: 183,
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    marginBottom: 5,
    "@media (min-width: 961px)": {
      fontSize: 48,
    },
    "@media (max-width: 960px)": {
      fontSize: 36,
    },
  },
  subtitle: {
    "@media (min-width: 961px)": {
      fontSize: 28,
    },
    "@media (max-width: 960px)": {
      fontSize: 24,
    },
  },
  ico: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  ct: {
    height: "100%",
    alignItems: "flex-start",
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    "@media (min-width: 961px)": {
      backgroundPosition: "top right",
    },
    "@media (max-width: 960px)": {
      backgroundSize: 150,
      backgroundPosition: "calc(100% + 30px) calc(100% + 30px)",
    },
  },
}));
