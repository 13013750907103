import React, { useMemo, useState } from "react";
import { Container, Grid, Link } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { apiRootUrl } from "utils/network";
import { EgSelect } from "components/ui/select";

const Raports: React.FC<{}> = () => {
  const currentYear = new Date().getFullYear();

  const [requestCreationYear, setRequestCreationYear] = useState<number>(() => currentYear);

  const classes = useStyles();
  const { t } = useTranslation();

  const requestCreationYearOptions = useMemo(() => {
    let birthYears = [] as number[];

    for (let i = 0; i < 8; i++) {
      birthYears.push(currentYear - i);
    }

    return birthYears;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRequestCreationYear = (requestCreationYear: number) => {
    setRequestCreationYear(requestCreationYear);
  };

  const downloadRequestAccepted = () => {
    window.open(`${apiRootUrl}/report/request_accepted/${requestCreationYear}`, "blank");
  };

  const downloadRequestTotals = () => {
    window.open(`${apiRootUrl}/report/request_totals/${requestCreationYear}`, "blank");
  };

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={classes.root}>
      <div className={classes.title}>{t("Rapoarte")}</div>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={4} sm={6} xs={12}>
          <div className={classes.formGroup}>
            <div className={classes.formGroupLabel}>{t("Anul depunerii cererii")}</div>
            <EgSelect
              value={requestCreationYear}
              onChangeValue={changeRequestCreationYear}
              options={requestCreationYearOptions.map((birthYear) => ({
                value: birthYear,
                text: birthYear.toString(),
              }))}
              error=""
            />
          </div>
        </Grid>
      </Grid>
      <ul className={classes.raports}>
        <li>
          <Link href="#" onClick={downloadRequestAccepted}>
            {t("Raportul de înmatriculare a copiilor pe sector")}
          </Link>
        </li>
        <li>
          <Link href="#" onClick={downloadRequestTotals}>
            {t("Raportul privind toate cererile înregistrate în sistem")}
          </Link>
        </li>
      </ul>
    </Container>
  );
};

export default Raports;
