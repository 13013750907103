import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    display: "flex",
  },
  language: {
    color: theme.colors.default,
    transition: "color .2s",
    fontSize: 18,
    fontWeight: 700,
    textTransform: "uppercase",
    marginLeft: 10,
    cursor: "pointer",
    "&.selected": {
      color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
    },
  },
}));
