import React, { ChangeEvent, useContext, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Checkbox, Container, FormControlLabel, Grid } from "@mui/material";
import { EgInput } from "components/ui/input";
import { EgButton } from "components/ui/button";
import { useMutation } from "@apollo/client";
import INSTITUTION_CREATE_OR_UPDATE from "main/requests/mutation/CreateOrUpdateInstitution";
import removeTypename from "utils/helpers/removeTypename";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useMessage from "main/hooks/useMessage";
import clsx from "clsx";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
interface IProps {
  id: any;
  data: any;
}

const schema = yup.object().shape({
  name: yup.string().min(8).required(),
  phone: yup.string().min(2).required(),
  address: yup.string().min(2).required(),
  //methodistEmail: yup.string().email().required(),
  district: yup.string().required(),
});

export const toCheckedList = (array: any, language: string) => {
  return array.map((item: any) => {
    if (language === "both") {
      return { ...item, isChecked: true };
    }
    const value = item.value.toLowerCase() === language;
    return { ...item, isChecked: value };
  });
};

const InstitutionForm: React.FC<IProps> = ({ id, data }) => {
  const c = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentInfoContext = useContext(CurrentInfoContext);
  const {
    currentInfo: { districts },
  } = currentInfoContext;

  const [checkbox, setCheckbox] = useState(
    toCheckedList(
      [
        { id: 1, value: t("ro").toUpperCase() },
        { id: 2, value: t("ru").toUpperCase() },
      ],
      data.language
    )
  );
  const [places, setPlaces] = useState(data.places.map((item: any) => removeTypename(item)));
  const [grades, setGrades] = useState(removeTypename(data.grades));
  const [infrastructure, setInfrastructure] = useState(data.infrastructure);
  const [addresses, setAddresses] = useState(data.addresses);

  const showMessage = useMessage();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data.name,
      phone: data.phone,
      address: data.address,
      district: data.district,
    },
  });

  const defineLanguage = () => {
    const both = checkbox.every((item: any) => item.isChecked);
    const selected = checkbox.find((item: any) => item.isChecked);
    if (both) {
      return "both";
    }
    if (selected) {
      return selected.value.toLowerCase();
    }
    return null;
  };

  const onSubmit = async (data: any) => {
    const variables = {
      ...data,
      id,
      language: defineLanguage(),
      places,
      grades,
      infrastructure: infrastructure,
      addresses: addresses,
    };
    try {
      const response = await saveForm({
        variables: variables,
      });

      if (!response.errors) {
        showMessage("Updated!", "success");
      } else {
        showMessage(response.errors[0].message);
      }
    } catch (e) {
      showMessage(e.message);
    }
  };

  const [saveForm] = useMutation(INSTITUTION_CREATE_OR_UPDATE, {
    update(cache, { data }) {
      navigate(`/institution/${data.institutionCreateOrUpdate.institution.id}`);
    },
  });

  const handlePlacesChange = (year: string, language: string) => (value: any) => {
    setPlaces((prevState: any) => {
      return prevState.map((place: any) => {
        if (place.year === year && place.language === language) {
          return { ...place, count: Number(value) };
        }
        return place;
      });
    });
  };

  const handleGradesChange = (grad: any) => (value: any) => {
    setGrades((prevState: any) => ({
      ...prevState,
      [grad]: Number(value),
    }));
  };

  const handleInfrastructureChange = (index: number) => (value: any) => {
    setInfrastructure(changeField(index)(value));
  };

  const handleAddressesChange = (index: number) => (value: string) => {
    setAddresses(changeField(index)(value));
  };

  const changeField = (_index: number) => (value: any) => (prevState: any) => {
    return prevState.map((a: any, index: number) => {
      if (_index === index) {
        return value;
      }
      return a;
    });
  };

  const addField = (prevState: any) => {
    if (!prevState.length) {
      return [""];
    }
    if (!prevState[prevState.length - 1]) {
      return [...prevState];
    }
    return [...prevState, ""];
  };

  const removeField = (index: number) => (prevState: any) => {
    return prevState.filter((s: any, _index: number) => _index !== index);
  };

  const handleAddInfrastructure = () => {
    setInfrastructure(addField);
  };

  const handleInfrastructureRemove = (id: number) => () => {
    setInfrastructure(removeField(id));
  };

  const handleAddAddresses = () => {
    setAddresses(addField);
  };

  const handleAddressesRemove = (id: number) => () => {
    setAddresses(removeField(id));
  };

  const handleCheckbox = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setCheckbox((prevState: any) => {
      return prevState.map((item: { value: string; isChecked: boolean }) => {
        if (item.value === target.value) {
          return { ...item, isChecked: target.checked };
        }
        return item;
      });
    });
  };

  const renderFields = (arr: any, change: any, remove: any) => {
    return arr.map((a: any, index: number) => (
      <Grid item sm={6} md={4} xs={12} key={index}>
        <div className={c.inputGroup}>
          <EgInput value={a} onChangeValue={change(index)} placeholder={t("Introduceți")} error={undefined} />
          <div className={c.removeInputGroup} onClick={remove(index)} />
        </div>
      </Grid>
    ));
  };

  const availableLanguages = checkbox.filter((item) => item?.isChecked).map((item) => item?.value.toLowerCase());

  const placesWithSpecificLanguage = places.filter((item) => availableLanguages.includes(item?.language));

  const renderPlaces = placesWithSpecificLanguage.map((p: any) => (
    <Grid item sm={6} md={4} xs={12} key={`${p?.year}-${p.language}`}>
      <div className={c.formGroup}>
        <div className={c.formGroupLabel}>
          {`${t("Locuri p-u născuți în")} ${p.year} ${p?.language?.toUpperCase()}`}
        </div>
        <EgInput
          value={p.count}
          onChangeValue={handlePlacesChange(p?.year, p?.language)}
          placeholder={t("Introduceți")}
          error={undefined}
          type="number"
          onFocus={(e) => e.target.select()}
        />
      </div>
    </Grid>
  ));

  const canUncheck = checkbox.filter((checkbox) => checkbox.isChecked)?.length === 2;

  const renderCheckboxes = checkbox.map((item: any) => (
    <FormControlLabel
      key={item.id}
      control={
        <Checkbox
          checked={item.isChecked}
          onChange={handleCheckbox}
          value={item.value}
          disabled={item.isChecked && !canUncheck}
        />
      }
      label={item.value}
    />
  ));

  const renderInfrastructure = renderFields(infrastructure, handleInfrastructureChange, handleInfrastructureRemove);

  const renderAddresses = renderFields(addresses, handleAddressesChange, handleAddressesRemove);

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={c.formBlock}>
          <div className={c.formBlockLabel}>{t("Informații generale")}</div>
          <Grid container spacing={3}>
            <Grid item sm={8} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Denumirea grădiniței")}</div>
                <EgInput
                  placeholder={t("Introduceți")}
                  error={errors.name && errors.name.message}
                  name="name"
                  ref={register}
                />
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Telefon")}</div>
                <EgInput
                  placeholder={t("Introduceți")}
                  error={errors.phone && errors.phone.message}
                  name="phone"
                  ref={register}
                />
              </div>
            </Grid>
            <Grid item sm={4} md={3} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Adresa")}</div>
                <EgInput
                  placeholder={t("Introduceți")}
                  error={errors.address && errors.address.message}
                  name="address"
                  ref={register}
                />
              </div>
            </Grid>
            <Grid item sm={4} md={3} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Raionul")}</div>
                <select name="district" ref={register} className={clsx(c.select, errors.district && c.selectError)}>
                  {Object.values(districts).map((d) => (
                    <option key={d} value={d}>
                      {t(`_${d}`)}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item sm={4} md={3} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Limba de instruire")}</div>
                {renderCheckboxes}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={c.formBlock}>
          <div className={c.formBlockLabel}>{t("Locuri preconizate")}</div>
          <Grid container spacing={3}>
            {renderPlaces}
          </Grid>
        </div>
        <div className={c.formBlock}>
          <div className={c.formBlockLabel}>{t("Calificarea cadrelor didactice")}</div>
          <Grid container spacing={3}>
            <Grid item sm={6} md={4} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Gradul superior")}</div>
                <EgInput
                  value={grades.superior}
                  onChangeValue={handleGradesChange("superior")}
                  onFocus={(e) => e.target.select()}
                  placeholder={t("Introduceți")}
                  error={undefined}
                />
              </div>
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Gradul I")}</div>
                <EgInput
                  value={grades.first}
                  onChangeValue={handleGradesChange("first")}
                  onFocus={(e) => e.target.select()}
                  placeholder={t("Introduceți")}
                  error={undefined}
                />
              </div>
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Gradul II")}</div>
                <EgInput
                  value={grades.second}
                  onChangeValue={handleGradesChange("second")}
                  onFocus={(e) => e.target.select()}
                  placeholder={t("Introduceți")}
                  error={undefined}
                />
              </div>
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <div className={c.formGroup}>
                <div className={c.formGroupLabel}>{t("Fără grad")}</div>
                <EgInput
                  value={grades.without}
                  onChangeValue={handleGradesChange("without")}
                  onFocus={(e) => e.target.select()}
                  placeholder={t("Introduceți")}
                  error={undefined}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={c.formBlock}>
          <div className={c.formBlockLabel}>{t("Infrastructura")}</div>
          <Grid container spacing={3}>
            {renderInfrastructure}
          </Grid>
          <EgButton text={t("Adaugă")} onClick={handleAddInfrastructure} className={c.btn} variant="outlined" />
        </div>
        <div className={c.formBlock}>
          <div className={c.formBlockLabel}>{t("Adresele de deservire")}</div>
          <Grid container spacing={3}>
            {renderAddresses}
          </Grid>
          <EgButton text={t("Adaugă")} onClick={handleAddAddresses} className={c.btn} variant="outlined" />
        </div>
        <div className={c.btns}>
          <EgButton variant="contained" text={t("Salvează")} className={c.btn} type="submit" />
          <EgButton text={t("Anulează")} onClick={() => navigate(-1)} className={c.btn} variant="cancel" />
        </div>
      </form>
    </Container>
  );
};

export default InstitutionForm;
