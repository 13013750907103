import { gql } from "@apollo/client";

export const GET_INSTITUTION = gql`
  query getInstitution($id: Int!) {
    getInstitution(_id: $id) {
      id: _id
      name
      phone
      address

      district
      language
      places {
          year
          count
          language
      }
      available {
          year
          count
          language
      }
      grades {
        superior
        first
        second
        without
      }
      infrastructure
      addresses
    }
  }
`;
