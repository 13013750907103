import React, { useContext } from "react";
import { Grid, Link, useMediaQuery } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { IInstitution } from "utils/entities/institution";
import { useNavigate } from "react-router-dom";
import { EgButton } from "components/ui/button";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

interface IProps {
  item: IInstitution;
}

const Institution: React.FC<IProps> = (props) => {
  const currentInfoContext = useContext(CurrentInfoContext);
  const c = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, name, addresses, district, address, available } = props.item;
  const isMob = useMediaQuery("(max-width: 960px)");

  const handleAbout = () => {
    navigate(`/institution/${id}`);
  };

  const handleReg = () => {
    navigate(`/public-register/${id}`);
  };

  const handleEdit = () => {
    navigate(`/institution/${id}/edit`);
  };

  const sendRequest = () => {
    navigate(`/request?institutionId=${id}`);
  };

  const addressesList = (
    <ul className={c.list}>
      {addresses.length ? (
        addresses.map((a) => (
          <li className={c.item} key={a}>
            {a}
          </li>
        ))
      ) : (
        <li className={c.item}>adress</li>
      )}
    </ul>
  );

  return (
    <div className={c.root} key={id}>
      <Grid container>
        <Grid item md={8} xs={12}>
          <div className={c.header}>{name}</div>
          {!isMob && addressesList}
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={c.location}>{t(`_${district}`)}</div>
          <div className={c.adress}>{address}</div>
          <div className={c.places}>
            {available.reduce(
              (totalAvailable, availablePlaces) =>
                totalAvailable + availablePlaces.count,
              0
            )}{" "}
            {t("locuri")}
          </div>
          {!isMob && <div className={c.devider}></div>}
          {isMob && addressesList}
          <div className={c.linkWrap}>
            <Link className={c.reg} onClick={handleReg}>
              {t("Registru public")}
            </Link>
          </div>
          <div className={c.linkWrap}>
            <Link className={c.about} onClick={handleAbout}>
              {t("Despre grădiniță")}
            </Link>
          </div>
          {currentInfoContext.roles.isSuperAdmin() && (
            <EgButton
              text={t("Editează")}
              onClick={handleEdit}
              variant="outlined"
              className={c.editBtn}
            />
          )}
          {currentInfoContext.roles.isParent() && (
            <EgButton
              variant="contained"
              text={t("Trimite cerere")}
              onClick={sendRequest}
              className={c.editBtn}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Institution;
