import { gql } from "@apollo/client";

export const GET_INSTITUTION = gql`
  query getInstitution($id: Int!) {
    getInstitution(_id: $id) {
      id: _id
      name
      language
    }
  }
`;
