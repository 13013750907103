import { gql } from "@apollo/client";

export const GET_ADMINS = gql`
  query getUsers($start: Int, $limit: Int, $search: String, $level: UserLevel) {
    getUsers(start: $start, limit: $limit, search: $search, level: $level) {
      users {
        id: _id
        level
        firstName
        lastName
        idnp
      }
    }
  }
`;
