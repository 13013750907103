import { getBranchConfig } from "utils/helpers/getBranchConfig";

import aron from "assets/img/aron2.svg";
import institutia from "assets/img/institutia2.svg";
import list from "assets/img/list.png";
import check from "assets/img/check2.svg";
import person from "assets/img/person2.svg";
import gmail from "assets/img/gmail.png";
import school from "assets/img/school_balti.png";
import verified from "assets/img/verified.png";
import pedestrian from "assets/img/pedestrian.png";
import gmail_ungheni from "assets/img/gmail_ungheni.png";
import school_ungheni from "assets/img/school_ungheni.png";
import list_ungheni from "assets/img/list_ungheni.png";
import verified_ungheni from "assets/img/verified_ungheni.png";
import pedestrian_ungheni from "assets/img/pedestrian_ungheni.png";

type LogoConfigType = {
  step_1_logo: string;
  step_2_logo: string;
  step_3_logo: string;
  step_4_logo: string;
  step_5_logo: string;
};

const logoConfig = {
  pred_prod: {
    step_1_logo: aron,
    step_2_logo: institutia,
    step_3_logo: list,
    step_4_logo: check,
    step_5_logo: person,
  },
  balti: {
    step_1_logo: gmail,
    step_2_logo: school,
    step_3_logo: list,
    step_4_logo: verified,
    step_5_logo: pedestrian,
  },
  chisinau: {
    step_1_logo: aron,
    step_2_logo: institutia,
    step_3_logo: list,
    step_4_logo: check,
    step_5_logo: person,
  },
  ungheni: {
    step_1_logo: gmail_ungheni,
    step_2_logo: school_ungheni,
    step_3_logo: list_ungheni,
    step_4_logo: verified_ungheni,
    step_5_logo: pedestrian_ungheni,
  },
};

export default getBranchConfig<LogoConfigType>(logoConfig);
