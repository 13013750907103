import { gql } from "@apollo/client";

export const UPDATE_REQUEST_STATUS = gql`
  mutation requestStatusUpdate(
    $id: Int!
    $status: RequestsStatus!
    $comment: String
    $rejectReason: RejectReason
    $documents: [MatriculationDocument]
  ) {
    requestStatusUpdate(
      _id: $id
      status: $status
      comment: $comment
      rejectReason: $rejectReason
      documents: $documents
    ) {
      errors {
        field
        message
      }
      request {
        id: _id
        number
        creationTime
        updateTime
        birthDate
        childFirstName
        childLastName
        childIdnp
        language
        parentAddress
        parentEmail
        parentIdnp
        parentLocation
        parentPhone
        priority
        priorityOther
        sex
        status
        files {
          id: _id
          name
          size
        }
        history {
          authorId
          author {
            id: _id
            level
          }
          timestamp
          status
          comment
          rejectReason
          documents
          isModified
        }
        institution {
          id: _id
          name
        }
      }
    }
  }
`;
