import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import InstitutionAbout from "components/modules/InstitutionAbout";
import { useGetInstitution } from "main/hooks/useGetInstitution";
import useMessage from "main/hooks/useMessage";
import { Loader } from "components/ui/loader";

const Institution: React.FC<{}> = () => {
  const { institutionID } = useParams();  
  const { t } = useTranslation();
  const showMessage = useMessage();

  const {
    getInstitutionLoading,
    getInstitutionError,
    getInstitutionData,
  } = useGetInstitution(Number(institutionID));

  if (getInstitutionLoading) {
    return <Loader />;
  }

  if (getInstitutionError) {
    return showMessage(getInstitutionError.message, "error");
  }

  const institution = getInstitutionData?.getInstitution;

  return (
    <>
      <PageHeader
        title={institution.name}
        subtitle={t("Informații despre grădiniță")}
        ico={pageHeaderIco}
      />
      <InstitutionAbout item={institution} />
    </>
  );
};

export default Institution;
