import React, { useEffect } from "react";
import {
  CurrentInfoContext,
  useCurrentInfoState,
} from "utils/globalContexts/currentInfoContext";
import { Loader } from "components/ui/loader";
import { AppRoutes } from "routes";
import { useGetCurrentInfo } from "./hooks/useGetCurrentInfo";
import useMessage from "./hooks/useMessage";

export const Main = () => {
  const currentInfoState = useCurrentInfoState();
  const showMessage = useMessage();

  const {
    getCurrentInfoLoading,
    getCurrentInfoError,
    getCurrentInfoData,
  } = useGetCurrentInfo();

  useEffect(() => {
    if (getCurrentInfoData) {
      currentInfoState.initCurrentInfo(getCurrentInfoData.getCurrentInfo);
    }
  }, [getCurrentInfoData, currentInfoState]);

  if (getCurrentInfoError) {
    return showMessage(getCurrentInfoError.message, "error");
  }

  return (
    <CurrentInfoContext.Provider value={currentInfoState}>     
      {getCurrentInfoLoading ? (
        <Loader />
      ) : getCurrentInfoData ? (
        <AppRoutes />
      ) : null}
    </CurrentInfoContext.Provider>
  );
};
