import Cookies from "universal-cookie";

export const getCookie = (name: string) => {
  const cookies = new Cookies();

  return cookies.get(name);
};

export const setCookie = (name: string, value: any) => {
  const cookies = new Cookies();

  cookies.set(name, value);
};

export const removeCookie = (name: string) => {
  const cookies = new Cookies();

  cookies.remove(name);
};

export const getElseSetCookie = (name: string, value: any) => {
  const cookies = new Cookies();

  const cookieValue = cookies.get(name);

  if (!!cookieValue) {
    return cookieValue;
  } else {
    cookies.set(name, value);

    return value;
  }
};
