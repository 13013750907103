import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    paddingBottom: 30,
  },
  title: {
    fontSize: 48,
    fontWeight: 700,
    margin: "50px 0 20px",
  },
  iframe: {
    maxWidth: 870,
    marginBottom: 30,
  },
  textBlock: {
    marginBottom: 30,
    fontSize: 18,
    lineHeight: 1.4,
    "& a": {
      color: theme.colors.primary,
    },
  },
}));
