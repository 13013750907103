import React, { useState, useEffect, useMemo, useContext } from "react";
import { Container, FormControl, Grid, useMediaQuery } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import { EgTabs } from "components/ui/tabs";
import InstitutionList from "components/modules/InstitutionList";
import { useGetInstitutions } from "main/hooks/useGetInstitutions";
import { District } from "utils/entities/district";
import { EgSelect } from "components/ui/select";
import { EgInput } from "components/ui/input";
import { IInstitution, ICounter } from "utils/entities/institution";
import useMessage from "main/hooks/useMessage";
import { Loader } from "components/ui/loader";
import { UpMover } from "components/ui/upMover";
import { getBirthYears } from "utils/birthYears";
import { EgButton } from "components/ui/button";
import { useNavigate } from "react-router-dom";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { deleteUrlParameter, getUrlParameter, setUrlParameter } from "utils/queryParams";
import removeTypename from "utils/helpers/removeTypename";
import { useGetInstitutionsCounters } from "main/hooks/useGetInstitutionsCounters";

const Institutions: React.FC<{}> = () => {
  const searchUrlParam = getUrlParameter("search");
  const birthYearUrlParam = getUrlParameter("birthYear");
  const districtUrlParam = getUrlParameter("district");

  const [search, setSearch] = useState<string>(() => searchUrlParam || "");

  const [birthYear, setBirthYear] = useState<number | undefined>(() =>
    birthYearUrlParam ? Number(birthYearUrlParam) : undefined
  );
  const currentInfoContext = useContext(CurrentInfoContext);

  const [district, setDistrict] = useState<typeof District | null>(
    !districtUrlParam ? null : districtUrlParam === "null" ? null : District[districtUrlParam]
  );

  const [institutions, setInstitutions] = useState<IInstitution[]>([]);
  const [counters, setCounters] = useState<any>({});

  const [areMoreInstitutions, setAreMoreInstitutions] = useState<boolean>(false);

  const c = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessage();
  const isMob = useMediaQuery("(max-width: 960px)");
  const navigate = useNavigate();

  const { getInstitutions, getInstitutionsLoading, getInstitutionsError, getInstitutionsData } = useGetInstitutions();

  const { getInstitutionsCounters, getInstitutionsCountersError, getInstitutionsCountersData } =
    useGetInstitutionsCounters();

  useEffect(() => {
    setInstitutions([]);

    getInstitutions({
      variables: {
        district: district || undefined,
        search,
        year: birthYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, birthYear, district]);

  useEffect(() => {
    getInstitutionsCounters({
      variables: {
        search,
        year: birthYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, birthYear]);

  useEffect(() => {
    if (getInstitutionsData) {
      setAreMoreInstitutions(
        institutions.length + getInstitutionsData.getInstitutions.institutions.length <
          getInstitutionsData.getInstitutions.count
      );

      setInstitutions((prevInstitutions) => [...prevInstitutions, ...getInstitutionsData.getInstitutions.institutions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstitutionsData]);

  useEffect(() => {
    if (getInstitutionsCountersData) {
      setCounters(getInstitutionsCountersData.getInstitutions.counters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstitutionsCountersData]);

  const birthYearOptions = useMemo(() => getBirthYears(), []);

  if (getInstitutionsError) {
    return showMessage(getInstitutionsError.message, "error");
  }

  if (getInstitutionsCountersError) {
    return showMessage(getInstitutionsCountersError.message, "error");
  }

  const changeSearch = (search: string) => {
    setUrlParameter("search", search);
    setSearch(search);
  };

  const changeBirthYear = (birthYear?: number) => {
    if (birthYear) {
      setUrlParameter("birthYear", birthYear.toString());
    } else {
      deleteUrlParameter("birthYear");
    }

    setBirthYear(birthYear);
  };

  const changeDistrict = (district: typeof District | null) => {
    if (district === null) {
      setUrlParameter("district", "null");
    } else {
      setUrlParameter("district", district.toString());
    }

    setDistrict(district);
  };

  const loadMoreInstitutions = () => {
    getInstitutions({
      variables: {
        district: district || undefined,
        search,
        year: birthYear,
        start: institutions.length,
      },
    });
  };

  const allCounters = Object.values(removeTypename(counters)).reduce(
    (acc: number, curr: { district: string; count: number }) => {
      return acc + curr.count;
    },
    0
  );

  const buildDistricts = Object.values(removeTypename(counters)).map((element: ICounter) => ({
    value: element.district,
    label: t(`_${element.district}`),
    icon: element.count,
  }));

  return (
    <>
      <PageHeader
        title={t("Lista de grădinițe")}
        subtitle={t("Districtele și adresele care le deservesc")}
        ico={pageHeaderIco}
      />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
        <div className={c.addInst}>
          {currentInfoContext.roles.isSuperAdmin() && (
            <EgButton
              variant="contained"
              text={`+ ${t("Grădiniță nouă")}`}
              onClick={() => navigate("/institution-create")}
            />
          )}
        </div>
        <Grid container className={c.filters} spacing={3}>
          <Grid item lg={8} xs={12}>
            <div className={c.label}>{t("Domiciliu sau grădinița")}</div>
            <EgInput value={search} onChangeValue={changeSearch} placeholder={t("Caută...")} error="" />
          </Grid>
          <Grid item lg={4} xs={12}>
            <div className={c.label}>{t("Locurile libere după anul nașterii")}</div>
            <FormControl variant="outlined" className={c.formControl}>
              <EgSelect
                value={birthYear}
                onChangeValue={changeBirthYear}
                options={birthYearOptions.map((birthYear) => ({
                  value: birthYear,
                  text: birthYear.toString(),
                }))}
                placeholder={t("Toate")}
                emptyOption={0}
                defaultValue={0}
                hasEmptyOption
                error=""
              />
            </FormControl>
          </Grid>
          {isMob && (
            <Grid item md={4} xs={12}>
              <div className={c.label}>{t("District")}</div>
              <FormControl variant="outlined" className={c.formControl}>
                <EgSelect
                  value={district}
                  onChangeValue={changeDistrict}
                  options={Object.values(removeTypename(counters)).map((element: ICounter) => ({
                    value: element.district,
                    text: t(`_${element.district}`),
                  }))}
                  placeholder={t("Toate")}
                  labelId="district"
                  emptyOption={null}
                  hasEmptyOption
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        {!isMob && (
          <EgTabs
            tabs={[
              {
                value: null,
                label: t("Toate"),
                icon: allCounters,
              },
              ...buildDistricts,
            ]}
            selectedTab={district}
            onChangeTab={changeDistrict}
          />
        )}
        {!institutions.length && getInstitutionsLoading ? (
          <Loader />
        ) : (
          <div className={c.institutions}>
            <InstitutionList items={institutions} />
            {areMoreInstitutions && (
              <EgButton variant="contained" text={t("Mai multe")} onClick={loadMoreInstitutions} />
            )}
          </div>
        )}
        <UpMover />
      </Container>
    </>
  );
};

export default Institutions;
