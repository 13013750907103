import React, { useContext } from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import documentUrls from "config/documentUrls";

import Iframe from "./components/iframe";

const Guide: React.FC<{ setShowMenu; setActiveSubMenu }> = ({ setShowMenu, setActiveSubMenu }) => {
  const c = useStyles();
  const { t } = useTranslation();
  const currentInfoContext = useContext(CurrentInfoContext);
  const isDirector = currentInfoContext.roles.isDirector();
  const isAdmin = currentInfoContext.roles.isSuperAdmin() || currentInfoContext.roles.isAdmin();

  const { adminGuide, directorGuide, parentGuide } = documentUrls;

  const handleClick = () => {
    setActiveSubMenu(false);
    setShowMenu(false);
  };

  const iframe = React.useMemo(
    () =>
      isAdmin ? (
        <Iframe src={adminGuide} title={"admin"} inferredClick={handleClick} />
      ) : isDirector ? (
        <Iframe src={directorGuide} title={"director"} inferredClick={handleClick} />
      ) : (
        <Iframe src={parentGuide} title={"parent"} inferredClick={handleClick} />
      ),
    [isAdmin, isDirector, adminGuide, directorGuide, parentGuide]
  );

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={c.root}>
      <div className={c.title}>{t("Ghid")}</div>
      {iframe}
    </Container>
  );
};

export default Guide;
