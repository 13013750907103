import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const Question: React.FC<{}> = () => {
  const c = useStyles();
  const { t } = useTranslation();
  const questionsLink =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? "https://docs.google.com/document/d/e/2PACX-1vTHAacRXtObZmprhvOm0OXw4bkb4qfTVw4Oib3mcVCf5e8hrlXHwmr13rAZBQZABw/pub?embedded=true"
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? "https://docs.google.com/document/d/e/2PACX-1vSRylvPwH6zTzyEc4W6iYWU1Mv61MWbRNQaTqUsjmMTOVnE2AAVvB7sc_mCDtOn9A/pub?embedded=true"
      : "https://docs.google.com/document/d/e/2PACX-1vTcX5USl0BlDDHp5wVoW0hstDkVL_KT_iOftDfo1WPjxpKuYXcgkRUVrsYrxXVv9w/pub?embedded=true";

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={c.root}>
      <div className={c.title}>{t("Întrebări frecvente")}</div>
      <iframe src={questionsLink} width="100%" height="700px" frameBorder="0" title="Questions">
        {t("Se încarcă...")}
      </iframe>
    </Container>
  );
};

export default Question;
