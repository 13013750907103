import { createContext, useState } from "react";
import { ICurrentInfo } from "utils/entities/currentInfo";
import { UserLevel } from "utils/entities/user";

interface ICurrentInfoContext {
  currentInfo?: ICurrentInfo;
  initCurrentInfo: (currentInfo: ICurrentInfo) => void;
  roles: {
    isSuperAdmin: () => boolean;
    isAdmin: () => boolean;
    isDirector: () => boolean;
    isParent: () => boolean;
    isAnonymous: () => boolean;
  };
}

export const CurrentInfoContext = createContext<ICurrentInfoContext>({
  currentInfo: undefined,
  initCurrentInfo: (currentInfo: ICurrentInfo) => {},
  roles: {
    isSuperAdmin: () => false,
    isAdmin: () => false,
    isDirector: () => false,
    isParent: () => false,
    isAnonymous: () => false,
  },
});

export const useCurrentInfoState = () => {
  const [currentInfo, setCurrentInfo] = useState<ICurrentInfo>();

  const initCurrentInfo = (currentInfo: ICurrentInfo) => {
    setCurrentInfo(currentInfo);
  };

  const isSuperAdmin = () => currentInfo?.user.level === UserLevel.super_admin;

  const isAdmin = () => currentInfo?.user.level === UserLevel.admin;

  const isDirector = () => currentInfo?.user.level === UserLevel.director;

  const isParent = () => currentInfo?.user.level === UserLevel.parent;

  const isAnonymous = () => currentInfo?.user.level === UserLevel.anonimous;

  return {
    currentInfo,
    initCurrentInfo,
    roles: {
      isSuperAdmin,
      isAdmin,
      isDirector,
      isParent,
      isAnonymous,
    },
  };
};
