import React from "react";
import { Button } from "@mui/material";
import { useStyles } from "./styles";
import clsx from "clsx";

interface IProps {
  text: string;
  onClick?: () => void;
  className?: string;
  variant?: any;
  type?: any;
  fullWidth?: any;
  bgColor?: string;
}

export const EgButton: React.FC<IProps> = ({
  text,
  onClick,
  className,
  variant,
  type,
  fullWidth,
  bgColor,
}) => {
  const classes = useStyles({ variant, fullWidth, bgColor });

  return (
    <Button
      className={clsx(classes.button, className)}
      onClick={onClick}
      variant={variant}
      type={type}
    >
      {variant === "logout" && <div className={classes.ico}></div>}
      {text}
    </Button>
  );
};
