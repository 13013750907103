import React, { FC, ReactNode, useEffect } from "react";
import { useLocation  } from "react-router-dom";
interface IProps {
  children: ReactNode;
}

export const ScrollerToTop: FC<IProps> = ({ children }) => {  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};
