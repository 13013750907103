import { gql } from "@apollo/client";

export const REGISTER_PARENT = gql`
  mutation parentRegister($email: String!) {
    parentRegister(email: $email) {
      errors {
        message
        field
      }
    }
  }
`;
