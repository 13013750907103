import { useMutation } from '@apollo/client';
import { ICreateOrUpdateInstitution } from "utils/entities/institution";
import { UPDATE_FREE_PLACES } from "../requests/updateFreePlaces";

export const useUpdateFreePlaces = () => {
  const [updateFreePlaces, { loading }] = useMutation<
    ICreateOrUpdateInstitution
  >(UPDATE_FREE_PLACES);

  return {
    updateFreePlaces,
    updateFreePlacesLoading: loading,
  };
};
