import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dw from "assets/img/download.svg";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  dimmer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.2)",
    padding: 20,
    zIndex: 1,
  },
  popup: {
    backgroundColor: "white",
    padding: 20,
    maxHeight: "calc(100% - 20px)",
    width: "100%",
    maxWidth: 600,
    overflowY: "auto",
    borderRadius: 15,
    boxShadow: "0 0 30px rgba(0,0,0,.2)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    fontSize: 18,
  },
  body: {},
  id: {
    background: theme.colors.default,
    color: "white",
    padding: "4px 20px",
    borderRadius: 15,
    marginRight: 20,
  },
  left: {
    width: "70%",
  },
  right: {
    width: "30%",
  },
  time: {
    color: theme.colors.darkGray,
    marginRight: 10,
  },
  date: {},
  idnp: {},
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    fontSize: 18,
  },
  cell: {
    "&:nth-child(1)": {
      width: 180,
      minWidth: 180,
      fontWeight: 700,
    },
  },
  name: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 10,
    textTransform: "capitalize",
  },
  institution: {
    marginBottom: 20,
  },
  formGroup: {
    marginBottom: 30,
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  btns: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    flex: 1,
    minWidth: "auto",
    maxWidth: "49%",
    width: "100%",
  },
  blockTitle: {
    fontSize: 16,
    color: theme.colors.primary,
    fontWeight: 700,
    margin: "30px 0 20px",
  },
  docs: {
    fontWeight: 700,
    margin: "20px 0",
    background: `url(${dw}) center left no-repeat`,
    padding: "5px 0 5px 40px",

    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));
