import { gql } from "@apollo/client";

export const REQUEST_CREATE_OR_UPDATE = gql`
  mutation requestCreateOrUpdate(
    $id: Int
    $birthDate: Date
    $childFirstName: String
    $childIdnp: String
    $childLastName: String
    $files: [FileInput]
    $institutionId: Int
    $language: Language
    $parentAddress: String
    $parentIdnp: String
    $parentLocation: String
    $parentPhone: String
    $priority: [RequestsPriority]
    $priorityOther: String
    $remove: Boolean
    $sex: Sex
  ) {
    requestCreateOrUpdate(
      _id: $id
      birthDate: $birthDate
      childFirstName: $childFirstName
      childIdnp: $childIdnp
      childLastName: $childLastName
      files: $files
      institutionId: $institutionId
      language: $language
      parentAddress: $parentAddress
      parentIdnp: $parentIdnp
      parentLocation: $parentLocation
      parentPhone: $parentPhone
      priority: $priority
      priorityOther: $priorityOther
      remove: $remove
      sex: $sex
    ) {
      errors {
        message
        field
      }
    }
  }
`;
