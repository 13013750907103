import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logout from "assets/img/logout.svg";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  cabinet: {},
  header: {
    display: "flex",
    marginBottom: 20,
    "@media (min-width: 961px)": {
      alignItems: "center",
      justifyContent: "space-between",
    },
    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
  },
  logoutContainer: {
    display: "flex",
    "@media (min-width: 501px)": {
      alignItems: "center",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
      "& button": {
        width: "100%",
      },
    },
  },
  name: {
    fontSize: 32,
    fontWeight: 700,
  },
  email: {
    color: theme.colors.darkGray,
  },
  left: {
    "@media (max-width: 960px)": {
      marginBottom: 20,
    },
  },
  right: {},
  logout: {
    position: "relative",
    height: 25,
    display: "flex",
    alignItems: "center",
    paddingLeft: 35,
    fontSize: 18,
    fontWeight: 700,
    background: `url(${logout}) left center no-repeat`,
    textDecoration: "underline",
    cursor: "pointer",
    marginRight: 50,
    "@media (max-width: 500px)": {
      order: 1,
      marginTop: 20,
    },
    "&:after": {
      content: '"›"',
      fontSize: 22,
      marginLeft: 10,
      color: theme.colors.primary,
      position: "absolute",
      right: -15,
      transition: "right .2s",
    },
    "&:hover": {
      textDecoration: "none",
      "&:after": {
        right: -20,
      },
    },
  },
}));
