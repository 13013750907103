import React, { ReactNode } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IRequest } from "utils/entities/request";
import { backendDateToDateString, backendDateToTimeString, backendBirthDateToDateString } from "utils/dates";
import { RequestHistory } from "../requestHistory";
import { fileDownloadUrl } from "utils/network";
import { EgSelect } from "../select";
import { checkIsBalti } from "utils/helpers/baltiBranchHelpers";
interface IProps {
  request: IRequest;
  actions: ReactNode;
}

const BALTI_DOWNLOAD_URL = "https://i.simpalsmedia.com/studii.md/gradinitsa";

export const RequestInfo: React.FC<IProps> = ({
  request: {
    id,
    number,
    creationTime,
    updateTime,
    childFirstName,
    childLastName,
    sex,
    language,
    birthDate,
    childIdnp,
    priority,
    priorityOther,
    parentIdnp,
    parentPhone,
    parentEmail,
    parentLocation,
    parentAddress,
    status,
    history,
    files,
    institution,
  },
  actions,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isBalti = checkIsBalti();
  const downloadUrl = isBalti ? BALTI_DOWNLOAD_URL : fileDownloadUrl;

  const downloadFile = (fileId?: string) => {
    if (isBalti) {
      window.open(`${downloadUrl}/${fileId}`, "blank");
      return;
    }
    if (fileId) {
      window.open(`${fileDownloadUrl}/${id}/${fileId}`, "blank");
    } else {
      window.open(`${fileDownloadUrl}/${id}`, "blank");
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.block, status)}>
        <div className={classes.left}>
          <div className={classes.header}>
            <div className={classes.id}>#{id}</div>
            <div className={classes.time}>{backendDateToTimeString(creationTime)}</div>
            <div className={classes.time}>{backendDateToDateString(creationTime)}</div>
          </div>
          {number && (
            <div className={classes.row}>
              <div className={classes.cell}>{t("Număr de ordine")}</div>
              <div className={classes.cell}>{number}</div>
            </div>
          )}
          <div className={classes.body}>
            <div className={classes.name}>
              {childFirstName} {childLastName}
            </div>
            <div className={classes.institution}>
              <div className={classes.row}>
                <div className={classes.cell}>{t("Grădinița")}</div>
                <div className={classes.cell}>{institution.name}</div>
              </div>
            </div>
            <div className={classes.blockTitle}>{t("Informații despre copil")}</div>
            <div className={classes.rowWrap}>
              <div className={classes.row}>
                <div className={classes.cell}>{t("Limba de instruire")}</div>
                <div className={classes.cell}>{t(`_${language}_`)}</div>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>{t("Data nașterii")}</div>
                <div className={classes.cell}>{backendBirthDateToDateString(birthDate)}</div>
              </div>
              <div className={classes.idnp}>
                <div className={classes.row}>
                  <div className={classes.cell}>{t("IDNP copil")}</div>
                  <div className={classes.cell}>{childIdnp}</div>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>{t("Sex")}</div>
                <div className={classes.cell}>{t(`_${sex}_`)}</div>
              </div>
              {!!priority.length && (
                <div className={classes.row}>
                  <div className={classes.cell}>{t("Caz cu prioritate")}</div>
                  <div className={classes.cell}>
                    {priority.map((priority) => (
                      <div key={priority}>{t(`_${priority}_`)}</div>
                    ))}
                  </div>
                </div>
              )}
              {priorityOther && (
                <div className={classes.row}>
                  <div className={classes.cell}>{t("Caz cu prioritate")}</div>
                  <div className={classes.cell}>{priorityOther}</div>
                </div>
              )}
            </div>
            <div className={classes.blockTitle}>{t("Informații despre părinte")}</div>
            <div className={classes.idnp}>
              <div className={classes.row}>
                <div className={classes.cell}>{t("IDNP părinte")}</div>
                <div className={classes.cell}>{parentIdnp}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.cell}>{t("Telefon")}</div>
              <div className={classes.cell}>{parentPhone}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.cell}>{t("Email")}</div>
              <div className={classes.cell}>{parentEmail}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.cell}>{t("Localitatea")}</div>
              <div className={classes.cell}>{parentLocation}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.cell}>{t("Adresa")}</div>
              <div className={classes.cell}>{parentAddress}</div>
            </div>
          </div>
          <div className={classes.docs}>
            <div className={classes.docsCount}>
              {files.length} {t("fișiere")}
            </div>
            <EgSelect
              value={null}
              options={files.map((file) => ({
                value: isBalti ? file.id : file.name,
                text: file.name,
              }))}
              hasEmptyOption
              emptyOption=""
              placeholder={t("Toate")}
              onChangeValue={downloadFile}
            />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.header}>
            <div className={classes.time}>{backendDateToTimeString(updateTime)}</div>
            <div className={classes.time}>{backendDateToDateString(updateTime)}</div>
          </div>
          <div className={classes.bodyFlex}>
            <div className={classes.status}>{t(`_${status}_`)}</div>
            <div>{actions}</div>
          </div>
        </div>
      </div>
      <RequestHistory statusHistory={history} />
    </div>
  );
};
