import { useQuery } from '@apollo/client';
import { IGetInstitution } from "utils/entities/institution";
import { GET_INSTITUTION } from "../requests/getInstitution";

export const useGetInstitution = (institutionId: number) => {
  const { loading, error, data } = useQuery<IGetInstitution>(GET_INSTITUTION, {
    variables: {
      id: institutionId,
    },
  });

  return {
    getInstitutionLoading: loading,
    getInstitutionError: error,
    getInstitutionData: data,
  };
};
