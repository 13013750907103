export const dateToDateString = (date: Date) =>
  date.toISOString().split("T")[0];

export const backendDateToDate = (date: Date) => new Date(`${date}T00:00:00`);

export const backendDateToDateString = (date: Date) => {
  if (!date) {
    return "";
  }

  const dateString = date.toString().split("T")[0];
  const dateStringParts = dateString.split("-");

  return `${dateStringParts[2]}.${dateStringParts[1]}.${dateStringParts[0]}`;
};

export const backendDateToTimeString = (date: Date) => {
  if (!date) {
    return "";
  }

  const timeString = date.toString().split("T")[1];
  const timeStringParts = timeString.split(":");

  return `${timeStringParts[0]}:${timeStringParts[1]}`;
};

export const backendBirthDateToDateString = (date: Date) => {
  if (!date) {
    return "";
  }

  const dateParts = date.toString().split("-");

  return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
};
