import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "utils/network";
import { Main } from "main";
import { SnackbarProvider } from "notistack";
import "./styles.css";
import { changeBaltiMeta, checkIsBalti } from "utils/helpers/baltiBranchHelpers";
import { changeUngheniMeta, checkIsUngheni } from "utils/helpers/ungheniBranchHelpers";

export const App = () => {
  const client = createApolloClient();
  const isBalti = checkIsBalti();
  const isUngheni = checkIsUngheni();

  useEffect(() => {
    isBalti ? changeBaltiMeta() : isUngheni && changeUngheniMeta();
  }, []);

  return (
    <ApolloProvider client={client}>
      <SnackbarProvider maxSnack={3}>
        <Main />
      </SnackbarProvider>
    </ApolloProvider>
  );
};
