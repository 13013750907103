import { gql } from "@apollo/client";

export const GET_INSTITUTIONS_COUNTERS = gql`
  query getInstitutions(
    $start: Int
    $limit: Int
    $district: District
    $search: String
    $year: Int
  ) {
    getInstitutions(
      start: $start
      limit: $limit
      district: $district
      search: $search
      year: $year
    ) {
      counters {
        district
        count
      }      
      count
    }
  }
`;
