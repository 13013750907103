import { useLazyQuery } from '@apollo/client';
import { IGetInstitutionsCounters } from "utils/entities/institution";
import { GET_INSTITUTIONS_COUNTERS } from "../requests/getInstitutionsCounters";

export const useGetInstitutionsCounters = () => {
  const [
    getInstitutionsCounters,
    { loading, error, data },
  ] = useLazyQuery<IGetInstitutionsCounters>(GET_INSTITUTIONS_COUNTERS);

  return {
    getInstitutionsCounters,
    getInstitutionsCountersLoading: loading,
    getInstitutionsCountersError: error,
    getInstitutionsCountersData: data,
  };
};
