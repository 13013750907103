import { useTranslation } from "react-i18next";

export const useLocalTranslations = () => {
  const branchNamespace =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? "baltiNamespace"
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? "ungheniNamespace"
      : "chisinauNamespace";
  const {
    t,
    i18n: { language },
  } = useTranslation(branchNamespace);

  const translation = (key: string) => {
    const getTranslation = t(key);
    return getTranslation;
  };

  return { t: translation };
};
