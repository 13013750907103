export const getUrlParameter = (name: string) => {
  const params = new URLSearchParams(window.location.search);

  return params.get(name) || undefined;
};

export const setUrlParameter = (name: string, value: string) => {
  const params = new URLSearchParams(window.location.search);

  params.set(name, value);

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};

export const deleteUrlParameter = (name: string) => {
  const params = new URLSearchParams(window.location.search);

  params.delete(name);

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};
