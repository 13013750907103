import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MatriculationDocument } from "utils/entities/matriculationDocument";
import { IRequest, RequestRejectReason, RequestsStatus } from "utils/entities/request";
import { useUpdateRequestStatus } from "utils/globalHooks/useUpdateRequestStatus";
import useMessage from "main/hooks/useMessage";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { Loader } from "components/ui/loader";
import { EgSelect } from "components/ui/select";
import { getBirthYears } from "utils/birthYears";
import { EgTabs } from "components/ui/tabs";
import { useGetRequests } from "./hooks/useGetRequests";
import { Request } from "./request";
import { StatusChangePopup } from "components/ui/statusChangePopup";
import { useStyles } from "./styles";
import { deleteUrlParameter, getUrlParameter, setUrlParameter } from "utils/queryParams";
import { useGetRequestsCounters } from "./hooks/useGetRequestsCounters";
import { apiRootUrl } from "utils/network";

import colors from "assets/colors";

const currentThemeColors = colors[process.env.REACT_APP_BRANCH_NAME] || colors.chisinau;

const customButtom = `${process.env.REACT_APP_BRANCH_NAME !== "ungheni"}`
  ? currentThemeColors.primary
  : currentThemeColors.secondary;

export const RequestList = () => {
  const searchUrlParam = getUrlParameter("search");
  const birthYearUrlParam = getUrlParameter("birthYear");
  const statusUrlParam = getUrlParameter("status");

  const [search, setSearch] = useState<string>(() => searchUrlParam || "");

  const [birthYear, setBirthYear] = useState<number | undefined>(() =>
    birthYearUrlParam ? Number(birthYearUrlParam) : undefined
  );

  const [status, setStatus] = useState<RequestsStatus | null>(() =>
    !statusUrlParam ? RequestsStatus.unprocessed : statusUrlParam === "null" ? null : RequestsStatus[statusUrlParam]
  );

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [counters, setCounters] = useState<any>({});
  const [areMoreRequests, setAreMoreRequests] = useState<boolean>(false);

  const [statusChangePopupRequest, setStatusChangePopupRequest] = useState<IRequest | undefined>();

  const [statusChangePopupStatus, setStatusChangePopupStatus] = useState<RequestsStatus | undefined>();

  const isMob = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessage();

  const { getRequests, getRequestsLoading, getRequestsError, getRequestsData } = useGetRequests();

  const { getRequestsCounters, getRequestsCountersError, getRequestsCountersData } = useGetRequestsCounters();

  const { updateRequestStatus } = useUpdateRequestStatus();

  useEffect(() => {
    setRequests([]);

    getRequests({
      variables: {
        search,
        year: birthYear,
        status: status || undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, birthYear, status]);

  useEffect(() => {
    getRequestsCounters({
      variables: {
        search,
        year: birthYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, birthYear]);

  useEffect(() => {
    if (getRequestsData && !statusChangePopupRequest) {
      setAreMoreRequests(
        requests.length + getRequestsData.getRequests.requests.length < getRequestsData.getRequests.count
      );

      setRequests((prevRequests) => [...prevRequests, ...getRequestsData.getRequests.requests]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestsData]);

  useEffect(() => {
    if (getRequestsCountersData) {
      setCounters(getRequestsCountersData.getRequests.counters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestsCountersData]);

  const birthYearOptions = useMemo(() => getBirthYears(), []);

  const firstUnprocessedRequestIndex = useMemo(
    () => requests.findIndex((request) => request.status === RequestsStatus.unprocessed),
    [requests]
  );

  const totalCounter = useMemo(
    () =>
      Number(
        Object.values(counters)
          .filter((counterValue: number) => !isNaN(counterValue))
          .reduce((prevCounter: number, currentCounter: number) => prevCounter + currentCounter, 0)
      ),
    [counters]
  );

  if (getRequestsError) {
    return showMessage(getRequestsError.message, "error");
  }

  if (getRequestsCountersError) {
    return showMessage(getRequestsCountersError.message, "error");
  }

  const changeSearch = (search: string) => {
    setUrlParameter("search", search);
    setSearch(search);
  };

  const changeBirthYear = (birthYear?: number) => {
    if (birthYear) {
      setUrlParameter("birthYear", birthYear.toString());
    } else {
      deleteUrlParameter("birthYear");
    }

    setBirthYear(birthYear);
  };

  const changeStatus = (status: RequestsStatus | null) => {
    if (status === null) {
      setUrlParameter("status", "null");
    } else {
      setUrlParameter("status", status.toString());
    }

    setStatus(status);
  };

  const loadMoreRequests = () => {
    getRequests({
      variables: {
        status,
        search,
        year: birthYear,
        start: requests.length,
      },
    });
  };

  const openStatusChangePopup = (request: IRequest, status: RequestsStatus) => {
    setStatusChangePopupRequest(request);
    setStatusChangePopupStatus(status);
  };

  const refreshRequestStatus = (refreshedRequest: IRequest) => {
    setRequests((prevRequests) => {
      const requests = [...prevRequests];

      const requestIndex = requests.findIndex((request) => request.id === refreshedRequest.id);

      if (status === null) {
        requests.splice(requestIndex, 1, refreshedRequest);
      } else {
        requests.splice(requestIndex, 1);
      }

      return requests;
    });
  };

  const changeRequestStatus = async (
    comment?: string,
    rejectReason?: RequestRejectReason,
    documents?: MatriculationDocument[]
  ) => {
    try {
      const response = await updateRequestStatus({
        variables: {
          id: statusChangePopupRequest.id,
          status: statusChangePopupStatus,
          comment,
          rejectReason,
          documents,
        },
      });

      if (!response.errors) {
        refreshRequestStatus(response.data?.requestStatusUpdate.request);
        setStatusChangePopupRequest(undefined);
        setStatusChangePopupStatus(undefined);
      } else {
        showMessage(response.errors[0].message, "error");
      }
    } catch (error) {
      showMessage(error.message, "error");
    }
  };

  const closeStatusChangePopup = () => {
    setStatusChangePopupRequest(undefined);
    setStatusChangePopupStatus(undefined);
  };

  const downloadRequestTotals = () => {
    window.open(`${apiRootUrl}/report/request_totals${birthYear ? `/${birthYear}` : ""}`, "blank");
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={4} sm={6} xs={12}>
          <div className={classes.formGroup}>
            <div className={classes.formGroupLabel}>{t("Nume, Prenume sau 4 cifre din IDNP")}</div>
            <EgInput value={search} onChangeValue={changeSearch} placeholder={t("Caută...")} error="" />
          </div>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <div className={classes.formGroup}>
            <div className={classes.formGroupLabel}>{t("Anul nașterii")}</div>
            <EgSelect
              value={birthYear}
              onChangeValue={changeBirthYear}
              options={birthYearOptions.map((birthYear) => ({
                value: birthYear,
                text: birthYear.toString(),
              }))}
              placeholder={t("Toate")}
              emptyOption={0}
              defaultValue={0}
              hasEmptyOption
              error=""
            />
          </div>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <EgButton variant="contained" text={t("Descarcă lista")} onClick={downloadRequestTotals} fullWidth />
        </Grid>
        {isMob && (
          <Grid item md={4} sm={6} xs={12}>
            <div className={classes.formGroup}>
              <div className={classes.formGroupLabel}>{t("Status")}</div>
              <EgSelect
                value={status}
                onChangeValue={changeStatus}
                options={Object.values(RequestsStatus)
                  .filter((status) => status !== RequestsStatus.withdraw)
                  .map((status) => ({
                    value: status,
                    text: t(`_${status}`),
                  }))}
                placeholder={t("Toate")}
                labelId="status"
                emptyOption={null}
                hasEmptyOption
              />
            </div>
          </Grid>
        )}
      </Grid>
      {!isMob && (
        <EgTabs
          tabs={[
            {
              value: RequestsStatus.unprocessed,
              label: t(`_${RequestsStatus.unprocessed}`),
              icon: counters.unprocessed,
            },
            {
              value: null,
              label: t("Toate"),
              icon: totalCounter,
            },
            {
              value: RequestsStatus.more_info,
              label: t(`_${RequestsStatus.more_info}`),
              icon: counters.moreInfo,
            },
            {
              value: RequestsStatus.registered,
              label: t(`_${RequestsStatus.registered}`),
              icon: counters.registered,
            },
            {
              value: RequestsStatus.rejected,
              label: t(`_${RequestsStatus.rejected}`),
              icon: counters.rejected,
            },
            {
              value: RequestsStatus.accepted,
              label: t(`_${RequestsStatus.accepted}`),
              icon: counters.accepted,
            },
          ]}
          selectedTab={status}
          onChangeTab={changeStatus}
        />
      )}
      {!requests.length && getRequestsLoading ? (
        <Loader />
      ) : (
        <div className={classes.requests}>
          {requests.map((request, requestIndex) => (
            <Request
              key={request.id}
              canProcess={
                request.status !== RequestsStatus.unprocessed || requestIndex === firstUnprocessedRequestIndex
              }
              request={request}
              onOpenStatusChangePopup={openStatusChangePopup}
            />
          ))}
          {areMoreRequests && (
            <EgButton text={t("Mai multe")} onClick={loadMoreRequests} bgColor={`${customButtom} !important`} />
          )}
        </div>
      )}
      {statusChangePopupRequest && (
        <StatusChangePopup
          request={statusChangePopupRequest}
          statusToSet={statusChangePopupStatus}
          onChangeStatus={changeRequestStatus}
          onClose={closeStatusChangePopup}
        />
      )}
    </div>
  );
};
