import { useLazyQuery } from '@apollo/client';
import { IGetInstitution } from "utils/entities/institution";
import { GET_INSTITUTION } from "main/requests/getInstitution";

export const useGetInstitution = () => {
  const [getInstitution, { loading, error, data }] = useLazyQuery<
    IGetInstitution
  >(GET_INSTITUTION);

  return {
    getInstitution,
    getInstitutionLoading: loading,
    getInstitutionError: error,
    getInstitutionData: data,
  };
};
