import { useQuery } from '@apollo/client';
import { IGetRequests } from "utils/entities/request";
import { GET_REQUESTS } from "../requests/getRequests";

export const useGetRequests = () => {
  const { loading, error, data } = useQuery<IGetRequests>(GET_REQUESTS);

  return {
    getRequestsLoading: loading,
    getRequestsError: error,
    getRequestsData: data,
  };
};
