import { useLazyQuery } from '@apollo/client';
import { IGetRequestsCounters } from "utils/entities/request";
import { GET_REQUESTS_COUNTERS } from "../requests/getRequestsCounters";

export const useGetRequestsCounters = () => {
  const [
    getRequestsCounters,
    { loading, error, data },
  ] = useLazyQuery<IGetRequestsCounters>(GET_REQUESTS_COUNTERS);

  return {
    getRequestsCounters,
    getRequestsCountersLoading: loading,
    getRequestsCountersError: error,
    getRequestsCountersData: data,
  };
};
