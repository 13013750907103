import { IError } from "./error";
import { IInstitution } from "./institution";

export enum UserLevel {
  super_admin = "super_admin",
  admin = "admin",
  director = "director",
  parent = "parent",
  anonimous = "anonimous",
}

export interface IUser {
  id: number;
  institutionId: number;
  institution: IInstitution;  
  level: UserLevel;
  firstName: string;
  lastName: string;
  idnp: string;
  email: string;
}

export interface IRegisterParent {
  parentRegister: {
    errors: IError[];
  };
}

export interface IGetUsers {
  getUsers: {
    count: number;
    users: IUser[];
  };
}

export interface ICudUser {
  usersCUD: {
    errors: IError[];
    user: IUser;
  };
}

export interface ILogout {
  logout: void;
}
