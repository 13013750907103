import { useMutation } from '@apollo/client';
import { ICudUser } from "utils/entities/user";
import { CUD_USER } from "../requests/cudUser";

export const useCudUser = () => {
  const [cudUser, { loading }] = useMutation<ICudUser>(CUD_USER);

  return {
    cudUser,
    cudUserLoading: loading,
  };
};
