import fetch from "cross-fetch";
import { apiRootUrl } from "utils/network";

export const authorizeDgets = async () => {
  try {
    const response = await fetch(`${apiRootUrl}/mpass/login`, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const object = await response.json();

    window.location.href = object.data.url;
  } catch (error) {
    console.error(error);
  }
};

export const unauthorizeDgets = async () => {
  try {
    const response = await fetch(`${apiRootUrl}/mpass/logout`, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const object = await response.json();

    window.location.href = object.data.url;
  } catch (error) {
    console.error(error);
  }
};

