import { useMutation } from '@apollo/client';
import { ICreateOrUpdateRequest } from "utils/entities/request";
import { REQUEST_CREATE_OR_UPDATE } from "../requests/requestCreateOrUpdate";

export const useCreateOrUpdateRequest = () => {
  const [createOrUpdateRequest, { loading }] = useMutation<
    ICreateOrUpdateRequest
  >(REQUEST_CREATE_OR_UPDATE);

  return {
    createOrUpdateRequest,
    createOrUpdateRequestLoading: loading,
  };
};
