import common from "../index";

const translationUngheni = {
  nameSpaceRo: {
    ...common.nameSpaceRo,
    loginAuthLinkTitle: "IETU",
  },
  nameSpaceRu: {
    ...common.nameSpaceRu,
    loginAuthLinkTitle: "IETU",
  },
};

export default translationUngheni;
