import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import accepted from "assets/img/accepted.svg";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    paddingBottom: 30,
  },
  formGroup: {
    marginBottom: 30,
  },
  formGroupLabel: {
    marginBottom: 10,
    fontSize: 18,
    color: theme.colors.default,
  },
  title: {
    fontSize: 48,
    fontWeight: 700,
    margin: "50px 0 20px",
  },
  raports: {
    listStyle: "none",
    padding: 0,

    "& li": {
      paddingLeft: 45,
      minHeight: 40,
      background: `url(${accepted}) center left no-repeat`,
      fontSize: 18,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
    },
  },
}));
