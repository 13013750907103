import { useMutation } from '@apollo/client';
import { ILogout } from "utils/entities/user";
import { LOGOUT } from "utils/globalRequests/logout";

export const useLogout = () => {
  const [logout, { loading }] = useMutation<ILogout>(LOGOUT);

  return {
    logout,
    logoutLoading: loading,
  };
};
