import { useMutation } from '@apollo/client';
import { IRegisterParent } from "utils/entities/user";
import { REGISTER_PARENT } from "../requests/registerParent";

export const useRegisterParent = () => {
  const [registerParent] = useMutation<IRegisterParent>(REGISTER_PARENT);

  return {
    registerParent,
  };
};
