import { getBranchConfig } from "utils/helpers/getBranchConfig";

type DocumentUrlConfigType = {
  adminGuide: string;
  directorGuide: string;
  parentGuide: string;
};

const documentUrls = {
  pred_prod: {
    adminGuide:
      "https://docs.google.com/document/d/e/2PACX-1vTG4KAxoX9Dm2Jl35yER3yQPKybfqB31y_kw2fc9HG0S1MBHpJWT3jXeZJKw9GZCA/pub?embedded=true",
    directorGuide:
      "https://docs.google.com/document/d/e/2PACX-1vT03Rl0FUauNFc_gLg4UszsN14JzhCFLFfZOlt11YZGHx4Z0qESE_IZfGifuUw9kw/pub?embedded=true",
    parentGuide:
      "https://docs.google.com/document/d/e/2PACX-1vS0EwL27vTNj9Hibk3jlrbmAtyap_YT5hBKHQUonunLPLG7p4oL1XqM7B0vz1981g/pub?embedded=true",
  },
  chisinau: {
    adminGuide:
      "https://docs.google.com/document/d/e/2PACX-1vTG4KAxoX9Dm2Jl35yER3yQPKybfqB31y_kw2fc9HG0S1MBHpJWT3jXeZJKw9GZCA/pub?embedded=true",
    directorGuide:
      "https://docs.google.com/document/d/e/2PACX-1vT03Rl0FUauNFc_gLg4UszsN14JzhCFLFfZOlt11YZGHx4Z0qESE_IZfGifuUw9kw/pub?embedded=true",
    parentGuide:
      "https://docs.google.com/document/d/e/2PACX-1vS0EwL27vTNj9Hibk3jlrbmAtyap_YT5hBKHQUonunLPLG7p4oL1XqM7B0vz1981g/pub?embedded=true",
  },
  balti: {
    adminGuide:
      "https://docs.google.com/document/d/e/2PACX-1vScTX7HqbPgbw6rpU-mSZ5YCGXK4rbJmNjG4VB5KWPNAKki576hV1MvaeRUH5MHGw/pub?embedded=true",
    directorGuide:
      "https://docs.google.com/document/d/e/2PACX-1vSFn31Pck4PqXKm840T3ljVinJUAdngToTRRCAFEBZnC02rqWygkjHR9c1eqEcUGw/pub?embedded=true",
    parentGuide:
      "https://docs.google.com/document/d/e/2PACX-1vTOOJu4qLbuqz1PQUGxG5kWmxVGS-XquQOCHNQZY27aSJecuGRJ9UD6Pyioj4UO2Q/pub?embedded=true",
  },
  ungheni: {
    adminGuide:
      "https://docs.google.com/document/d/e/2PACX-1vRM3bUm86Ckh_hiTqeM2LMaT9hbfUPFwvi67sISDfFy69v-mvB_weuG7bv2P_UHYg/pub?embedded=true",
    directorGuide:
      "https://docs.google.com/document/d/e/2PACX-1vTjfJRZ0w4C-5gR_os2QoTyFiJYPtaeQeL5vry4JpiP0fkgUkdme7CtyVp16j045g/pub?embedded=true",
    parentGuide:
      "https://docs.google.com/document/d/e/2PACX-1vRTaWJ_WNkloCNg1AmuVGkRAevTkxvWwzA5F_3BESE2NKju1z5Z9-zM2YQ-6iebjw/pub?embedded=true",
  },
};

export default getBranchConfig<DocumentUrlConfigType>(documentUrls);
