import { useLazyQuery } from '@apollo/client';
import { IGetInstitutions } from "utils/entities/institution";
import { GET_INSTITUTIONS } from "../requests/getInstitutions";

export const useGetInstitutions = () => {
  const [getInstitutions, { loading, error, data }] = useLazyQuery<
    IGetInstitutions
  >(GET_INSTITUTIONS);

  return {
    getInstitutions,
    getInstitutionsLoading: loading,
    getInstitutionsError: error,
    getInstitutionsData: data,
  };
};
