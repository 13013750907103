import React from "react";
import { useTranslation } from "react-i18next";
import { setCookie } from "utils/cookies";
import { useStyles } from "./styles";

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const changeLanguage = (language: string) => () => {
    setCookie("language", language);
    i18n.changeLanguage(language);
  };

  return (
    <div className={classes.root}>
      <div className={`${classes.language} ${i18n.language === "ro" ? "selected" : ""}`} onClick={changeLanguage("ro")}>
        Ro
      </div>
      <div className={`${classes.language} ${i18n.language === "ru" ? "selected" : ""}`} onClick={changeLanguage("ru")}>
        Ру
      </div>
    </div>
  );
};
