import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import { Authorization } from "../main/authorization";
import { useLocalTranslations } from "utils/globalHooks/useLocalTranslations";

const Login: React.FC<{}> = () => {
  const c = useStyles();
  const { t } = useLocalTranslations();

  return (
    <Container maxWidth={false} sx={ { maxWidth: '1280px' } } className={c.root}>
      <div className={c.title}>{t("loginParentCabinet")}</div>
      <div className={c.textBlock}>
        {t("loginCabinetAccessError")}
      </div>
      <br />
      <br />
      <div className={c.textBlock}>
        {t("loginMailAccessError")}{" "}
        <Link to="/contacts">
          {t("loginAuthLinkTitle")}
        </Link>.
      </div>
      <br />
      <br />
      <Authorization login />
    </Container>
  );
};

export default Login;
