import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import colors from "assets/colors";
import { IRequest } from "utils/entities/request";
import { fileDownloadUrl } from "utils/network";
import { EgButton } from "components/ui/button";
import {
  backendBirthDateToDateString,
  backendDateToDateString,
  backendDateToTimeString,
} from "utils/dates";
import { useStyles } from "./styles";

interface IProps {
  request?: IRequest;
  onWithdraw: () => void;
  onClose: () => void;
}

export const WithdrawPopup: FC<IProps> = ({
  request: {
    id,
    creationTime,
    updateTime,
    childFirstName,
    childLastName,
    sex,
    language,
    birthDate,
    childIdnp,
    priority,
    parentIdnp,
    parentPhone,
    parentEmail,
    parentLocation,
    parentAddress,
    files,
    institution,
  },
  onWithdraw,
  onClose,
}) => {
  const c = useStyles();
  const { t } = useTranslation();

  const downloadArchive = () => {
    window.open(`${fileDownloadUrl}/${id}`, "blank");
  };

  return (
    <div className={c.dimmer}>
      <div className={c.popup}>
        <div>
          <div className={c.header}>
            <div className={c.id}>#{id}</div>
            <div className={c.time}>
              {backendDateToTimeString(creationTime)}
            </div>
            <div className={c.time}>
              {backendDateToDateString(creationTime)}
            </div>
          </div>
          <div className={c.body}>
            <div className={c.name}>
              {childFirstName} {childLastName}
            </div>
            <div className={c.institution}>
              <div className={c.row}>
                <div className={c.cell}>{t("Grădinița")}</div>
                <div className={c.cell}>{institution.name}</div>
              </div>
            </div>
            <div className={c.blockTitle}>{t("Informații despre copil")}</div>
            <div className={c.row}>
              <div className={c.cell}>{t("Limba de instruire")}</div>
              <div className={c.cell}>{t(`_${language}_`)}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Data nașterii")}</div>
              <div className={c.cell}>
                {backendBirthDateToDateString(birthDate)}
              </div>
            </div>
            <div className={c.idnp}>
              <div className={c.row}>
                <div className={c.cell}>{t("IDNP copil")}</div>
                <div className={c.cell}>{childIdnp}</div>
              </div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Sex")}</div>
              <div className={c.cell}>{t(`_${sex}_`)}</div>
            </div>
            {priority && (
              <div className={c.row}>
                <div className={c.cell}>{t("Caz cu prioritate")}</div>
                <div className={c.cell}>
                  {priority.map((priorityEntry) => (
                    <div key={priorityEntry}>{t(`_${priorityEntry}_`)}</div>
                  ))}
                </div>
              </div>
            )}
            <div className={c.blockTitle}>{t("Informații despre părinte")}</div>
            <div className={c.idnp}>
              <div className={c.row}>
                <div className={c.cell}>{t("IDNP părinte")}</div>
                <div className={c.cell}>{parentIdnp}</div>
              </div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Telefon")}</div>
              <div className={c.cell}>{parentPhone}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Email")}</div>
              <div className={c.cell}>{parentEmail}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Localitatea")}</div>
              <div className={c.cell}>{parentLocation}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Adresa")}</div>
              <div className={c.cell}>{parentAddress}</div>
            </div>
          </div>
        </div>
        <div className={c.docs} onClick={downloadArchive}>
          {files.length} {t("fișiere")}
        </div>
        <h3>{t("Sigur doriți să vă retrageți cererea?")}</h3>
        <div className={c.btns}>
          <EgButton
            text={t("Retrage")}
            onClick={onWithdraw}
            variant="contained"
            className={c.btn}
            bgColor={colors.red}
          />
          <EgButton
            variant="outlined"
            text={t("Anulează")}
            onClick={onClose}
            className={c.btn}
          />
        </div>
      </div>
    </div>
  );
};
