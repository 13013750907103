import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import citiesConfig from "../../config/cities";

const Contacts: React.FC<{}> = () => {
  const c = useStyles();
  const { t } = useTranslation();
  const { title, iframeSrc, phone, email, address } = citiesConfig;

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={c.root}>
      <div className={c.title}>{t(title)}</div>
      <div className={c.iframe}>
        <iframe
          src={iframeSrc}
          width={
            process.env.REACT_APP_BRANCH_NAME === "ungheni" ? ("@media (max-width: 650px)" ? "100%" : "600") : "870"
          }
          height={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "450" : "200"}
          style={{ border: 0 }}
          aria-hidden="false"
        ></iframe>
      </div>
      <div className={c.textBlock} dangerouslySetInnerHTML={{ __html: t(address) }}></div>
      <div className={c.textBlock}>
        <a href={`mailto:${email}`}>{email}</a>
        <br />
        <a href={`tel:${phone}`}>{phone}</a>
      </div>
    </Container>
  );
};

export default Contacts;
