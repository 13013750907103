import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  user: {
    marginBottom: 20,
    padding: 40,
    border: `1px solid ${theme.colors.lightGray}`,
    borderRadius: 15,
  },
  formGroup: {
    marginBottom: 30,
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  block: {
    "@media (min-width: 961px)": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  blockLeft: {
    "@media (min-width: 961px)": {
      width: "70%",
    },
  },
  blockRight: {
    "@media (min-width: 961px)": {
      width: "30%",
    },
    "@media (max-width: 960px)": {
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
    },
  },
  btnEdit: {
    margin: "0 0 10px 0 !important",
    border: `1px solid ${theme.colors.lightGray} !important`,
    color: `${theme.colors.ironGray} !important`,
  },
  btnRemove: {
    color: `${theme.colors.red} !important`,
  },
  role: {
    padding: "3px 20px",
    borderRadius: 20,
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.primary}`,
    marginRight: 30,
  },
  header: {
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    marginBottom: 20,
  },
  name: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 10,
  },
  inst: {
    fontSize: 18,
  },
  instLabel: {
    marginRight: 30,
  },
  btns: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  btn: {
    flex: 1,
    minWidth: "auto",
    maxWidth: "49%",
    width: "100%",
    "@media (max-width: 960px)": {
      flex: "none",
      maxWidth: "100%",
    },
  },
}));
