import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  input: {
    borderRadius: "15px !important",
    "& .MuiOutlinedInput-input": {
      fontFamily: "Open Sans",
      fontSize: 18,
    },
  },
});
