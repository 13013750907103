import React from "react";
import { useStyles } from "./styles";
import { Error } from "../error";
import { FormControl, TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";

interface IProps {
  term: string;
  onChangeTerm: (term: string) => void;
  onChangeValue: (value?: number) => void;
  options: { value: number; text: string }[];
  placeholder?: string;
  error?: string;
}

export const EgAutocomplete: React.FC<IProps> = ({
  term,
  onChangeTerm,
  onChangeValue,
  options,
  placeholder,
  error,
}) => {
  const classes = useStyles();

  const changeTerm = (event: React.ChangeEvent<{}>, value: string) => {
    onChangeTerm(value);
  };

  const changeValue = (event: React.ChangeEvent<{}>, value: any) => {
    onChangeValue(value ? Number(value.value) : undefined);
  };

  return (
    <FormControl variant="outlined" className={classes.root}>
      <Autocomplete
        className={classes.autocomplete}
        inputValue={term}
        onInputChange={changeTerm}
        options={options}
        getOptionLabel={(option) => option.text}
        noOptionsText=""
        onChange={changeValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className={classes.input}
            fullWidth
            error={!!error}
            placeholder={placeholder}
          />
        )}
      />
      <Error text={error} />
    </FormControl>
  );
};
