import { useLazyQuery } from '@apollo/client';
import { IGetPublicRequests } from "utils/entities/request";
import { GET_PUBLIC_REQUESTS } from "../requests/getPublicRequests";

export const useGetPublicRequests = () => {
  const [getPublicRequests, { loading, error, data }] = useLazyQuery<
    IGetPublicRequests
  >(GET_PUBLIC_REQUESTS);

  return {
    getPublicRequests,
    getPublicRequestsLoading: loading,
    getPublicRequestsError: error,
    getPublicRequestsData: data,
  };
};
