import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRequestHistory, RequestsStatus } from "utils/entities/request";
import { UserLevel } from "utils/entities/user";
import {
  backendDateToDateString,
  backendDateToTimeString,
} from "utils/dates";
import { useStyles } from "./styles";

interface IProps {
  statusHistory: IRequestHistory[];
}

export const RequestHistory: FC<IProps> = ({ statusHistory }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getMessage = (
    status: RequestsStatus,
    isRequestModified: boolean,
    userLevel?: UserLevel
  ) => {    
    if (!userLevel) {
      switch (status) {
        case RequestsStatus.unprocessed:
          return t("Statutul cererii a fost schimbat în Neprocesată");
        case RequestsStatus.withdraw:
          return t("Statutul cererii a fost schimbat în Retrasă");
        case RequestsStatus.more_info:
          return t("Statutul cererii a fost schimbat în Solicitare acte");
        case RequestsStatus.registered:
          return t("Statutul cererii a fost schimbat în Înregistrat");
        case RequestsStatus.rejected:
          return t("Statutul cererii a fost schimbat în Respins");
        case RequestsStatus.accepted:
          return t("Statutul cererii a fost schimbat în Înmatriculat");
        default:
          return "";
      }
    }

    switch (userLevel) {
      case UserLevel.parent:
        if (isRequestModified) {
          return t("Аutorul a editat cererea");
        }

        switch (status) {
          case RequestsStatus.unprocessed:
            return t("Аutorul a expediat cererea");
          case RequestsStatus.withdraw:
            return t("Аutorul a retras cererea");
          default:
            return "";
        }
      case UserLevel.director:
        switch (status) {
          case RequestsStatus.more_info:
            return t("Directorul a solicitat acte");
          case RequestsStatus.registered:
            return t("Directorul a înregistrat cererea");
          case RequestsStatus.rejected:
            return t("Directorul a refuzat cererea");
          case RequestsStatus.accepted:
            return t("Directorul a înmatriculat copilul");
          default:
            return "";
        }
      case UserLevel.super_admin:
      case UserLevel.admin:
        if (isRequestModified) {
          return t("Administratorul a editat cererea");
        }

        switch (status) {
          case RequestsStatus.unprocessed:
            return t("Administratorul a schimbat statutul în Neprocesată");
          case RequestsStatus.more_info:
            return t("Administratorul a schimbat statutul în Solicitare acte");
          case RequestsStatus.registered:
            return t("Administratorul a schimbat statutul în Înregistrat");
          case RequestsStatus.rejected:
            return t("Administratorul a schimbat statutul în Respins");
          case RequestsStatus.accepted:
            return t("Administratorul a schimbat statutul în Înmatriculat");
          default:
            return "";
        }
      default:
        return "";
    }
  }; 

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{t("Istoria acțiunilor")}</div>
      {statusHistory.map((change, changeIndex) => (
        <div className={classes.statusChange} key={changeIndex}>
          <div className={classes.leftPart}>
            <div className={classes.dateTime}>
              {backendDateToDateString(change.timestamp)}
            </div>
            <div className={classes.dateTime}>
              {backendDateToTimeString(change.timestamp)}
            </div>
          </div>
          <div className={classes.centralPart}>
            <div className={classes.circle} />
            {changeIndex !== statusHistory.length - 1 && (
              <div className={classes.line} />
            )}
          </div>
          <div className={classes.rightPart}>
            <div className={classes.message}>
              {getMessage(
                change.status,
                change.isModified,
                change.author?.level
              )}
            </div>
            {change.rejectReason && (
              <div className={classes.reason}>
                {t(`_${change.rejectReason}_`)}
              </div>
            )}
            {change.comment && (
              <div className={classes.comment}>{change.comment}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
