import { Checkbox, Container, FormControlLabel, Grid } from "@mui/material";
import fetch from "cross-fetch";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IFile } from "utils/entities/file";
import { Language } from "utils/entities/language";
import { RequestsPriority } from "utils/entities/request";
import { Sex } from "utils/entities/sex";
import { fileUploadUrl } from "utils/network";
import { regex } from "utils/regex";
import { EgButton } from "components/ui/button";
import { EgDatepicker } from "components/ui/datepicker";
import { Error } from "components/ui/error";
import { EgInput } from "components/ui/input";
import { EgSelect } from "components/ui/select";
import { useStyles } from "./styles";
import { useCreateOrUpdateRequest } from "./hooks/useCreateOrUpdateRequest";
import { useGetRequest } from "./hooks/useGetRequest";
import { useGetInstitution } from "./hooks/useGetInstitution";
import { backendDateToDate } from "utils/dates";
import useMessage from "main/hooks/useMessage";
import { Loader } from "components/ui/loader";
import { CONSTANTS } from "utils/constants/index";
import PageHeader from "components/ui/pageHeader";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { IFieldsState, IFieldsErrors } from "utils/entities/fields";
import { format } from "date-fns";

const RequestEdit: React.FC<{}> = () => {
  const { requestID } = useParams();
  const currentInfoContext = useContext(CurrentInfoContext);
  const userEmail = currentInfoContext.currentInfo.user.email;
  const isParent = currentInfoContext.roles.isParent();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const institutionID = queryParams.get("institutionId");
  const institutionIdRef = useRef<number>(Number(institutionID));

  const [fields, setFields] = useState<IFieldsState>({
    childFirstName: "",
    childLastName: "",
    language: null,
    sex: null,
    childIdnp: "",
    priority: [],
    priorityOther: "",
    parentEmail: isParent ? userEmail : "",
    parentIdnp: "",
    parentPhone: "",
    parentLocation: "",
    parentAddress: "",
    files: [],
  });

  const [fieldsErrors, setFieldsErrors] = useState<IFieldsErrors>({
    childFirstName: "",
    childLastName: "",
    language: "",
    sex: "",
    birthDate: "",
    childIdnp: "",
    priorityOther: "",
    parentEmail: "",
    parentIdnp: "",
    parentPhone: "",
    parentLocation: "",
    parentAddress: "",
    files: "",
    isTruthful: "",
    agree: "",
  });

  const [isTruthful, setIsTruthful] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const fileInputRef = useRef(null);

  const { getRequest, getRequestLoading, getRequestError, getRequestData } = useGetRequest();

  const { getInstitution, getInstitutionLoading, getInstitutionError, getInstitutionData } = useGetInstitution();

  const { createOrUpdateRequest, createOrUpdateRequestLoading } = useCreateOrUpdateRequest();

  useEffect(() => {
    if (requestID) {
      getRequest({
        variables: {
          id: Number(requestID),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getRequestData) {
      const request = getRequestData.getRequest;

      institutionIdRef.current = request.institutionId;

      setFields((prevFields) => ({
        ...prevFields,
        childFirstName: request.childFirstName,
        childLastName: request.childLastName,
        language: request.language,
        sex: request.sex,
        birthDate: backendDateToDate(request.birthDate),
        childIdnp: request.childIdnp,
        parentEmail: request.parentEmail,
        parentIdnp: request.parentIdnp,
        parentPhone: request.parentPhone,
        parentLocation: request.parentLocation,
        parentAddress: request.parentAddress,
        priority: request.priorityOther ? [RequestsPriority.other] : request.priority,
        priorityOther: request.priorityOther,
        files: request.files,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestData]);

  useEffect(() => {
    if (institutionIdRef.current) {
      getInstitution({
        variables: {
          id: institutionIdRef.current,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionIdRef.current]);

  const languageOptions = [Language.ro, Language.ru];

  if (getRequestLoading || getInstitutionLoading) {
    return <Loader />;
  }

  if (getRequestError) {
    return showMessage(getRequestError.message, "error");
  }

  if (getInstitutionError) {
    return showMessage(getInstitutionError.message, "error");
  }

  const changeInstitution = () => {
    navigate("/institutions");
  };

  const changeChildFirstName = (childFirstName: string) => {
    setFields((prevFields) => ({ ...prevFields, childFirstName }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      childFirstName: "",
    }));
  };

  const changeChildLastName = (childLastName: string) => {
    setFields((prevFields) => ({ ...prevFields, childLastName }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      childLastName: "",
    }));
  };

  const changeLanguage = (language: Language) => {
    setFields((prevFields) => ({
      ...prevFields,
      language,
    }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      language: "",
    }));
  };

  const changeSex = (sex: Sex) => {
    setFields((prevFields) => ({
      ...prevFields,
      sex,
    }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      sex: "",
    }));
  };

  const changeBirthDate = (birthDate?: Date) => {
    setFields((prevFields) => ({ ...prevFields, birthDate }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      birthDate: "",
    }));
  };

  const changeChildIdnp = (childIdnp: string) => {
    setFields((prevFields) => ({ ...prevFields, childIdnp }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      childIdnp: "",
    }));
  };

  const changePriority =
    (requestsPriority: RequestsPriority) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFields((prevFields) => ({
        ...prevFields,
        priority: checked
          ? requestsPriority === RequestsPriority.other
            ? [requestsPriority]
            : [...prevFields.priority.filter((priority) => priority !== RequestsPriority.other), requestsPriority]
          : prevFields.priority.filter((priority) => priority !== requestsPriority),
      }));
    };

  const changePriorityOther = (priorityOther: string) => {
    setFields((prevFields) => ({ ...prevFields, priorityOther }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      priorityOther: "",
    }));
  };

  const changeParentEmail = (parentEmail: string) => {
    setFields((prevFields) => ({ ...prevFields, parentEmail }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      parentEmail: "",
    }));
  };

  const changeParentIdnp = (parentIdnp: string) => {
    setFields((prevFields) => ({ ...prevFields, parentIdnp }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      parentIdnp: "",
    }));
  };

  const changeParentPhone = (parentPhone: string) => {
    setFields((prevFields) => ({ ...prevFields, parentPhone }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      parentPhone: "",
    }));
  };

  const changeParentLocation = (parentLocation: string) => {
    setFields((prevFields) => ({ ...prevFields, parentLocation }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      parentLocation: "",
    }));
  };

  const changeParentAddress = (parentAddress: string) => {
    setFields((prevFields) => ({ ...prevFields, parentAddress }));

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      parentAddress: "",
    }));
  };

  const removeFile = (fileToRemoveIndex: number) => () => {
    setFields((prevFields) => ({
      ...prevFields,
      files: prevFields.files.filter((file, fileIndex) => fileIndex !== fileToRemoveIndex),
    }));
  };

  const triggerFileInputClick = () => {
    fileInputRef.current.click();

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      files: "",
    }));
  };

  const clearFileInput = (
    event: any // React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.target.value = "";
  };

  const areFilesValid = (files) => {
    const totalFilesCount = fields.files.length + files.length;

    if (totalFilesCount < 1 || totalFilesCount > 5) {
      setFieldsErrors((prevFieldsErrors) => ({
        ...prevFieldsErrors,
        files: t("Numărul total de fișiere trebuie să fie între 1 și 5"),
      }));

      return false;
    }

    for (let i in files) {
      if (files[i].size > 10000000) {
        setFieldsErrors((prevFieldsErrors) => ({
          ...prevFieldsErrors,
          files: t("Mărimea maximală a unui fișier trebuie să fie 10MB"),
        }));

        return false;
      }
    }

    return true;
  };

  const uploadFiles = (event) => {
    const files = event.target.files;

    if (files && files.length) {
      if (areFilesValid(files)) {
        const formData = new FormData();

        for (let i in files) {
          formData.append("files[]", files[i]);
        }

        fetch(fileUploadUrl, {
          method: "POST",
          body: formData,
          credentials: "include",
        })
          .then((response) => response.json())
          .then((responseJson) => {
            const responseFiles = responseJson;

            setFields((prevFields) => ({
              ...prevFields,
              files: [
                ...prevFields.files,
                ...responseFiles.map(
                  (file, fileIndex) =>
                    ({
                      id: file._id,
                      name: file.name,
                      size: files[fileIndex].size,
                    } as IFile)
                ),
              ],
            }));
          })
          .catch((error) => {
            showMessage(error.message, "error");
          });
      }
    }
  };

  const uploadFilesToService = (event) => {
    const files = event.target.files;

    if (files && files.length) {
      if (areFilesValid(files)) {
        const formData = new FormData();

        formData.append("file", files[0]);

        fetch(fileUploadUrl, {
          method: "POST",
          body: formData,
          credentials: "omit",
        })
          .then((response) => response.json())
          .then((responseJson) => {
            const response = responseJson;
            const responseFiles = [];
            responseFiles.push(response);

            setFields((prevFields) => ({
              ...prevFields,
              files: [
                ...prevFields.files,
                ...responseFiles.map(
                  (file, fileIndex) =>
                    ({
                      id: file.filename,
                      name: file.input.filename,
                      size: files[fileIndex].size,
                    } as IFile)
                ),
              ],
            }));
          })
          .catch((error) => {
            showMessage(error.message, "error");
          });
      }
    }
  };

  const changeIsTruthful = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsTruthful(checked);

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      isTruthful: "",
    }));
  };

  const changeAgree = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAgree(checked);

    setFieldsErrors((prevFieldsErrors) => ({
      ...prevFieldsErrors,
      agree: "",
    }));
  };

  const scrollToFirstInvalidElement = (elementId: string) => {
    document.getElementById(elementId).scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const areFieldsValid = () => {
    let isAnyFieldInvalid = false;
    let firstInvalidElementId = null;

    const trySetFirstInvalidElementId = (elementId: string) => {
      if (!firstInvalidElementId) {
        firstInvalidElementId = elementId;
      }
    };

    const validateField = (value: string, minLength: number, _errorMessage: any, fieldId: string) => {
      if (typeof value === "string") {
        if (value.length === 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_required");
          trySetFirstInvalidElementId(fieldId);
        } else if (value.length < minLength && value.length !== 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_min_length", { minLength });
          trySetFirstInvalidElementId(fieldId);
        }
      }
    };

    if (fields.childIdnp === fields.parentIdnp) {
      isAnyFieldInvalid = true;
      fieldsErrors.childIdnp = t("field_idnp_same");
      fieldsErrors.parentIdnp = t("field_idnp_same");
      trySetFirstInvalidElementId("childIdnp");
      trySetFirstInvalidElementId("parentIdnp");
    } else {
      fieldsErrors.childIdnp = null;
      fieldsErrors.parentIdnp = null;
    }

    const validateIdnpChild = (idnp: string, _errorMessage: any, fieldId: string) => {
      if (typeof idnp === "string") {
        if (idnp.length === 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_required");
          trySetFirstInvalidElementId(fieldId);
        } else if (idnp.length !== 13 && idnp.length > 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_idnp_invalid");
          trySetFirstInvalidElementId(fieldId);
        }
      }
    };

    const validateIdnpParrent = (idnp: string, _errorMessage: any, fieldId: string) => {
      if (typeof idnp === "string") {
        if (idnp.length === 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_required");
          trySetFirstInvalidElementId(fieldId);
        } else if (idnp.length !== 13 && idnp.length > 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_idnp_invalid");
          trySetFirstInvalidElementId(fieldId);
        }
      }
    };

    const validatePhone = (phone: string, minLength: number, _errorMessage: any, fieldId: string) => {
      if (typeof phone === "string") {
        if (phone.length === 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_required");
          trySetFirstInvalidElementId(fieldId);
        } else if (phone.length < minLength && phone.length !== 0) {
          isAnyFieldInvalid = true;
          fieldsErrors[fieldId] = t("field_invalid_phone");
          trySetFirstInvalidElementId(fieldId);
        }
      }
    };

    const validateLocation = (location: string, minLength: number, _errorMessage: any, fieldId: string) => {
      if (location.length === 0) {
        isAnyFieldInvalid = true;
        fieldsErrors[fieldId] = t("field_required");
        trySetFirstInvalidElementId(fieldId);
      } else if (location.length < minLength && location.length !== 0) {
        isAnyFieldInvalid = true;
        fieldsErrors[fieldId] = t("field_min_length", { minLength });
        trySetFirstInvalidElementId(fieldId);
      }
    };

    validateField(fields.childLastName, 3, t("field_required"), "childLastName");
    validateField(fields.childFirstName, 3, t("field_required"), "childFirstName");
    validateIdnpChild(fields.childIdnp, t("field_required"), "childIdnp");
    validateIdnpParrent(fields.parentIdnp, t("field_required"), "parentIdnp");
    validatePhone(fields.parentPhone, 8, t("field_required"), "parentPhone");
    validateLocation(fields.parentLocation, 2, t("field_required"), "parentLocation");
    validateLocation(fields.parentAddress, 2, t("field_required"), "parentAddress");

    ["sex", "language"].forEach((field) => {
      if (!fields[field]) {
        isAnyFieldInvalid = true;
        fieldsErrors[field] = t("field_required");
        trySetFirstInvalidElementId(field);
      }
    });

    if (!fields.birthDate) {
      const inputElements = document.getElementsByClassName("datepicker");

      if (inputElements.length === 0) {
        isAnyFieldInvalid = true;
        fieldsErrors.birthDate = t("field_required");
      } else {
        const inputElement = inputElements[0] as HTMLInputElement;

        if (inputElement instanceof HTMLInputElement) {
          if (inputElement.value.trim() === "") {
            inputElement.classList.add("red-border");
            trySetFirstInvalidElementId("birthDate");
          } else {
            inputElement.classList.remove("red-border");
          }

          fieldsErrors.birthDate = t("field_required");
        }
      }
    }

    if (fields.priority.includes(RequestsPriority.other) && !fields.priorityOther) {
      isAnyFieldInvalid = true;
      fieldsErrors.priorityOther = t("field_required");
      trySetFirstInvalidElementId("priorityOther");
    }

    if (!fields.files.length) {
      isAnyFieldInvalid = true;
      fieldsErrors.files = t("field_minimum_1_file");
      trySetFirstInvalidElementId("files");
    }

    if (!isTruthful) {
      isAnyFieldInvalid = true;
      fieldsErrors.isTruthful = t("field_required");
      trySetFirstInvalidElementId("isTruthful");
    }

    if (!agree) {
      isAnyFieldInvalid = true;
      fieldsErrors.agree = t("field_required");
      trySetFirstInvalidElementId("agree");
    }

    if (isAnyFieldInvalid) {
      setFieldsErrors((prevFieldsErrors) => ({ ...prevFieldsErrors }));
      scrollToFirstInvalidElement(firstInvalidElementId);
    }

    return !isAnyFieldInvalid;
  };

  const sendRequest = async () => {
    if (!createOrUpdateRequestLoading && areFieldsValid()) {
      try {
        const response = await createOrUpdateRequest({
          variables: {
            id: requestID ? Number(requestID) : undefined,
            institutionId: institutionIdRef.current,
            childFirstName: fields.childFirstName,
            childLastName: fields.childLastName,
            language: fields.language,
            sex: fields.sex,
            birthDate: format(fields.birthDate as Date, "yyyy-MM-dd"),
            childIdnp: fields.childIdnp,
            //parentEmail: fields.parentEmail,
            parentIdnp: fields.parentIdnp,
            parentPhone: fields.parentPhone,
            parentLocation: fields.parentLocation,
            parentAddress: fields.parentAddress,
            priority: fields.priority.includes(RequestsPriority.other) ? [] : fields.priority,
            priorityOther: fields.priority.includes(RequestsPriority.other) ? fields.priorityOther : undefined,
            files: fields.files.map((file) => ({
              _id: file.id,
              name: file.name,
              size: file.size || 0,
            })),
            remove: false,
          },
        });
        if (!response.errors) {
          navigate("/");
          setTimeout(() => {
            window.location.reload();
          }, 50);
        } else {
          showMessage(response.errors[0].message, "error");
        }
      } catch (error) {
        showMessage(error.message, "error");
      }
    }
  };

  const now = new Date();
  const maxChildBirthDate = new Date(now.getTime());
  const minChildBirthDate = new Date(now.getTime());

  maxChildBirthDate.setFullYear(now.getFullYear() - CONSTANTS.childMinAge);
  minChildBirthDate.setFullYear(now.getFullYear() - CONSTANTS.childMaxAge);
  minChildBirthDate.setMonth(0);
  minChildBirthDate.setDate(1);

  return (
    <>
      <PageHeader
        title={t("Cerere de înscriere")}
        subtitle={
          process.env.REACT_APP_BRANCH_NAME === "balti"
            ? t("Pentru copii din mun. Bălți")
            : process.env.REACT_APP_BRANCH_NAME === "ungheni"
            ? t("Pentru copii din mun. Ungheni")
            : t("Pentru copii din mun. Chișinău")
        }
      />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={classes.requestEdit}>
        <div className={classes.kidsGardenLabel}>{t("Grădinița")}</div>
        <div className={classes.kidsGardenContainer}>
          <div className={classes.kidsGardenIconContainer}>
            <div className={classes.kidsGardenIcon}></div>
            <div className={classes.kidsGardenName}>
              {getInstitutionData?.getInstitution.name || t("Nu ați selectat grădinița")}
            </div>
          </div>
          <div className={classes.modifyButton}>
            <EgButton variant="contained" text={t("Modifică")} onClick={changeInstitution} />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>{t("Informații despre copil")}</div>
          <div className={classes.fields}>
            <Grid container spacing={3}>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="childLastName">
                  <div className={classes.formGroupLabel}>{t("Nume copil")}</div>
                  <EgInput
                    value={fields.childLastName || ""}
                    onChangeValue={changeChildLastName}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.childLastName}
                    pattern={regex.name}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="childFirstName">
                  <div className={classes.formGroupLabel}>{t("Prenume copil")}</div>
                  <EgInput
                    value={fields.childFirstName || ""}
                    onChangeValue={changeChildFirstName}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.childFirstName}
                    pattern={regex.name}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="sex">
                  <div className={classes.formGroupLabel}>{t("Sex")}</div>
                  <EgSelect
                    value={fields.sex}
                    onChangeValue={changeSex}
                    options={Object.keys(Sex).map((sexKey) => ({
                      value: sexKey,
                      text: t(`_${sexKey}_`),
                    }))}
                    placeholder={t("Selectați")}
                    labelId="sex"
                    emptyOption={null}
                    error={fieldsErrors.sex}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="birthDate">
                  <div className={classes.formGroupLabel}>{t("Data nașterii")}</div>
                  <EgDatepicker
                    value={fields.birthDate}
                    onChangeValue={changeBirthDate}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.birthDate}
                    maxDate={maxChildBirthDate}
                    minDate={minChildBirthDate}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="language">
                  <div className={classes.formGroupLabel}>{t("Limba de instruire")}</div>
                  <EgSelect
                    value={fields.language}
                    onChangeValue={changeLanguage}
                    options={languageOptions.map((language) => ({
                      value: language,
                      text: t(`_${language.toString()}_`),
                    }))}
                    placeholder={t("Selectați")}
                    labelId="language"
                    emptyOption={null}
                    error={fieldsErrors.language}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="childIdnp">
                  <div className={classes.formGroupLabel}>{t("IDNP copil")}</div>
                  <EgInput
                    value={fields.childIdnp || ""}
                    onChangeValue={changeChildIdnp}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.childIdnp}
                    pattern={regex.idnp}
                    maxLength={13}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                <div className={classes.formGroup}>
                  <div className={classes.formGroupLabel}>{t("Caz cu prioritate")}</div>
                  {Object.keys(RequestsPriority).map((requestsPriorityKey) => (
                    <div key={requestsPriorityKey}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              fields.priority.find((priority) => priority === RequestsPriority[requestsPriorityKey]) !==
                              undefined
                            }
                            onChange={changePriority(RequestsPriority[requestsPriorityKey])}
                          />
                        }
                        label={t(`_${requestsPriorityKey}_`)}
                      />
                    </div>
                  ))}
                </div>
              </Grid>
              {fields.priority.includes(RequestsPriority.other) && (
                <Grid item sm={6} md={4} xs={12}>
                  <div className={classes.formGroup} id="priorityOther">
                    <EgInput
                      value={fields.priorityOther || ""}
                      onChangeValue={changePriorityOther}
                      placeholder={t("Introduceți")}
                      error={fieldsErrors.priorityOther}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>{t("Informații despre părinte")}</div>
          <div className={classes.fields}>
            <Grid container spacing={3}>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="parentEmail">
                  <div className={classes.formGroupLabel}>{t("Email")}</div>
                  <EgInput
                    value={fields.parentEmail || ""}
                    onChangeValue={changeParentEmail}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.parentEmail}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="parentIdnp">
                  <div className={classes.formGroupLabel}>{t("IDNP părinte")}</div>
                  <EgInput
                    value={fields.parentIdnp || ""}
                    onChangeValue={changeParentIdnp}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.parentIdnp}
                    pattern={regex.idnp}
                    maxLength={13}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="parentPhone">
                  <div className={classes.formGroupLabel}>{t("Telefon")}</div>
                  <EgInput
                    value={fields.parentPhone || ""}
                    onChangeValue={changeParentPhone}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.parentPhone}
                    pattern={regex.idnp}
                    maxLength={20}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="parentLocation">
                  <div className={classes.formGroupLabel}>{t("Localitatea")}</div>
                  <EgInput
                    value={fields.parentLocation || ""}
                    onChangeValue={changeParentLocation}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.parentLocation}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={4} xs={12}>
                <div className={classes.formGroup} id="parentAddress">
                  <div className={classes.formGroupLabel}>{t("Adresa")}</div>
                  <EgInput
                    value={fields.parentAddress || ""}
                    onChangeValue={changeParentAddress}
                    placeholder={t("Introduceți")}
                    error={fieldsErrors.parentAddress}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionTitle} id="files">
            {t("Documente")}
          </div>
          <ol className={classes.ol}>
            <li>{t("Buletinul părintelui")}</li>
            <li>{t("Certificat de naștere copil")}</li>
          </ol>
          <div className={classes.text2}>
            {t("Puteți atașa documente PDF/jpeg maxim 5 documente de maxim 5 MB fiecare")}
          </div>
          {fields.files.map((file, fileIndex) => (
            <div key={file.id} className={classes.addedFile}>
              {fileIndex + 1}. <span>{file.name}</span>
              <span className={classes.fileRemover} onClick={removeFile(fileIndex)}></span>
            </div>
          ))}
          <div className={classes.fileUpload} onClick={triggerFileInputClick}>
            <div className={classes.fileUploadIcon}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .heic, .heif, .pdf"
                multiple
                ref={fileInputRef}
                onClick={clearFileInput}
                onChange={process.env.REACT_APP_BRANCH_NAME !== "balti" ? uploadFiles : uploadFilesToService}
              />
              <div>{t("Atașeaza documente").toUpperCase()}</div>
            </div>
            <Error text={fieldsErrors.files} />
          </div>
          <div className={classes.text}>
            {t(
              "În formă fizică la înmatriculare se prezintă extrasul din istoria dezvoltării copilului cu concluziile despre starea sănătății lui."
            )}
          </div>
          <div id="isTruthful">
            <FormControlLabel
              className={classes.checkboxes}
              control={<Checkbox checked={isTruthful} color="primary" onChange={changeIsTruthful} />}
              label={t("Confirm că datele și documentele introduse sunt corecte")}
            />
            <Error text={fieldsErrors.isTruthful} />
          </div>
          <div style={{ marginBottom: 20 }} id="agree">
            <FormControlLabel
              className={classes.checkboxes}
              control={<Checkbox checked={agree} color="primary" onChange={changeAgree} />}
              label={t("Sunt de acord cu prelucrarea datelor cu caracter personal")}
            />
            <Error text={fieldsErrors.agree} />
          </div>
          <div className={classes.text3}>
            {t(
              "Colectarea, prelucrarea, păstrarea datelor cu caracter personal se va realiza în conformitate cu prevederile Hotărârii Guvernului nr.1123 din 14.12.2010 și Legea nr. 133 din 08.07.2011 „Privind protecția datelor cu caracter personal”."
            )}
          </div>
          {institutionIdRef.current !== 0 && (
            <EgButton
              text={t(requestID ? "Salvează cererea" : "Trimite cerere")}
              onClick={sendRequest}
              variant="contained"
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default RequestEdit;
