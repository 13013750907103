import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInstitution, InstitutionStatus } from "utils/entities/institution";
import { IUser, UserLevel } from "utils/entities/user";
import useMessage from "main/hooks/useMessage";
import { regex } from "utils/regex";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { Loader } from "components/ui/loader";
import { EgTabs } from "components/ui/tabs";
import { getUrlParameter, setUrlParameter } from "utils/queryParams";
import { useCudUser } from "./hooks/useCudUser";
import { useGetAdmins, useGetSupervizors } from "./hooks/useGetAdmins";
import { useGetInstitutions } from "./hooks/useGetInstitutions";
import { RemovePopup } from "./removePopup";
import { useStyles } from "./styles";
import { User } from "./user";
import { UserAddPopup } from "./userAddPopup";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

export interface IErrors {
  idnp: string;
  firstName: string;
  lastName: string;
}

export const UsersList = () => {
  const [admins, setAdmins] = useState<IUser[]>([]);
  const [supervizors, setSupervizors] = useState<IUser[]>([]);
  const [institutions, setInstitutions] = useState<IInstitution[]>([]);
  const currentInfoContext = useContext(CurrentInfoContext);
  const isSuperAdmin = currentInfoContext.roles.isSuperAdmin();
  const searchUrlParam = getUrlParameter("search");
  const statusUrlParam = getUrlParameter("status");
  const [search, setSearch] = useState<string>(() => searchUrlParam || "");
  const [status, setStatus] = useState<string>(statusUrlParam || "all");
  const [adminAddLevel, setAdminAddLevel] = useState<UserLevel | undefined>();
  const [editedItemKey, setEditedItemKey] = useState<string>("");
  const [editedUser, setEditedUser] = useState<IUser | undefined>();

  const [editedInstitutionId, setEditedInstitutionId] = useState<number | undefined>();

  const [editedUserErrors, setEditedUserErrors] = useState<IErrors>({
    idnp: "",
    firstName: "",
    lastName: "",
  });

  const [deletedAdminId, setDeletedAdminId] = useState<number | undefined>();

  const [deletedDirectorId, setDeletedDirectorId] = useState<number | undefined>();

  const { getAdmins, getAdminsLoading, getAdminsError, getAdminsData } = useGetAdmins();

  const { getSupervizors, getSupervizorsLoading, getSupervizorsError, getSupervizorsData } = useGetSupervizors();

  const { getInstitutions, getInstitutionsLoading, getInstitutionsError, getInstitutionsData } = useGetInstitutions();

  const { cudUser, cudUserLoading } = useCudUser();
  const c = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessage();

  useEffect(() => {
    setEditedItemKey("");
    setEditedUser(undefined);
    setEditedInstitutionId(undefined);

    setEditedUserErrors({
      idnp: "",
      firstName: "",
      lastName: "",
    });

    setAdmins([]);
    setSupervizors([]);
    setInstitutions([]);

    if (status === UserLevel.super_admin || status === "all") {
      getAdmins({
        variables: {
          search,
          limit: 50,
          level: UserLevel.super_admin,
        },
      });
    }

    if (status === UserLevel.admin || status === "all") {
      getSupervizors({
        variables: {
          search,
          limit: 50,
          level: UserLevel.admin,
        },
      });
    }

    if (status === InstitutionStatus.not_set || status === InstitutionStatus.set || status === "all") {
      getInstitutions({
        variables: {
          search,
          limit: 50,
          status,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, status]);

  useEffect(() => {
    if (getAdminsData) {
      setAdmins(getAdminsData.getUsers.users);
    }
  }, [getAdminsData]);

  useEffect(() => {
    if (getSupervizorsData) {
      setSupervizors(getSupervizorsData.getUsers.users);
    }
  }, [getSupervizorsData]);

  useEffect(() => {
    if (getInstitutionsData) {
      setInstitutions(getInstitutionsData.getInstitutions.institutions);
    }
  }, [getInstitutionsData]);

  if (getAdminsError) {
    return showMessage(getAdminsError.message, "error");
  }

  if (getSupervizorsError) {
    return showMessage(getSupervizorsError.message, "error");
  }

  if (getInstitutionsError) {
    return showMessage(getInstitutionsError.message, "error");
  }

  const changeSearch = (search: string) => {
    setUrlParameter("search", search);
    setSearch(search);
  };

  const changeStatus = (status: InstitutionStatus | null) => {
    setUrlParameter("status", status.toString());
    setStatus(status);
  };

  const enterAdminAdd = (level: UserLevel) => () => {
    setAdminAddLevel(level);
  };

  const exitAddMode = () => {
    setAdminAddLevel(undefined);
  };

  const addAdmin = async (admin: IUser) => {
    try {
      const response = await cudUser({
        variables: {
          firstName: admin.firstName,
          lastName: admin.lastName,
          idnp: admin.idnp,
          level: adminAddLevel,
        },
      });

      if (!response.errors) {
        if (status === adminAddLevel || status === "all") {
          if (adminAddLevel === UserLevel.super_admin) {
            setAdmins((prevAdmins) => [response.data?.usersCUD.user, ...prevAdmins]);
          } else {
            setSupervizors((prevSupervizors) => [response.data?.usersCUD.user, ...prevSupervizors]);
          }
        }

        exitAddMode();
      } else {
        showMessage(response.errors[0].message, "error");
      }
    } catch (error) {
      showMessage(error.message, "error");
    }
  };

  const enterEditMode = (user: IUser, editedItemKey: string, institutionId?: number) => () => {
    setEditedItemKey(editedItemKey);
    setEditedUser(user);
    setEditedInstitutionId(institutionId);

    setEditedUserErrors({
      idnp: "",
      firstName: "",
      lastName: "",
    });
  };

  const exitEditMode = () => {
    setEditedItemKey("");
    setEditedUser(undefined);
    setEditedInstitutionId(undefined);

    setEditedUserErrors({
      idnp: "",
      firstName: "",
      lastName: "",
    });
  };

  const changeField = (fieldName: string, fieldValue: string) => {
    setEditedUser((prevUser) => ({ ...prevUser, [fieldName]: fieldValue }));
    setEditedUserErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const updateEditedInstitution = (editedDirector: IUser) => {
    setInstitutions((prevInstitutions) => {
      const institutions = [...prevInstitutions];

      institutions.find((institution) => institution.id === editedInstitutionId).director = editedDirector;

      return institutions;
    });
  };

  const updateEditedAdmin = (editedAdmin: IUser) => {
    setAdmins((prevAdmins) => {
      const admins = [...prevAdmins];

      const editedAdminIndex = admins.findIndex((admin) => admin.id === editedAdmin.id);

      admins.splice(editedAdminIndex, 1, editedAdmin);

      return admins;
    });
  };

  const updateEditedSupervizor = (editedSupervizor: IUser) => {
    setSupervizors((prevSupervizors) => {
      const supervizors = [...prevSupervizors];

      const editedAdminIndex = supervizors.findIndex((supervizor) => supervizor.id === editedSupervizor.id);

      supervizors.splice(editedAdminIndex, 1, editedSupervizor);

      return supervizors;
    });
  };

  const areFieldsValid = () => {
    let isAnyFieldInvalid = false;

    const validateField = (value: string, minLength: number, errorMessage: string, fieldId: string) => {
      if (value.trim().length === 0) {
        isAnyFieldInvalid = true;
        setEditedUserErrors((prevErrors) => ({ ...prevErrors, [fieldId]: errorMessage }));
      } else if (value.length < minLength) {
        isAnyFieldInvalid = true;
        setEditedUserErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_min_length", { minLength }) }));
      }
    };

    const validateIdnp = (idnp: string, _errorMessage: any, fieldId: string) => {
      if (typeof idnp === "string") {
        if (idnp.length === 0) {
          isAnyFieldInvalid = true;
          setEditedUserErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_required") }));
        } else if (idnp.length !== 13 && idnp.length > 0) {
          isAnyFieldInvalid = true;
          setEditedUserErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_idnp_invalid") }));
        }
      }
    };

    validateField(editedUser.firstName, 3, t("field_required"), "firstName");
    validateField(editedUser.lastName, 3, t("field_required"), "lastName");
    validateIdnp(editedUser.idnp, t("field_required"), "idnp");

    if (isAnyFieldInvalid) {
      setEditedUserErrors((prevErrors) => ({ ...prevErrors }));
    }

    return !isAnyFieldInvalid;
  };

  const saveEditedUser = async () => {
    if (!cudUserLoading && areFieldsValid()) {
      try {
        const response = await cudUser({
          variables: {
            id: editedUser.id,
            firstName: editedUser.firstName,
            lastName: editedUser.lastName,
            idnp: editedUser.idnp,
            institutionId: editedInstitutionId,
            level: editedInstitutionId ? UserLevel.director : editedUser.level,
          },
        });

        if (!response.errors) {
          if (editedInstitutionId) {
            updateEditedInstitution(response.data?.usersCUD.user);
          } else if (editedUser.level === UserLevel.super_admin) {
            updateEditedAdmin(response.data?.usersCUD.user);
          } else {
            updateEditedSupervizor(response.data?.usersCUD.user);
          }

          exitEditMode();
        } else {
          showMessage(response.errors[0].message, "error");
        }
      } catch (error) {
        showMessage(error.message, "error");
      }
    }
  };

  const openRemovePopup = (adminId?: number, directorId?: number) => () => {
    setDeletedAdminId(adminId);
    setDeletedDirectorId(directorId);
  };

  const closeRemovePopup = () => {
    setDeletedAdminId(undefined);
    setDeletedDirectorId(undefined);
  };

  const deleteUser = () => {
    if (deletedAdminId) {
      setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== deletedAdminId));
    } else {
      setInstitutions((prevInstitutions) => {
        const institutions = [...prevInstitutions];

        const institution = institutions.find((institution) => institution.director?.id === deletedDirectorId);

        if (institution) {
          institution.director = undefined;
        }

        return institutions;
      });
    }
  };

  const removeUser = async () => {
    try {
      const response = await cudUser({
        variables: {
          id: deletedAdminId || deletedDirectorId,
          remove: true,
        },
      });

      if (!response.errors) {
        deleteUser();
        closeRemovePopup();
      } else {
        showMessage(response.errors[0].message, "error");
      }
    } catch (error) {
      showMessage(error.message, "error");
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={6} md={4} xs={12}>
          <div className={c.formGroup}>
            <div className={c.formGroupLabel}>{t("Nume, Prenume sau grădiniță")}</div>
            <EgInput value={search} onChangeValue={changeSearch} placeholder={t("Caută...")} error="" />
          </div>
        </Grid>
        {isSuperAdmin && (
          <>
            <Grid item sm={6} md={4} xs={12} className={c.rowAlign}>
              <EgButton
                variant={"contained"}
                text={t("Adaugă admin")}
                onClick={enterAdminAdd(UserLevel.super_admin)}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12} className={c.rowAlign}>
              <EgButton
                text={t("Adaugă supervizor")}
                onClick={enterAdminAdd(UserLevel.admin)}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      <EgTabs
        tabs={[
          {
            value: "all",
            label: t("Toți"),
          },
          {
            value: UserLevel.super_admin,
            label: t("Administratori"),
          },
          {
            value: UserLevel.admin,
            label: t("Supervizor"),
          },
          {
            value: InstitutionStatus.set,
            label: t("Instituții setate"),
          },
          {
            value: InstitutionStatus.not_set,
            label: t("Instituții nesetate"),
          },
        ]}
        selectedTab={status}
        onChangeTab={changeStatus}
      />
      {getAdminsLoading || getSupervizorsLoading || getInstitutionsLoading ? (
        <Loader />
      ) : (
        <div>
          {admins.map((admin) => {
            const key = `admin-${admin.id}`;

            return (
              <User
                key={key}
                admin={admin}
                editedUser={editedItemKey === key ? editedUser : undefined}
                editedUserErrors={editedItemKey === key ? editedUserErrors : undefined}
                onEnterEditMode={enterEditMode(admin, key)}
                onExitEditMode={exitEditMode}
                onChangeField={changeField}
                onSave={saveEditedUser}
                onRemoveUser={openRemovePopup(admin.id)}
                isSuperAdmin={isSuperAdmin}
              />
            );
          })}
          {supervizors.map((supervizor) => {
            const key = `supervizor-${supervizor.id}`;

            return (
              <User
                key={key}
                admin={supervizor}
                editedUser={editedItemKey === key ? editedUser : undefined}
                editedUserErrors={editedItemKey === key ? editedUserErrors : undefined}
                onEnterEditMode={enterEditMode(supervizor, key)}
                onExitEditMode={exitEditMode}
                onChangeField={changeField}
                onSave={saveEditedUser}
                onRemoveUser={openRemovePopup(supervizor.id)}
                isSuperAdmin={isSuperAdmin}
              />
            );
          })}
          {institutions.map((institution) => {
            const key = `institution-${institution.id}`;

            return (
              <User
                key={key}
                institution={institution}
                editedUser={editedItemKey === key ? editedUser : undefined}
                editedUserErrors={editedItemKey === key ? editedUserErrors : undefined}
                onEnterEditMode={enterEditMode(
                  institution?.director || ({ idnp: "", firstName: "", lastName: "" } as IUser),
                  key,
                  institution.id
                )}
                onExitEditMode={exitEditMode}
                onChangeField={changeField}
                onSave={saveEditedUser}
                onRemoveUser={openRemovePopup(undefined, institution.director?.id)}
                isSuperAdmin={isSuperAdmin}
              />
            );
          })}
        </div>
      )}
      {adminAddLevel && <UserAddPopup onAddUser={addAdmin} onClose={exitAddMode} isAddLoading={cudUserLoading} />}
      {(deletedAdminId || deletedDirectorId) && <RemovePopup onRemove={removeUser} onClose={closeRemovePopup} />}
    </div>
  );
};
