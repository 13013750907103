import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logout from "assets/img/logout.svg";

interface IStyle {
  variant: "outlined" | "contained" | "cancel" | "logout";
  fullWidth: any;
  bgColor?: string;
}

const grayVariant = (p: any) => {
  return ["cancel", "logout"].includes(p.variant);
};

export const useStyles = makeStyles<DefaultTheme, IStyle>((theme) => ({
  button: {
    width: (p) => (p.fullWidth ? "100%" : "auto"),
    "@media (max-width: 525px)": {
      width: "100%",
    },
    background: (p) =>
      p.bgColor
        ? p.bgColor
        : p.variant === "outlined"
        ? `white !important`
        : grayVariant(p)
        ? `white !important`
        : `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
    border: (p) =>
      p.variant === "outlined"
        ? `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`
        : grayVariant(p)
        ? `1px solid ${theme.colors.lightGray} !important`
        : 0,
    color: (p) =>
      p.variant === "outlined"
        ? `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`
        : grayVariant(p)
        ? `${theme.colors.default} !important`
        : `white !important`,
    fontFamily: "Open Sans !important",
    fontSize: "18px !important",
    borderRadius: "15px !important",
    height: 60,
    minWidth: "280px !important",
    transition: "color .2s, background .2s !important",
    lineHeight: 1.2,
    "&:hover": {
      background: (p) =>
        grayVariant(p) ? `${theme.colors.lightGray} !important` : `${theme.colors.secondary} !important`,
      color: (p) => (grayVariant(p) ? `${theme.colors.default} !important` : "white !important"),
      filter: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? "" : "saturate(130%)"}`,
    },
    "@media (max-width: 960px)": {
      minWidth: "auto !important",
    },
  },
  ico: {
    width: 50,
    height: 50,
    background: `url(${logout}) center center no-repeat`,
  },
}));
