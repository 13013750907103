import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "utils/entities/user";
import { regex } from "utils/regex";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { useStyles } from "./styles";

interface IErrors {
  idnp: string;
  firstName: string;
  lastName: string;
}

interface IProps {
  onAddUser: (user: IUser) => void;
  onClose: () => void;
  isAddLoading: boolean;
}

export const UserAddPopup: FC<IProps> = ({ onAddUser, onClose, isAddLoading }) => {
  const [user, setUser] = useState<IUser>({
    idnp: "",
    firstName: "",
    lastName: "",
  } as IUser);

  const [errors, setErrors] = useState<IErrors>({
    idnp: "",
    firstName: "",
    lastName: "",
  });

  const c = useStyles();
  const { t } = useTranslation();

  const changeField = (fieldName: string) => (fieldValue: string) => {
    setUser((prevUser) => ({ ...prevUser, [fieldName]: fieldValue }));
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const areFieldsValid = () => {
    let isAnyFieldInvalid = false;

    const validateField = (value: string, minLength: number, errorMessage: string, fieldId: string) => {
      if (value.trim().length === 0) {
        isAnyFieldInvalid = true;
        setErrors((prevErrors) => ({ ...prevErrors, [fieldId]: errorMessage }));
      } else if (value.length < minLength) {
        isAnyFieldInvalid = true;
        setErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_min_length", { minLength }) }));
      }
    };

    const validateIdnp = (idnp: string, _errorMessage: any, fieldId: string) => {
      if (typeof idnp === "string") {
        if (idnp.length === 0) {
          isAnyFieldInvalid = true;
          setErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_required") }));
        } else if (idnp.length !== 13 && idnp.length > 0) {
          isAnyFieldInvalid = true;
          setErrors((prevErrors) => ({ ...prevErrors, [fieldId]: t("field_idnp_invalid") }));
        }
      }
    };

    validateField(user.firstName, 3, t("field_required"), "firstName");
    validateField(user.lastName, 3, t("field_required"), "lastName");
    validateIdnp(user.idnp, t("field_required"), "idnp");

    if (isAnyFieldInvalid) {
      setErrors((prevErrors) => ({ ...prevErrors }));
    }

    return !isAnyFieldInvalid;
  };

  const save = () => {
    if (!isAddLoading && areFieldsValid()) {
      onAddUser(user);
    }
  };

  return (
    <div className={c.dimmer}>
      <div className={c.popup}>
        <div>
          <div className={c.formGroup}>
            <div className={c.formGroupLabel}>{t("Nume")}</div>
            <EgInput
              value={user.lastName}
              onChangeValue={changeField("lastName")}
              placeholder={t("Introduceți")}
              error={errors.lastName}
              pattern={regex.name}
            />
          </div>
          <div className={c.formGroup}>
            <div className={c.formGroupLabel}>{t("Prenume")}</div>
            <EgInput
              value={user.firstName}
              onChangeValue={changeField("firstName")}
              placeholder={t("Introduceți")}
              error={errors.firstName}
              pattern={regex.name}
            />
          </div>
          <div className={c.formGroup}>
            <div className={c.formGroupLabel}>IDNP</div>
            <EgInput
              value={user.idnp}
              onChangeValue={changeField("idnp")}
              placeholder={t("Introduceți")}
              error={errors.idnp}
              pattern={regex.idnp}
              maxLength={13}
            />
          </div>
        </div>
        <div className={c.btns}>
          <EgButton variant="contained" text={t("Salvează")} onClick={save} className={c.btn} />
          <EgButton variant="cancel" text={t("Anulează")} onClick={onClose} className={c.btn} />
        </div>
      </div>
    </div>
  );
};
