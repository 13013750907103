import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    background: process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.secondary : theme.colors.ironGray,
    padding: "40px 0",
    color: "white",
    "@media (max-width:900px)": {
      marginTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "80px !important" : ""}`,
    },
    "& > div > div:first-child": {
      justifyContent: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "center" : ""}`,
      "@media(max-width: 960px)": {
        marginLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-100px" : ""}`,
      },
      "@media(max-width: 899px)": {
        marginLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-10px" : ""}`,
      },
    },
  },
  logo: {
    marginBottom: 15,
    marginTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-22px" : ""}`,
  },
  pLogo: {
    "@media (max-width:960px)": {
      marginBottom: 15,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 15,
    marginLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "20px" : ""}`,
    "@media (max-width:900px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "100%" : ""}`,
    },
  },
  list: {},
  listRow: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 16,
    marginBottom: 15,
    marginLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "20px" : ""}`,
    width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "280px" : ""}`,
    "& span:last-child": {
      fontWeight: 700,
      "@media (max-width:900px)": {
        marginRight: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "20px" : ""}`,
      },
    },
    "@media (max-width:1300px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "200px" : ""}`,
    },
    "@media (max-width:950px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "170px" : ""}`,
    },
    "@media (max-width:900px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "100%" : ""}`,
    },
  },
  menu: {
    borderTop: "1px solid white",
    borderBottom: "1px solid white",
    padding: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "14px 0" : "20px 0"}`,
    "@media (min-width:961px)": {
      textAlign: "center",
      marginTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "46px!important" : "50px!important"}`,
    },
    "@media (max-width:960px)": {
      marginTop: 5,
    },
  },
  menuItem: {
    fontSize: 18,
    fontWeight: 700,
    color: "white",
    padding: "3px 10px",
    margin: "0 5px",
    display: "inline-block",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  copyright: {
    padding: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "17px 0" : "15px 0"}`,
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
  },
  imgsMaxWidth: {
    "& img": {
      maxWidth: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "" : "100%"}`,
      marginLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-8px" : ""}`,
      "@media (max-width:960px)": {
        display: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "25%" : ""}`,
      },
      "@media (max-width:1300px)": {
        width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "100%" : ""}`,
      },
    },
  },
}));
