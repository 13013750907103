import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  wrapper: {
    borderTop: `1px solid #e1e7ec`,
    marginTop: 20,
    paddingTop: 20,
  },
  header: {
    width: "100%",
    marginBottom: 20,
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  statusChange: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "stretch",
  },
  leftPart: {},
  centralPart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  rightPart: {
    marginLeft: 10,
    flex: 1,
  },
  dateTime: {
    width: 170,
    minWidth: 150,
    color: "#58595b",
    fontSize: 18,
    fontWeight: "bold",
  },
  message: {
    color: "#58595b",
    fontSize: 18,
    overflow: "hidde",
  },
  reason: {
    color: "#7f98ac",
    fontSize: "16",
    fontWeight: 500,
    letterSpacing: "0.5px",
    marginTop: 10,
  },
  comment: {
    color: "#7f98ac",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: "0.5px",
    marginTop: 10,
    marginBottom: 10,
  },
  circle: {
    width: 7,
    height: 7,
    border: `1px solid ${theme.colors.primary}`,
    borderRadius: 5,
    margin: 6,
  },
  line: {
    width: 0,
    minHeight: 50,
    borderRight: `1px solid #e1e7ec`,
    flex: 1,
  },
}));
