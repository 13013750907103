import React, { useContext } from "react";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import { useTranslation } from "react-i18next";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { Container } from "@mui/material";
import { useLogout } from "utils/globalHooks/useLogout";
import { Loader } from "components/ui/loader";
import { useStyles } from "./styles";
import { FreePlaces } from "./freePlaces";
import { RequestList } from "./requestList";
import { UpMover } from "components/ui/upMover";
import { EgButton } from "components/ui/button";
import { unauthorizeDgets } from "utils/dgetsAuth";

const DirectorCabinet: React.FC<{}> = () => {
  const currentInfoContext = useContext(CurrentInfoContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { logoutLoading } = useLogout();

  if (logoutLoading) {
    return <Loader />;
  }

  const doLogout = async () => {
    await unauthorizeDgets();
  };

  const user = currentInfoContext.currentInfo.user;

  return (
    <>
      <PageHeader
        title={t("Lista de cereri")}
        subtitle={currentInfoContext.currentInfo.institution?.name}
        ico={pageHeaderIco}
      />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
        <div className={classes.header}>
          <span>
            {user.firstName} {user.lastName} ({t("Director")})
          </span>
          <EgButton text={t("Ieșire")} onClick={doLogout} variant="logout" />
        </div>
        <FreePlaces
          institutionId={currentInfoContext.currentInfo.institution.id}
          places={currentInfoContext.currentInfo.institution?.places || []}
          available={currentInfoContext.currentInfo.institution.available || []}
          language={currentInfoContext.currentInfo.institution?.language}
        />
        <RequestList />
        <UpMover />
      </Container>
    </>
  );
};

export default DirectorCabinet;
