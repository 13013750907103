import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IProps {
  height: number;
}

export const useStyles = makeStyles<DefaultTheme, IProps>((theme) => ({
  dimmer: {
    width: "100%",
    height: ({ height }) => height,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.primary,
  },
}));
