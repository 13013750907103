import React, { useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

import { Footer } from "components/ui/footer";
import { Header } from "components/ui/header";
import { ScrollerToTop } from "components/modules/scrollerToTop";
import {
  Main,
  Institutions,
  Institution,
  PublicRegister,
  ParentCabinet,
  RequestEdit,
  DirectorCabinet,
  AdminCabinet,
  InstitutionEdit,
  Contacts,
  Raport,
  Raports,
  Guide,
  Reglament,
  Question,
  Login,
  Users,
} from "pages";

export const AppRoutes = () => {
  const currentInfoContext = useContext(CurrentInfoContext);

  const [showMenu, setShowMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const isSuperAdmin = currentInfoContext.roles.isSuperAdmin();
  const isAdmin = currentInfoContext.roles.isAdmin();
  const isDirector = currentInfoContext.roles.isDirector();
  const isParent = currentInfoContext.roles.isParent();
  const isAnonymous = currentInfoContext.roles.isAnonymous();

  return (
    <BrowserRouter>
      <Header
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        activeSubMenu={activeSubMenu}
        setActiveSubMenu={setActiveSubMenu}
      />
      <ScrollerToTop>
        <Routes>
          <Route path="/institutions" element={<Institutions />} />
          <Route path="/institution/:institutionID" element={<Institution />} />
          <Route path="/public-register/:institutionID" element={<PublicRegister />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/raport" element={<Raport />} />
          <Route path="/gid" element={<Guide setShowMenu={setShowMenu} setActiveSubMenu={setActiveSubMenu} />} />
          <Route path="/reglament" element={<Reglament />} />
          <Route path="/question" element={<Question />} />
          {(isSuperAdmin || isAdmin) && (
            <>
              <Route path="/" element={<AdminCabinet />} />
              <Route path="/users" element={<Users />} />
              <Route path="/request/:requestID" element={<RequestEdit />} />
              <Route path="/institution/:institutionID/edit" element={<InstitutionEdit />} />
              <Route path="/institution-create" element={<InstitutionEdit />} />
              <Route path="/raports" element={<Raports />} />
            </>
          )}
          {isDirector && (
            <>
              <Route path="/" element={<DirectorCabinet />} />
            </>
          )}
          {isParent && (
            <>
              <Route path="/" element={<ParentCabinet />} />
              <Route path="/request" element={<RequestEdit />} />
              <Route path="/request/:requestID" element={<RequestEdit />} />
            </>
          )}
          {isAnonymous && (
            <>
              <Route path="/" element={<Main />} />
              <Route path="/login" element={<Login />} />
            </>
          )}
        </Routes>
      </ScrollerToTop>
      <Footer />
    </BrowserRouter>
  );
};
