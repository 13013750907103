import { makeStyles } from "@mui/styles";
import schoolIco from "assets/img/school.svg";
import houseIco from "assets/img/house.svg";
import locationIco from "assets/img/location.svg";
import usersIco from "assets/img/users.svg";
import regIco from "assets/img/list_ico_blue.svg";
import infoIco from "assets/img/info_blue.svg";
import { DefaultTheme } from "@mui/material";

const common = {
  paddingLeft: 50,
  marginBottom: 20,
  minHeight: 36,
  backgroundPosition: "left center",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  fontSize: 18,
};

const handlers = (theme: DefaultTheme) => ({
  fontWeight: 600,
  textDecoration: "underline !important",
  color: `${theme.colors.default} !important`,
  marginBottom: 0,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
  },
});

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    minHeight: 80,
    "@media (min-width: 961px)": {
      background: `url(${schoolIco}) 30px 30px no-repeat`,
      padding: "30px 30px 30px 130px",
      border: `1px solid ${theme.colors.lightGray}`,
      borderRadius: 15,
      marginBottom: 20,
    },
    "@media (max-width: 960px)": {
      padding: "30px 0",
      borderTop: `1px solid ${theme.colors.lightGray}`,
    },
  },
  header: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 30,
    paddingRight: 20,
  },
  list: {
    padding: "0 20px",
    "@media (min-width: 961px)": {
      margin: 0,
    },
    "@media (max-width: 960px)": {
      margin: "40px 0 50px",
    },
  },
  item: {
    listStyle: "none",
    position: "relative",
    padding: "3px 0",
    "&::before": {
      content: '""',
      backgroundColor: theme.colors.primary,
      fontWeight: 700,
      display: "inline-block",
      width: 6,
      height: 6,
      marginLeft: "-1em",
      position: "absolute",
      left: 0,
      top: 10,
      borderRadius: "50%",
    },
  },
  location: {
    ...common,
    backgroundImage: `url(${houseIco})`,
    fontSize: 24,
    fontWeight: 700,
    textTransform: "capitalize",
  },
  adress: {
    ...common,
    backgroundImage: `url(${locationIco})`,
    fontWeight: 500,
  },
  places: {
    ...common,
    backgroundImage: `url(${usersIco})`,
    fontWeight: 500,
  },
  reg: {
    ...common,
    ...handlers(theme),
    backgroundImage: `url(${regIco})`,
  },
  about: {
    ...common,
    ...handlers(theme),
    backgroundImage: `url(${infoIco})`,
  },
  devider: {
    margin: "35px 0",
    height: 1,
    backgroundColor: theme.colors.lightGray,
  },
  linkWrap: {
    position: "relative",
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    "&:hover:after": {
      transform: "translate(5px) rotate(45deg)",
    },
    "&:after": {
      content: '""',
      marginLeft: 5,
      width: 5,
      height: 5,
      borderTop: `2px solid ${theme.colors.primary}`,
      borderRight: `2px solid ${theme.colors.primary}`,
      transform: "rotate(45deg)",
      borderRadius: 2,
      transition: "transform .2s",
    },
  },
  editBtn: {
    marginTop: 20,
  },
}));
