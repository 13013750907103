import React from "react";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { OptionsObject, useSnackbar } from "notistack";
import colors from "assets/colors";
interface IStyle {
  bgColor?: string;
  textColor?: string;
}

interface IConfig {
  style?: IStyle;
  Icon?: React.ElementType;
}
const getSnackContent = (msg: string, config: IConfig = {}) => {
  const { Icon = ErrorIcon, style = {} } = config;
  const Message = (
    <Box display="flex" alignItems="center" bgcolor={style.bgColor || colors.red} p={1.5} borderRadius={4}>
      <Icon style={{ color: style.textColor || "#fff", marginRight: 8 }} />
      <span style={{ color: style.textColor || "#fff" }}>{msg}</span>
    </Box>
  );

  const options: OptionsObject = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    content: () => Message,
  };

  return options;
};

type MessageType = "success" | "error" | "info";

const SNACK_CONFIG: any = {
  success: {
    Icon: CheckIcon,
    style: { bgColor: colors.green },
  },
  error: {
    Icon: ErrorIcon,
    style: { bgColor: colors.red },
  },
};

const useMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (message: string, type?: MessageType, render?: () => JSX.Element) => {
    enqueueSnackbar("", getSnackContent(message, SNACK_CONFIG[type || "error"]));
    return render ? render() : null;
  };
};

export default useMessage;
