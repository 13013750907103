import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const Reglament: React.FC<{}> = () => {
  const c = useStyles();
  const { t } = useTranslation();
  const rulesLink =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? "https://docs.google.com/document/d/e/2PACX-1vTxnGqFShgVrGcp6yBRXMiLX6sMb6wraM1Y6GVyi9oXueSjmWlAXzYtBjJLqRItug/pub?embedded=true"
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? "https://docs.google.com/document/d/e/2PACX-1vQ26x3ar9vxQZqVEtfz6Mqsfv6K8RBVfhiPQ_2Vhw0_7E2CsiUU7QCpgbYToIKUMA/pub?embedded=true"
      : "https://docs.google.com/document/d/e/2PACX-1vQvCak-HdvnbnGtNtOluVwIh7kxz11II_LDelq-j3QzF0m6dArg1GdAqEu9CzaYgX6lT1mASht3p6Ua/pub?embedded=true";

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={c.root}>
      <div className={c.title}>{t("Regulament")}</div>
      <iframe src={rulesLink} frameBorder="0" width="100%" height="700px" title="Regulament">
        {t("Se încarcă...")}
      </iframe>
    </Container>
  );
};

export default Reglament;
