import { makeStyles } from "@mui/styles";
import { DefaultTheme } from "@mui/material";

interface IStyle {
  login: boolean | undefined;
}

export const useStyles = makeStyles<DefaultTheme, IStyle>((theme: DefaultTheme) => ({
  authorization: {
    display: "flex",
    flexDirection: "column",
    alignItems: (p) => (p.login ? "initial" : "center"),
    "@media (max-width:900px)": {
      marginBottom: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "80px !important" : ""}`,
    },
  },
  input: {
    maxWidth: (p) => (p.login ? 500 : `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "556px" : "710px"}`),
    width: "100%",
    marginRight: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-7px" : "0"}`,
    "@media (max-width:600px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "420px !important" : ""}`,
    },
    "@media (max-width:495px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "100% !important" : ""}`,
    },
  },
  btn: {
    width: "100%",
    margin: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "24px 0 20px !important" : "25px 0 20px !important"}`,
    textTransform: "none",
    height: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "64px" : "60px"}`,
    transition: "color .2s, background .2s",
    lineHeight: 1.2,
    color: "#fff !important",
    "@media (min-width:601px)": {
      maxWidth: (p) => (p.login ? 500 : `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "556px" : "326px"}`),
      letterSpacing: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "0.9px !important" : "0"}`,
    },
    "@media (max-width:601px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "420px !important" : ""}`,
    },
    "@media (max-width:495px)": {
      width: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "100% !important" : ""}`,
    },
  },
  link: {
    fontSize: 18,
    textDecoration: "underline",

    "&:hover": {
      textDecoration: "none",
    },
  },
  linkSentMessage: {
    maxWidth: 785,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    margin: (p) => (p.login ? 0 : "auto"),
    fontSize: 18,
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    border: `1px solid  ${theme.colors.darkGray}`,
    borderRadius: 15,
  },
  title: {
    fontWeight: 700,
    marginBottom: 20,
  },
  text: {
    maxWidth: 400,
    textAlign: "center",
  },
}));
