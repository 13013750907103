import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 28,
    fontWeight: 700,
    borderBottom: `1px solid ${theme.colors.lightGray}`,
    paddingBottom: 20,
    marginBottom: 30,
  },
}));
