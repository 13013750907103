import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { IRequest, RequestsStatus } from "utils/entities/request";
import { EgButton } from "components/ui/button";
import { RequestInfo } from "components/ui/requestInfo";

interface IProps {
  request: IRequest;
  canProcess: boolean;
  onOpenStatusChangePopup: (request: IRequest, status: RequestsStatus) => void;
}

export const Request: React.FC<IProps> = ({
  request,
  canProcess,
  onOpenStatusChangePopup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openStatusChangePopup = (status: RequestsStatus) => () => {
    onOpenStatusChangePopup(request, status);
  };

  const requestStatus = request.status;

  return (
    <RequestInfo
      request={request}
      actions={
        canProcess ? (
          <>
            {[RequestsStatus.unprocessed].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Înregistrează")}
                onClick={openStatusChangePopup(RequestsStatus.registered)}
                className={classes.btnReg}
              />
            )}
            {[
              RequestsStatus.unprocessed,
              RequestsStatus.registered,
              RequestsStatus.more_info,
            ].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Solicită")}
                onClick={openStatusChangePopup(RequestsStatus.more_info)}
                className={classes.btn}
              />
            )}
            {[
              RequestsStatus.unprocessed,
              RequestsStatus.more_info,
              RequestsStatus.registered,
            ].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Respinge")}
                onClick={openStatusChangePopup(RequestsStatus.rejected)}
                className={classes.btnRej}
              />
            )}
            {[RequestsStatus.registered].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Înmatriculează")}
                onClick={openStatusChangePopup(RequestsStatus.accepted)}
                className={classes.btn}
              />
            )}
          </>
        ) : (
          <></>
        )
      }
    />
  );
};
