import isObject from './isObject';

function removeTypename<T extends object>(data: T): T {
  return Object.entries(data).reduce((acc: any, [key, val]) => {
    if (key !== '__typename') {
      acc[key] = Array.isArray(val)
        ? val.map(removeTypename)
        : isObject(val)
        ? removeTypename(val)
        : val;
    }   
    return acc;
  }, {} as T);
}

export default removeTypename;