import { Checkbox, FormControlLabel } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "assets/colors";
import { MatriculationDocument } from "utils/entities/matriculationDocument";
import {
  IRequest,
  RequestRejectReason,
  RequestsStatus,
} from "utils/entities/request";
import { fileDownloadUrl } from "utils/network";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { EgSelect } from "components/ui/select";
import {
  backendBirthDateToDateString,
  backendDateToDateString,
  backendDateToTimeString,
} from "utils/dates";
import { useStyles } from "./styles";

interface IProps {
  request?: IRequest;
  statusToSet?: RequestsStatus;
  onChangeStatus: (
    comment?: string,
    rejectReason?: RequestRejectReason,
    documents?: MatriculationDocument[]
  ) => void;
  onClose: () => void;
}

export const StatusChangePopup: FC<IProps> = ({
  request: {
    id,
    number,
    creationTime,
    updateTime,
    childFirstName,
    childLastName,
    sex,
    language,
    birthDate,
    childIdnp,
    priority,
    parentIdnp,
    parentPhone,
    parentEmail,
    parentLocation,
    parentAddress,
    files,
    institution,
  },
  statusToSet,
  onChangeStatus,
  onClose,
}) => {
  const [comment, setComment] = useState<string>("");

  const [rejectReason, setRejectReason] = useState<RequestRejectReason>(
    RequestRejectReason.no_places
  );

  const [documents, setDocuments] = useState<MatriculationDocument[]>([]);

  const c = useStyles();
  const { t } = useTranslation();

  const changeComment = (comment: string) => {
    setComment(comment);
  };

  const changeRejectReason = (rejectReason: RequestRejectReason) => {
    setRejectReason(rejectReason);
  };

  const changeDocument = (matriculationDocument: MatriculationDocument) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDocuments((prevDocuments) =>
      checked
        ? [...prevDocuments, matriculationDocument]
        : prevDocuments.filter((document) => document !== matriculationDocument)
    );
  };

  const changeStatus = () => {
    onChangeStatus(
      [RequestsStatus.more_info, RequestsStatus.rejected].includes(statusToSet)
        ? comment
        : undefined,
      statusToSet === RequestsStatus.rejected ? rejectReason : undefined,
      statusToSet === RequestsStatus.accepted ? documents : undefined
    );
  };

  const downloadArchive = () => {
    window.open(`${fileDownloadUrl}/${id}`, "blank");
  };

  return (
    <div className={c.dimmer}>
      <div className={c.popup}>
        <div>
          <div className={c.header}>
            <div className={c.id}>#{id}</div>
            <div className={c.time}>
              {backendDateToTimeString(creationTime)}
            </div>
            <div className={c.time}>
              {backendDateToDateString(creationTime)}
            </div>
          </div>
          {number && (
            <div className={c.row}>
              <div className={c.cell}>{t("Număr de ordine")}</div>
              <div className={c.cell}>{number}</div>
            </div>
          )}
          <div className={c.body}>
            <div className={c.name}>
              {childFirstName} {childLastName}
            </div>
            <div className={c.institution}>
              <div className={c.row}>
                <div className={c.cell}>{t("Grădinița")}</div>
                <div className={c.cell}>{institution.name}</div>
              </div>
            </div>
            <div className={c.blockTitle}>{t("Informații despre copil")}</div>
            <div className={c.row}>
              <div className={c.cell}>{t("Limba de instruire")}</div>
              <div className={c.cell}>{t(`_${language}_`)}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Data nașterii")}</div>
              <div className={c.cell}>
                {backendBirthDateToDateString(birthDate)}
              </div>
            </div>
            <div className={c.idnp}>
              <div className={c.row}>
                <div className={c.cell}>{t("IDNP copil")}</div>
                <div className={c.cell}>{childIdnp}</div>
              </div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Sex")}</div>
              <div className={c.cell}>{t(`_${sex}_`)}</div>
            </div>
            {priority && (
              <div className={c.row}>
                <div className={c.cell}>{t("Caz cu prioritate")}</div>
                <div className={c.cell}>
                  {priority.map((priorityEntry) => (
                    <div key={priorityEntry}>{t(`_${priorityEntry}_`)}</div>
                  ))}
                </div>
              </div>
            )}
            <div className={c.blockTitle}>{t("Informații despre părinte")}</div>
            <div className={c.idnp}>
              <div className={c.row}>
                <div className={c.cell}>{t("IDNP părinte")}</div>
                <div className={c.cell}>{parentIdnp}</div>
              </div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Telefon")}</div>
              <div className={c.cell}>{parentPhone}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Email")}</div>
              <div className={c.cell}>{parentEmail}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Localitatea")}</div>
              <div className={c.cell}>{parentLocation}</div>
            </div>
            <div className={c.row}>
              <div className={c.cell}>{t("Adresa")}</div>
              <div className={c.cell}>{parentAddress}</div>
            </div>
          </div>
          <div className={c.docs} onClick={downloadArchive}>
            {files.length} {t("fișiere")}
          </div>
        </div>
        <div>
          {[RequestsStatus.more_info, RequestsStatus.rejected].includes(
            statusToSet
          ) && (
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>{t("Comentariu")}</div>
              <EgInput
                value={comment}
                onChangeValue={changeComment}
                placeholder={t("Descrieti pe scurt situatia")}
                error=""
              />
            </div>
          )}
          {statusToSet === RequestsStatus.rejected && (
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>{t("Motivul")}</div>
              <EgSelect
                value={rejectReason}
                onChangeValue={changeRejectReason}
                options={Object.keys(RequestRejectReason).map(
                  (requestRejectReasonKey) => ({
                    value: RequestRejectReason[requestRejectReasonKey],
                    text: t(`_${requestRejectReasonKey}_`),
                  })
                )}
                error=""
              />
            </div>
          )}
          {statusToSet === RequestsStatus.accepted && (
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>
                {t("Bifați actele prezentate fizic")}
              </div>
              {Object.keys(MatriculationDocument).map(
                (matriculationDocumentKey) => (
                  <div key={matriculationDocumentKey}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            documents.find(
                              (document) =>
                                document ===
                                MatriculationDocument[matriculationDocumentKey]
                            ) !== undefined
                          }
                          onChange={changeDocument(
                            MatriculationDocument[matriculationDocumentKey]
                          )}
                        />
                      }
                      label={t(`_${matriculationDocumentKey}_`)}
                    />
                  </div>
                )
              )}
            </div>
          )}
        </div>
        <div className={c.btns}>
          <EgButton
            className={c.btn}
            text={t(
              statusToSet === RequestsStatus.registered
                ? "Înregistrează"
                : statusToSet === RequestsStatus.more_info
                ? "Solicită"
                : statusToSet === RequestsStatus.rejected
                ? "Respinge"
                : statusToSet === RequestsStatus.accepted
                ? "Înmatriculează"
                : "Resetează"
            )}
            bgColor={
              statusToSet === RequestsStatus.registered
                ? `${colors.green} !important`
                : statusToSet === RequestsStatus.more_info
                ? `${colors.orange} !important`
                : statusToSet === RequestsStatus.rejected
                ? `${colors.red} !important`
                : statusToSet === RequestsStatus.accepted
                ? `${colors.green} !important`
                : `${colors.primary} !important`
            }
            onClick={changeStatus}
            variant="contained"
          />
          <EgButton
            className={c.btn}
            variant="contained"
            text={t("Anulează")}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};
