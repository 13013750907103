import { useMutation } from '@apollo/client';
import { IUpdateRequestStatus } from "utils/entities/request";
import { UPDATE_REQUEST_STATUS } from "utils/globalRequests/updateRequestStatus";

export const useUpdateRequestStatus = () => {
  const [updateRequestStatus, { loading }] = useMutation<IUpdateRequestStatus>(
    UPDATE_REQUEST_STATUS
  );

  return {
    updateRequestStatus,
    updateRequestStatusLoading: loading,
  };
};
