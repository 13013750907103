import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
    "& .red-border": {
      border: `1px solid #d32f2f`,
    },
  },
  datepicker: {
    width: "100%",
    height: 58,
    padding: "0 10px",
    borderRadius: 15,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    fontSize: 18,
    fontFamily: "Open Sans",
    color: theme.colors.ironGray,
    boxSizing: "border-box",
    outline: "none",
    "&:hover": {
      borderColor: "black",
    },
    "&:focus": {
      border: `2px solid ${
        process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary
      }`,
    },
  },
}));
