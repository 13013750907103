import React, { FC } from "react";
import { Tabs, Tab } from "@mui/material";
import { useStyles } from "./styles";
import { checkIsBalti } from "utils/helpers/baltiBranchHelpers";
import { checkIsUngheni } from "utils/helpers/ungheniBranchHelpers";
interface IProps {
  tabs: { value: any; label: string; icon?: any }[];
  selectedTab: any;
  onChangeTab: (selectedTab: any) => void;
}

export const EgTabs: FC<IProps> = ({ tabs, selectedTab, onChangeTab }) => {
  const c = useStyles();
  const isInstitutions = window.location.pathname.includes("institutions");

  const changeTab = (event: React.ChangeEvent<{}>, value: any) => {
    onChangeTab(value);
  };

  return (
    <Tabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={changeTab} className={c.tabs}>
      {tabs.map((tab) => {
        const isBalti = checkIsBalti();
        const isUngheni = checkIsUngheni();
        const label =
          isBalti && isInstitutions
            ? `${tab.label} - `
            : isUngheni && isInstitutions
            ? `${tab.label} - `
            : `${tab.label} - `;
        return (
          <Tab
            key={tab.value}
            value={tab.value}
            label={label}
            iconPosition="end"
            icon={<span className={c.tabCount}>{tab.icon}</span>}
          />
        );
      })}
    </Tabs>
  );
};
