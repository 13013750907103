import { gql } from "@apollo/client";

export const GET_CURRENT_INFO = gql`
  query getCurrentInfo {
    getCurrentInfo {
      user {
        id: _id
        level
        firstName
        lastName
        idnp
        email
      }
      institution {
        id: _id
        name
        language
        places {
          year
          count
          language
        }
        available {
          year
          count
          language
        }
      }
      districts
      statistics {
        requestsNewToday
        requestsNewWeek
        requestsNewMonth
        requestsNewYear
        requestsRegisteredToday
        requestsRegisteredWeek
        requestsRegisteredMonth
        requestsRegisteredYear
        totalRequests
        totalQueue
        totalYear
      }
    }
  }
`;
