import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    paddingBottom: 30,
  },
  title: {
    fontSize: 48,
    fontWeight: 700,
    margin: "50px 0 20px",
  },
});
