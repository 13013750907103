import React from "react";
import { useStyles } from "./styles";
import { Error } from "../error";
import { Select, FormControl, MenuItem, InputLabel } from "@mui/material";

interface IProps {
  value?: any;
  onChangeValue?: any;
  onChange?: any;
  options: { value: any; text: string }[];
  placeholder?: string;
  labelId?: string;
  emptyOption?: any;
  hasEmptyOption?: boolean;
  error?: string;
  ref?: any;
  name?: string;
  defaultValue?: any;
}

export const EgSelect: React.FC<IProps> = React.forwardRef(
  (
    {
      value,
      onChangeValue,
      defaultValue,
      options,
      placeholder,
      labelId,
      emptyOption,
      hasEmptyOption,
      error,
      name,
      onChange,
    },
    ref
  ) => {
    const classes = useStyles();

    const changeValue = (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>
    ) => {
      if (onChangeValue) onChangeValue(event.target.value);
    };

    const getSelectProps = () => {
      const defaultProps = {
        labelId: labelId,
        className: classes.select,
        error: !!error,
        name,
      };

      if (ref) {
        return { ...defaultProps, ref };
      }
      return {
        ...defaultProps,
        onChange: onChangeValue ? changeValue : onChange,
        value,
        defaultValue,
      };
    };

    return (
      <FormControl variant="outlined" className={classes.root}>
        {labelId && value === emptyOption && (
          <InputLabel className={classes.label} id={labelId}>
            {placeholder}
          </InputLabel>
        )}
        <Select {...getSelectProps()}>
          {hasEmptyOption && <MenuItem value={emptyOption}>{placeholder}</MenuItem>}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <Error text={error} />
      </FormControl>
    );
  }
);
