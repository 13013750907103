import { makeStyles } from "@mui/styles";
import institution from "assets/img/institution.svg";
import attach from "assets/img/attach.svg";
import close from "assets/img/close.svg";
import { DefaultTheme } from "@mui/material";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  requestEdit: {
    paddingBottom: 50,
  },
  header: {
    fontSize: 48,
    fontWeight: 700,
    marginBottom: 5,
  },
  subheader: {
    fontSize: 24,
    fontWeight: 400,
    margin: "0 0 50px",
  },
  kidsGardenLabel: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 15,
  },
  kidsGardenContainer: {
    display: "flex",
    marginBottom: 40,
    "@media (min-width: 961px)": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
  },
  kidsGardenIconContainer: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 960px)": {
      marginBottom: 20,
    },
  },
  kidsGardenIcon: {
    background: `url(${institution}) center center no-repeat`,
    backgroundSize: "cover",
    "@media (min-width: 961px)": {
      width: 128,
      height: 128,
    },
    "@media (max-width: 960px)": {
      width: 80,
      height: 80,
      backgroundSize: "contain",
    },
  },
  kidsGardenName: {
    marginLeft: 30,
    fontSize: 24,
  },
  modifyButton: {},
  section: {
    marginTop: 20,
  },
  sectionTitle: {
    color: process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary,
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 30,
  },
  fields: {},
  formGroup: {
    "@media (min-width: 961px)": {
      marginBottom: 30,
    },
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  fileUpload: {
    marginBottom: 20,
  },
  fileUploadIcon: {
    display: "flex",
    alignItems: "center",
    padding: "10px 10px 10px 50px",
    height: 30,
    fontSize: 18,
    fontWeight: 700,
    background: `url(${attach}) left center no-repeat`,
    cursor: "pointer",
    marginBottom: 5,
    "& input": {
      display: "none",
    },
  },
  fileRemover: {
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: 20,
    width: 20,
    height: 20,
    background: `url(${close}) center center no-repeat`,
    backgroundSize: "contain",
    cursor: "pointer",
  },
  ol: {
    fontSize: 18,
    margin: "0 0 20px",
    paddingLeft: 20,
    lineHeight: 1.4,
  },
  text: {
    fontSize: 18,
    marginBottom: 20,
    maxWidth: 570,
    lineHeight: 1.4,
  },
  text2: {
    fontSize: 18,
    color: theme.colors.default,
    opacity: ".75",
    marginBottom: 20,
  },
  text3: {
    fontSize: 14,
    opacity: ".75",
    marginBottom: 50,
    maxWidth: 490,
  },
  checkboxes: {
    "& .PrivateSwitchBase-root-66": {
      padding: 7,
    },
    "& .MuiTypography-root": {
      fontSize: 18,
    },
  },
  addedFile: {
    marginBottom: 10,
    fontSize: 18,
    color: theme.colors.secondary,
  },
}));
