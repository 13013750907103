import { useLazyQuery } from '@apollo/client';
import { IGetRequests } from "utils/entities/request";
import { GET_REQUESTS } from "../requests/getRequests";

export const useGetRequests = () => {
  const [getRequests, { loading, error, data }] = useLazyQuery<IGetRequests>(
    GET_REQUESTS
  );

  return {
    getRequests,
    getRequestsLoading: loading,
    getRequestsError: error,
    getRequestsData: data,
  };
};
