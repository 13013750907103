import { makeStyles } from "@mui/styles";
import cadres from "assets/img/cadres.svg";
import house from "assets/img/house_big.svg";
import location from "assets/img/location_big.svg";
import users from "assets/img/users_big.svg";
import phone from "assets/img/phone.svg";
import planet from "assets/img/planet.svg";
import gr1 from "assets/img/gr1.svg";
import gr2 from "assets/img/gr2.svg";
import gr3 from "assets/img/gr3.svg";
import gr4 from "assets/img/gr4.svg";
import { DefaultTheme } from "@mui/material";

const common = {
  paddingLeft: 70,
  minHeight: 50,
  display: "flex",
  alignItems: "center",
  fontSize: 18,
  fontWeight: 500,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left center",
  margin: "15px 0",
};

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  block: {
    "@media (min-width: 961px)": {
      borderRadius: 15,
      padding: 30,
      border: `1px solid ${theme.colors.lightGray}`,
      marginBottom: 20,
    },
    "@media (max-width: 960px)": {
      padding: "30px 0",
      borderBottom: `1px solid ${theme.colors.lightGray}`,
    },
  },
  row: {
    alignItems: "center",
  },
  cell: {},
  btn: {
    display: "block",
    width: "100%",
    margin: "auto",
    "@media (min-width: 961px)": {
      maxWidth: 280,
    },
    "@media (max-width: 960px)": {
      marginTop: 20,
    },
    "&:not(:first-child)": {
      marginTop: 20,
    },
  },
  district: {
    ...common,
    fontSize: 24,
    fontWeight: 700,
    textTransform: "capitalize",
    backgroundImage: `url(${house})`,
    "@media (min-width: 961px)": {
      margin: 0,
    },
  },
  phone: {
    ...common,
    backgroundImage: `url(${phone})`,
    "@media (min-width: 961px)": {
      margin: 0,
    },
  },
  address: {
    ...common,
    backgroundImage: `url(${location})`,
    backgroundPosition: "6px center",
    "@media (min-width: 961px)": {
      margin: "25px 0 0",
    },
  },
  language: {
    ...common,
    backgroundImage: `url(${planet})`,
    "@media (min-width: 961px)": {
      margin: "25px 0 0",
    },
  },
  title: {
    marginBottom: 10,
    fontSize: 28,
    fontWeight: 700,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    "@media (min-width: 961px)": {
      borderBottom: `1px solid ${theme.colors.primary}`,
    },
    "@media (max-width: 575px)": {
      fontSize: 20,
    },
  },
  itemWrap: {
    paddingLeft: 70,
  },
  item: {
    ...common,
    backgroundImage: `url(${users})`,
    flexDirection: "column",
    alignItems: "start",
  },
  itemTitle: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 2,
  },
  itemText: {
    lineHeight: 1.3,
  },
  cadres: {
    ...common,
    backgroundImage: `url(${cadres})`,
    flexDirection: "column",
    alignItems: "start",
    margin: "0",
    paddingLeft: 90,
  },
  list: {
    padding: "0 0 0 20px",
    margin: 0,
  },
  listItem: {
    listStyle: "none",
    position: "relative",
    padding: "3px 0",
    "&::before": {
      content: '""',
      backgroundColor: theme.colors.primary,
      fontWeight: 700,
      display: "inline-block",
      width: 6,
      height: 6,
      marginLeft: "-1em",
      position: "absolute",
      left: 0,
      top: 10,
      borderRadius: "50%",
    },
  },
  degree: {
    ...common,
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: 53,
    marginTop: 30,
    "&:nth-child(1)": {
      backgroundImage: `url(${gr1})`,
    },
    "&:nth-child(2)": {
      backgroundImage: `url(${gr2})`,
    },
    "&:nth-child(3)": {
      backgroundImage: `url(${gr3})`,
    },
    "&:nth-child(4)": {
      backgroundImage: `url(${gr4})`,
    },
  },
}));
