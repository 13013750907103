import React from "react";
import { OutlinedInput } from "@mui/material";
import { useStyles } from "./styles";
import { Error } from "../error";

interface IProps {
  value?: string;
  onChangeValue?: (value: string) => void;
  onFocus?: any;
  placeholder?: string;
  error?: string;
  type?: string;
  required?: boolean;
  name?: string;
  ref?: any;
  disabled?: boolean;
  pattern?: RegExp;
  maxLength?: number;
}

export const EgInput: React.FC<IProps> = React.forwardRef(
  ({ value, onChangeValue, onFocus, placeholder, error, type, required, name, disabled, pattern, maxLength }, ref) => {
    const classes = useStyles();

    const changeValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (onChangeValue) onChangeValue(event.target.value);
      if (pattern && pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(pattern, "");
        changeValue(event);
      }
      if (maxLength && event.target.value.length > maxLength) {
        onChangeValue && onChangeValue(event.target.value.slice(0, maxLength));
      }
    };

    const getInputProps = () => {
      const defaultProps = {
        className: classes.input,
        placeholder: placeholder,
        error: !!error,
        fullWidth: true,
        type: type,
        required: required,
        name,
        disabled,
      };

      if (ref) {
        return { ...defaultProps, inputRef: ref };
      }

      return {
        ...defaultProps,
        onChange: (e) => {
          changeValue(e);
        },
        onFocus: onFocus,
        value,
      };
    };

    return (
      <div className={classes.wrapper}>
        <OutlinedInput {...getInputProps()} />
        <Error text={error} />
      </div>
    );
  }
);
