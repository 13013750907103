import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  select: {
    borderRadius: "15px !important",
    fontSize: "18px !important",
  },
  label: {
    "&.MuiFormLabel-root": {
      backgroundColor: "white",
      fontFamily: "Open Sans",
      fontSize: 18,
      color: theme.colors.placeholderGray,
    },
  },
}));
