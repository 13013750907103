import { gql } from "@apollo/client";

export const GET_REQUESTS_COUNTERS = gql`
  query getRequests(
    $start: Int
    $limit: Int
    $status: RequestsStatus
    $search: String
    $institutionId: Int
    $year: Int
  ) {
    getRequests(
      start: $start
      limit: $limit
      status: $status
      search: $search
      institutionId: $institutionId
      year: $year
    ) {
      counters {
        withdraw
        unprocessed
        registered
        moreInfo
        rejected
        accepted
      }
    }
  }
`;
