const commonColors = {
  default: "#58595b",
  primary: "#3FB3F9",
  lightGray: "#d7d7d7",
  placeholderGray: "#C2C2C2",
  averageGray: "#333f484d",
  ironGray: "#333F48",
  darkGray: "#868686",
  red: "#cd362c",
  green: "#5fba4e",
  orange: "#efa531",
};

const colors = {
  default: "#58595b",
  primary: "#3FB3F9",
  green: "#5fba4e",
  red: "#cd362c",
  orange: "#efa531",
  ironGray: "#333F48",
  placeholderGray: "#C2C2C2",
  gray: ["#d7d7d7", "#868686"],
  pred_prod: {
    ...commonColors,
    secondary: "#00bff3",
  },
  chisinau: {
    ...commonColors,
    secondary: "#00bff3",
  },
  balti: {
    ...commonColors,
    secondary: "#5293C0",
  },
  ungheni: {
    ...commonColors,
    secondary: "#A54B7C",
  },
};

export default colors;
