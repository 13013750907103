import React, { useContext } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { IRequest, RequestsStatus } from "utils/entities/request";
import { EgButton } from "components/ui/button";
import { RequestInfo } from "components/ui/requestInfo";
import { useNavigate } from "react-router-dom";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

interface IProps {
  request: IRequest;
  onOpenStatusChangePopup: (request: IRequest, status: RequestsStatus) => void;
}

export const Request: React.FC<IProps> = ({
  request,
  onOpenStatusChangePopup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentInfoContext = useContext(CurrentInfoContext);
  const isSuperAdmin = currentInfoContext.roles.isSuperAdmin();

  const editRequest = () => {
    navigate(`/request/${request.id}`);
  };

  const openStatusChangePopup = (status: RequestsStatus) => () => {
    onOpenStatusChangePopup(request, status);
  };

  const requestStatus = request.status;
 
  return (
    <RequestInfo
      request={request}
      actions={
        isSuperAdmin && (
          <>
            <EgButton
              variant="cancel"
              text={t("Editează")}
              onClick={editRequest}
              className={classes.btn}
            />
            {[
              RequestsStatus.more_info,
              RequestsStatus.registered,
              RequestsStatus.rejected,
              RequestsStatus.accepted,
            ].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Resetează")}
                onClick={openStatusChangePopup(RequestsStatus.unprocessed)}
                className={classes.btn}
              />
            )}
            {[RequestsStatus.unprocessed, RequestsStatus.more_info].includes(
              requestStatus
            ) && (
              <EgButton
                variant="cancel"
                text={t("Înregistrează")}
                onClick={openStatusChangePopup(RequestsStatus.registered)}
                className={classes.btnReg}
              />
            )}
            {[RequestsStatus.unprocessed, RequestsStatus.more_info].includes(
              requestStatus
            ) && (
              <EgButton
                variant="cancel"
                text={t("Solicită")}
                onClick={openStatusChangePopup(RequestsStatus.more_info)}
                className={classes.btn}
              />
            )}
            {[
              RequestsStatus.unprocessed,
              RequestsStatus.more_info,
              RequestsStatus.registered,
            ].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Respinge")}
                onClick={openStatusChangePopup(RequestsStatus.rejected)}
                className={classes.btnRej}
              />
            )}
            {[RequestsStatus.registered].includes(requestStatus) && (
              <EgButton
                variant="cancel"
                text={t("Înmatriculează")}
                onClick={openStatusChangePopup(RequestsStatus.accepted)}
                className={classes.btn}
              />
            )}
          </>
        )
      }
    />
  );
};
