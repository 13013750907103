import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/material";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
