import React from "react";
import Institution from "components/modules/Institution";
import { IInstitution } from "utils/entities/institution";

interface IProps {
  items: any;
}

const InstitutionList = ({ items }: IProps) => {
  const render = items.map((item: IInstitution) => <Institution key={item.id} item={item} />);
  return render;
};

export default InstitutionList;
