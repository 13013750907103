import { gql } from "@apollo/client";

export const GET_REQUESTS = gql`
  query getRequests(
    $start: Int
    $limit: Int
    $status: RequestsStatus
    $search: String
    $institutionId: Int
    $year: Int
  ) {
    getRequests(
      start: $start
      limit: $limit
      status: $status
      search: $search
      institutionId: $institutionId
      year: $year
    ) {
      count
      requests {
        id: _id
        number
        creationTime
        updateTime
        birthDate
        childFirstName
        childLastName
        childIdnp
        language
        parentAddress
        parentEmail
        parentIdnp
        parentLocation
        parentPhone
        priority
        priorityOther
        sex
        status
        files {
          id: _id
          name
          size
        }
        history {
          authorId
          author {
            id: _id
            level
          }
          timestamp
          status
          comment
          rejectReason
          documents
          isModified
        }
        institution {
          id: _id
          name
        }
      }
    }
  }
`;
