import { makeStyles } from "@mui/styles";
import bg from "assets/img/hero_bg.png";
import balti_bg from "assets/img/balti_bg.png";
import ungheni_bg from "assets/img/ungheni_bg.png";

const main_bg =
  process.env.REACT_APP_BRANCH_NAME === "balti"
    ? balti_bg
    : process.env.REACT_APP_BRANCH_NAME === "ungheni"
    ? ungheni_bg
    : bg;

export const useStyles = makeStyles({
  hero: {
    height: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "700px" : "532px"}`,
    background: `url(${main_bg}) center center no-repeat`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    margin: `${process.env.REACT_APP_BRANCH_NAME === "balti" ? "0 5% 40px 5%" : "0 0 40px 0"}`,
    "@media (max-width:1400px)": {
      margin: `${process.env.REACT_APP_BRANCH_NAME === "balti" ? "0 2% 40px 2%" : "0 0 40px 0"}`,
    },
    "@media (max-width:1300px)": {
      margin: "0 0 40px 0",
    },
  },
  heroText: {
    width: 440,
    fontSize: 46,
    fontWeight: 800,
    color: "white",
    textShadow: "0 2px 2px rgba(0,0,0,.2)",
    "& div:nth-child(1)": {
      marginLeft: 70,
    },
    "& div:nth-child(2)": {
      marginLeft: 45,
    },
    "& div:nth-child(3)": {
      textAlign: "right",
      fontSize: 30,
      fontWeight: 400,
      textShadow: "none",
      margin: "20px 30px 0 0",
      "@media (max-width:960px)": {},
      "@media (min-width:961px)": {},
    },
  },
  title: {
    fontWeight: 700,
    margin: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "0 auto 17px" : "0 auto 20px"}`,
    padding: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "20px" : ""}`,
    maxWidth: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1010px" : "800px"}`,
    lineHeight: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1.38" : "1.2"}`,
    textIndent: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "-3px" : "0"}`,
    paddingLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "25px" : "0"}`,
    "@media (max-width:960px)": {
      fontSize: 30,
    },
    "@media (min-width:961px)": {
      fontSize: 40,
    },
  },
  subtitle: {
    fontSize: 24,
    paddingLeft: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "11px" : ""}`,
    "& a": {
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width:1000px)": {
      paddingBottom: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "30px" : ""}`,
    },
  },
  heading: {
    "@media (max-width:960px)": {
      marginTop: 20,
      lineHeight: 1.1,
      marginBottom: 30,
    },
    "@media (min-width:961px)": {
      marginBottom: 50,
      lineHeight: 1.4,
      textAlign: "center",
    },
  },
  mainText: {
    maxWidth: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1097px" : "785px"}`,
    fontSize: 18,
    lineHeight: "24px",

    "@media (max-width:960px)": {
      margin: "30px auto",
    },
    "@media (min-width:961px)": {
      margin: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "146px auto" : "100px auto"}`,
    },
    "& p": {
      marginBottom: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "24px" : "20px"}`,
    },
  },
  stepsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "1047px",
    margin: "170px auto",

    "@media (max-width:900px)": {
      width: "100%",
      height: "auto",
      flexDirection: "column",
      margin: "32px 0",
    },
  },
  stepContainer: {
    position: "relative",

    "@media (min-width:901px)": {
      width: 137,
    },
    "@media (max-width:900px)": {
      width: "300px",
      margin: "auto",
      display: "flex",
      marginBottom: "24px",
    },
    "@media (min-width:1000px)": {
      "&:not(:last-child)::after": {
        content: '" "',
        position: "absolute",
        top: "65px",
        left: "103%",
        width: "71px",
        height: 0,
        borderTop: "1px solid #d1d2d4",
      },
    },
  },
  imageContainer: {
    width: "128px",
    height: "128px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (min-width:901px)": {
      margin: "auto",
    },
    "@media (max-width:900px)": {
      "& img": {
        height: "128px",
        width: "auto",
      },
    },
  },
  stepDescription: {
    "@media (max-width:900px)": {
      marginLeft: 16,
    },
  },
  stepCount: {
    color: "#58595b",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 800,
    margin: "30px 0 12px 0",

    "@media (max-width:900px)": {
      textAlign: "left",
    },
  },
  pathStepDescriptor: {
    color: "#58595b",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",

    "@media (max-width:900px)": {
      textAlign: "left",
    },
  },
});
