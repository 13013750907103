import { getBranchConfig } from "utils/helpers/getBranchConfig";

type CitiesConfigType = {
  title: string;
  iframeSrc: string;
  address: string;
  email: string;
  phone: string;
};

const citiesConfig = {
  pred_prod: {
    title: "Contacte",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.444095979312!2d28.823161820411222!3d47.03151584521173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97db603694873%3A0xc21de5be8f2a9e8c!2sGeneral%20Direction%20of%20Education%2C%20Youth%20and%20Sport!5e0!3m2!1sru!2s!4v1605107839804!5m2!1sru!2s",
    address:
      "Direcţia Generală Educaţie,Tineret şi Sport a Consiliului municipal. <br/> Republica Moldova, MD-2004 Chişinau, str.Mitropolit Dosoftei 99.",
    email: "egradinita@pmc.md",
    phone: "+373(22) 23-52-84",
  },
  balti: {
    title: "Contacte",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d515.8414556648668!2d27.92774184403411!3d47.76215015767458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cb60d2ad3881e1%3A0x645ef93d2eacfbe3!2zUHJpbcSDcmlh!5e0!3m2!1sru!2s!4v1676874660905!5m2!1sru!2s",
    address:
      "Direcția Învățământ Tineret și Sport municipiul Bălți. <br/> Republica Moldova, MD-3100 str. Independenței 1, Bălți cab. 415, Anticameră.",
    email: "dits.balti@gmail.com",
    phone: "+373 231 22 307",
  },
  chisinau: {
    title: "Contacte",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.444095979312!2d28.823161820411222!3d47.03151584521173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97db603694873%3A0xc21de5be8f2a9e8c!2sGeneral%20Direction%20of%20Education%2C%20Youth%20and%20Sport!5e0!3m2!1sru!2s!4v1605107839804!5m2!1sru!2s",
    address:
      "Direcţia Generală Educaţie,Tineret şi Sport a Consiliului municipal. <br/> Republica Moldova, MD-2004 Chişinau, str.Mitropolit Dosoftei 99.",
    email: "egradinita@pmc.md",
    phone: "+373(22) 23-52-84",
  },
  ungheni: {
    title: "Contacte",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10842.287786030793!2d27.7980209!3d47.2053902!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cb00213092f48b%3A0x12e28953448a1a94!2sPrim%C4%83ria%20ora%C8%99ului%20Ungheni!5e0!3m2!1sru!2s!4v1703599560738!5m2!1sru!2s",
    address: "Primăria Municipiului Ungheni MD-3600 Ungheni, str. Naţională 7 Republica Moldova",
    email: "gradinite.ungheni@gmail.com",
    phone: "+373 236 229 62",
  },
};

export default getBranchConfig<CitiesConfigType>(citiesConfig);
