export enum DistrictChisinau {
  botanica = "botanica",
  buiucani = "buiucani",
  centru = "centru",
  ciocana = "ciocana",
  riscani = "riscani",
}

export enum DistrictBalti {
  dacia = "dacia",
  centru = "centru",
  autogara = "autogara",
  cartierul8 = "cartierul8",
  slobozia = "slobozia",
  baltii_noi = "baltii_noi",
}

export enum DistrictUngheni {
  danuteni = "danuteni",
  tineretului = "tineretului",
  centru = "centru",
  ungheni_vale = "ungheni_vale",
}

export const District = { ...DistrictChisinau, ...DistrictBalti, ...DistrictUngheni };
