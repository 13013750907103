import common from '../index';

const translationChisinau = {
  nameSpaceRo: {
    ...common.nameSpaceRo,
    "loginAuthLinkTitle": "DGETS",
  },
  nameSpaceRu: {
    ...common.nameSpaceRu,
    "loginAuthLinkTitle": "DGETS",
  }
}

export default translationChisinau;
