import React, {useState, useEffect} from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { backendBirthDateToDateString, backendDateToDateString, backendDateToTimeString } from "utils/dates";
import useAppData from "hooks/useAppData";
interface IProps {
  item: any;
}

const Request: React.FC<IProps> = ({ item }) => {
  const { userRole, loading } = useAppData();
  const classes = useStyles();
  const { t } = useTranslation();

  const { id, number, creationTime, updateTime, birthDate, childIdnp, childFirstName, childLastName, status } = item;

  const showPersonalData = !loading && userRole !== "anonimous";

  return (
    <div className={clsx(classes.block, status)}>
      <div className={classes.left}>
        <div className={classes.header}>
          <div className={classes.id}>#{id}</div>
          <div className={classes.time}>{backendDateToTimeString(creationTime)}</div>
          <div className={classes.time}>{backendDateToDateString(creationTime)}</div>
        </div>
        {number && (
          <div className={classes.row}>
            <div className={classes.cell}>{t("Număr de ordine")}</div>
            <div className={classes.cell}>{number}</div>
          </div>
        )}
        <div className={classes.body}>
          <div className={classes.name}>
            {childFirstName} {childLastName}
          </div>
          {showPersonalData &&(
          <div className={classes.idnp}>
            <div className={classes.row}>
              <div className={classes.cell}>IDNP</div>
              <div className={classes.cell}>{childIdnp}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.cell}>{t("Data nașterii")}</div>
              <div className={classes.cell}>{backendBirthDateToDateString(birthDate)}</div>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.header}>
          <div className={classes.time}>{backendDateToTimeString(updateTime)}</div>
          <div className={classes.time}>{backendDateToDateString(updateTime)}</div>
        </div>
        <div className={classes.body}>
          <div className={classes.status}>{t(`_${status}_`)}</div>
        </div>
      </div>
    </div>
  );
};

export default Request;
