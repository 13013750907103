import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { useRegisterParent } from "./hooks/useRegisterParent";
import { useStyles } from "./styles";
import { regex } from "utils/regex";
import useMessage from "main/hooks/useMessage";

interface IProps {
  login?: boolean | undefined;
}

export const Authorization: React.FC<IProps> = ({ login }) => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | undefined>();

  const [
    isAuthorizationLinkSent,
    setIsAuthorizationLinkSent,
  ] = useState<boolean>(false);

  const { registerParent } = useRegisterParent();
  const { t } = useTranslation();
  const classes = useStyles({ login });
  const showMessage = useMessage();

  const changeEmail = (email: string) => {
    setEmail(email);
    setEmailError(undefined);
  };

  const isEmailValid = () => {
    if (!regex.email.test(email)) {
      setEmailError(t("field_invalid_email"));

      return false;
    }

    return true;
  };

  const tryRegisterParent = async () => {
    try {
      const response = await registerParent({
        variables: {
          email,
        },
      });

      if (!response.errors) {
        setIsAuthorizationLinkSent(true);
      } else {
        showMessage(response.errors[0].message, "error");
      }
    } catch (error) {
      showMessage(error.message, "error");
    }
  };

  const requestAccess = () => {
    if (isEmailValid()) {
      tryRegisterParent();
    }
  };

  return (
    <>
      {isAuthorizationLinkSent ? (
        <div className={classes.linkSentMessage}>
          <div className={classes.texts}>
            <div className={classes.title}>{t("Expediat")}!</div>
            <div className={classes.text}>
              {t(
                "Link-ul de autorizare a fost transmis cu succes pe adresa de e-mail specificată"
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.authorization}>
          <div className={classes.input}>
            <EgInput
              value={email}
              onChangeValue={changeEmail}
              placeholder={t("Introduceți adresa de e-mail")}
              error={emailError}
            />
          </div>
          <EgButton
            text={t(
              login
                ? "Trimite e-mail cu link la cabinetul părintelui"
                : "Solicită acces"
            )}
            variant="contained"
            onClick={requestAccess}
            className={classes.btn}
          />
        </div>
      )}
    </>
  );
};
