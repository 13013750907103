import { useQuery } from '@apollo/client';
import { IGetCurrentInfo } from "utils/entities/currentInfo";
import { GET_CURRENT_INFO } from "../requests/getCurrentInfo";

export const useGetCurrentInfo = () => {
  const { loading, error, data } = useQuery<IGetCurrentInfo>(GET_CURRENT_INFO);

  return {
    getCurrentInfoLoading: loading,
    getCurrentInfoError: error,
    getCurrentInfoData: data,
  };
};
