import { makeStyles } from "@mui/styles";
import burger from "assets/img/hamburger-menu.svg";
import close_burger from "assets/img/close.svg";
import bg from "assets/img/hero_bg.png";
import balti_bg from "assets/img/balti_bg.png";
import ungheni_bg from "assets/img/ungheni_bg.png";
import { DefaultTheme } from "@mui/material";
import { NoSchemaIntrospectionCustomRule } from "graphql";

const main_bg =
  process.env.REACT_APP_BRANCH_NAME === "balti"
    ? balti_bg
    : process.env.REACT_APP_BRANCH_NAME === "ungheni"
    ? ungheni_bg
    : bg;

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    "@media (max-width:960px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  logo: {
    "@media (min-width:501px) and (max-width:960px)": {
      maxWidth: 260,
    },
    "@media (max-width:500px)": {
      maxWidth: 150,
    },
  },
  headerTop: {},
  topRow: {
    justifyContent: "space-between",
    width: 1000,
    paddingTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni"}` ? 32 : 20,
    paddingBottom: `${process.env.REACT_APP_BRANCH_NAME === "ungheni"}` ? 26 : 20,
    borderBottom: `${process.env.REACT_APP_BRANCH_NAME === "ungheni"}`
      ? `1px solid ${theme.colors.averageGray}`
      : `1px solid ${theme.colors.default}`,
    "@media (max-width:960px)": {
      alignItems: "center",
      paddingLeft: 25,
      paddingRight: 0,
    },
  },
  menu: {
    borderBottom: `1px solid ${theme.colors.default}`,
    "@media (max-width:960px)": {
      position: "fixed",
      left: 0,
      top: 0,
      zIndex: 9,
      width: 0,
      height: "100%",
      transition: "background .2s",
    },
  },
  menuIsOpen: {
    "@media (max-width:960px)": {
      width: "100%",
      background: "rgba(0,0,0,.75)",
      "& $menuRow": {
        paddingTop: 20,
        width: "60%",
        transform: "translate(0)",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        overflow: "auto",
      },
    },
  },
  menuRow: {
    "@media (max-width:960px)": {
      maxWidth: 385,
      background: "white",
      height: "100%",
      flexDirection: "column",
      flexWrap: "nowrap",
      overflow: "auto",
      transform: "translate(-100%)",
      transition: "transform .2s",
      "& .MuiGrid-item:first-child $menuItem": {
        color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
      },
    },
    "@media (min-width:961px)": {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  menuItem: {
    display: "block",
    position: "relative",
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.colors.default,
    transition: "color .2s",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
    },
    "@media (max-width:960px)": {
      padding: "27px 35px",
      fontSize: 18,
    },
    "@media (min-width:961px)": {
      padding: "25px 0",
      fontSize: 20,
    },
  },
  withSubmenu: {
    "@media (max-width:960px)": {
      "& $menuItem": {
        borderBottom: 0,
      },
    },
  },
  submenuWrap: {
    "@media (max-width:960px)": {
      display: "block",
    },
    "@media (min-width:961px)": {
      paddingTop: 20,
      position: "absolute",
      zIndex: 9,
      left: -70,
      top: "100%",
      width: 250,
      opacity: 1,
      visibility: "visible",
      pointerEvents: "inherit",
      transition: "opacity .2s, visibility .2s",
    },
  },
  submenu: {
    listStyle: "none",
    margin: "0",
    padding: "5px 30px",
    width: "100%",
    overflow: "scroll",
    "@media (max-width:960px)": {
      paddingTop: 15,
      boxSizing: "border-box",
    },
    "@media (max-width:420px)": {
      padding: "27px 0",
      fontSize: 15,
    },
    "@media (min-width:961px)": {
      background: "#fff",
      borderRadius: 15,
      boxShadow: "0 20px 30px rgba(0,0,0,.35)",
    },
    "& li": {
      "&:not(:first-child)": {
        "@media (min-width:961px)": {
          borderTop: `1px solid ${theme.colors.lightGray}`,
        },
      },
      "& a": {
        display: "block",
        padding: "20px 0",
        textTransform: "uppercase",
        fontSize: 18,
        color: theme.colors.default,
        textDecoration: "none",
        transition: "color, .2s",
        "@media (max-width:960px)": {
          fontWeight: 400,
        },
        "&:hover": {
          color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
        },
      },
    },
  },
  burger: {
    width: 32,
    height: 27,
    background: `url(${burger})`,
    backgroundSize: "contain",
  },
  menuClose: {
    width: 50,
    height: "100%",
    background: `url(${burger}) center center no-repeat`,
    backgroundSize: "contain",
    position: "absolute",
    top: 0,
    opacity: ".5",
  },
  headerTopMob: {
    height: 185,
    position: "relative",
    display: "flex",
    alignItems: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? "center" : "flex-start"}`,
    justifyContent: "flex-end",


    "& img": {
      marginRight: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? "20px" : "auto"}`,
      marginTop: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? "0" : "5px"}`,
      width: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? "auto" : "160px"}`,
    },
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      background: `url(${main_bg}) center center no-repeat`,
      backgroundSize: "cover",
      transform: "scaleX(-1)",
      position: "absolute",
      zIndex: -1,
    },
  },
}));
