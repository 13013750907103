import { Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetRequests } from "./hooks/useGetRequests";
import { useStyles } from "./styles";
import pageHeaderIco from "assets/img/list_ico.svg";
import PageHeader from "components/ui/pageHeader";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { EgButton } from "components/ui/button";
import { Request } from "./request";
import useMessage from "main/hooks/useMessage";
import { useLogout } from "utils/globalHooks/useLogout";
import { Loader } from "components/ui/loader";
import { UpMover } from "components/ui/upMover";
import { apiRootUrl } from "utils/network";
import { IRequest, RequestsStatus } from "utils/entities/request";
import { WithdrawPopup } from "./withdrawPopup";
import { useUpdateRequestStatus } from "utils/globalHooks/useUpdateRequestStatus";

const ParentCabinet: React.FC<{}> = () => {
  const currentInfoContext = useContext(CurrentInfoContext);
  const [requests, setRequests] = useState<IRequest[]>([]);

  const [withdrawPopupRequest, setWithdrawPopupRequest] = useState<IRequest | undefined>();

  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const showMessage = useMessage();

  const { getRequestsLoading, getRequestsError, getRequestsData } = useGetRequests();

  const { updateRequestStatus } = useUpdateRequestStatus();

  const { logoutLoading } = useLogout();

  useEffect(() => {
    if (getRequestsData) {
      setRequests(getRequestsData.getRequests.requests);
    }
  }, [getRequestsData]);

  if (getRequestsLoading || logoutLoading) {
    return <Loader />;
  }

  if (getRequestsError) {
    return showMessage(getRequestsError.message, "error");
  }

  const doLogout = () => {
    window.location.href = `${apiRootUrl}/logout`;
  };

  const sendRequest = () => {
    navigate("/request");
  };

  const openWithdrawPopup = (request: IRequest) => () => {
    setWithdrawPopupRequest(request);
  };

  const refreshRequestStatus = (refreshedRequest: IRequest) => {
    setRequests((prevRequests) => {
      const requests = [...prevRequests];

      const requestIndex = requests.findIndex((request) => request.id === refreshedRequest.id);

      requests.splice(requestIndex, 1, refreshedRequest);

      return requests;
    });
  };

  const withdrawRequest = async () => {
    try {
      const response = await updateRequestStatus({
        variables: {
          id: withdrawPopupRequest.id,
          status: RequestsStatus.withdraw,
        },
      });

      if (!response.errors) {
        refreshRequestStatus(response.data?.requestStatusUpdate.request);
        setWithdrawPopupRequest(undefined);
      } else {
        showMessage(response.errors[0].message, "error");
      }
    } catch (error) {
      showMessage(error.message, "error");
    }
  };

  const closeWithdrawPopup = () => {
    setWithdrawPopupRequest(undefined);
  };

  const user = currentInfoContext?.currentInfo.user;

  return (
    <>
      <PageHeader title={t("Lista de cereri")} subtitle={t("Cererile depuse de către dvs.")} ico={pageHeaderIco} />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={classes.cabinet}>
        <div className={classes.header}>
          <div className={classes.left}>
            <div className={classes.name}>
              {user.firstName} {user.lastName}
            </div>
            <div className={classes.email}>{user.email}</div>
          </div>
          <div className={classes.right}>
            <div className={classes.logoutContainer}>
              <span className={classes.logout} onClick={doLogout}>
                {t("Ieșire")}
              </span>
              <EgButton variant="contained" text={t("Cerere nouă")} onClick={sendRequest} />
            </div>
          </div>
        </div>
        <div>
          {requests.map((request) => (
            <Request key={request.id} request={request} onWithdrawRequest={openWithdrawPopup(request)} />
          ))}
        </div>
        {withdrawPopupRequest && (
          <WithdrawPopup request={withdrawPopupRequest} onWithdraw={withdrawRequest} onClose={closeWithdrawPopup} />
        )}
        <UpMover />
      </Container>
    </>
  );
};

export default ParentCabinet;
