const common = {
  nameSpaceRo: {
    "loginParentCabinet": "Cabinetul părintelui",
    "loginCabinetAccessError": "Accesul la cabinetul părintelui se face prin accesarea unui link trimis anterior la adresa de e-mail care a fost folosită pentru depunerea cererii online. Dacă ați pierdut e-mail-ul respectiv, introduceți adresa electronică dvs. în căsuța de mai jos și apăsați 'Trimite' pentru a primi din nou link-ul cu acces la cabinetul părintelui.",
    "loginMailAccessError": "Dacă nu mai aveți acces la e-mail, contactați",
  },
  nameSpaceRu: {
    "loginParentCabinet": "Кабинет родителя",
    "loginCabinetAccessError": "Доступ к кабинету родителя осуществляется путем доступа по ссылке, ранее отправленной на адрес электронной почты, который использовался для подачи заявления онлайн. Если вы потеряли это электронное письмо, введите вашу элетронную почту в поле ниже и нажмите «Отправить», чтобы получить ссылку для доступа к родительскому офису снова.",
    "loginMailAccessError": "Если у вас больше нет доступа к электронной почте, свяжитесь с",
  }
}

export default common;
