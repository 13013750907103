import React, { useContext, useState, useEffect } from "react";
import { LanguageSwitch } from "../languageSwitch";
import { Container, Grid, useMediaQuery } from "@mui/material";
import { useStyles } from "./styles";
import logoPNG from "assets/img/logo.png";
import logoBalti from "assets/img/logo_balti.png";
import logoUngheni from "assets/img/logo_ungheni.png";
import primariaLogo from "assets/img/primaria_logo.svg";
import primariaLogoBalti from "assets/img/primaria_balti.png";
import primariaLogoUngheni from "assets/img/primaria_ungheni.png";
import primariaLogoMob from "assets/img/primaria_logo2.svg";
import primariaLogoBaltiMob from "assets/img/primaria_balti_mob.png";
import primariaLogoUngheniMob from "assets/img/primaria_ungheni.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

export const Header = ({ showMenu, setShowMenu, activeSubMenu, setActiveSubMenu }) => {
  const isMob = useMediaQuery("(max-width: 960px)");
  const c = useStyles();
  const { t } = useTranslation();
  const currentInfoContext = useContext(CurrentInfoContext);
  const isAnonymous = currentInfoContext.roles.isAnonymous();
  const infoRef: any = React.createRef();
  const regRef: any = React.createRef();

  const logoHeader =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? logoBalti
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? logoUngheni
      : logoPNG;

  const logoPrimaria =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? primariaLogoBalti
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? primariaLogoUngheni
      : primariaLogo;

  const logoPrimariaMob =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? primariaLogoBaltiMob
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? primariaLogoUngheniMob
      : primariaLogoMob;

  const isAnyAdmin = currentInfoContext.roles.isSuperAdmin() || currentInfoContext.roles.isAdmin();

  useEffect(() => {
    const handleOutsideClick = (e: { target: any }) => {
      if (
        (infoRef.current && !infoRef.current?.contains(e.target)) ||
        (regRef.current && !regRef.current?.contains(e.target)) 
      ) {
        setShowMenu(false);
        setActiveSubMenu(null);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [infoRef, regRef]);

  const handleToggle = (e: any) => {
    return infoRef.current?.contains(e.target) || regRef.current?.contains(e.target)
      ? null
      : setShowMenu((prevState) => !prevState);
  };

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [showMenu]);

  const toggleBlockVisibility = (submenuName: string) => {
    setActiveSubMenu(activeSubMenu === submenuName ? null : submenuName);
  };

  const pLogo = <img src={logoPrimaria} alt="logoPrimaria" />;
  const maxWidth = process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1330px" : "1280px";

  return (
    <Grid item mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? -0.7 : 0}>
      {isMob && (
        <div className={c.headerTopMob}>
          <img src={logoPrimariaMob} alt="logoPrimaria" />
        </div>
      )}
      <Container maxWidth={false} sx={{ maxWidth: maxWidth }}>
        <div className={c.headerTop}>
          <Grid container className={c.topRow}>
            {isMob && (
              <Grid item>
                <div className={c.burger} onClick={handleToggle}></div>
              </Grid>
            )}
            <Grid item>
              <Link to="/">
                <img src={logoHeader} className={c.logo} alt="logoHeader" />
              </Link>
            </Grid>
            {!isMob && (
              <Grid item mt={process.env.REACT_APP_BRANCH_NAME === "ungheni" ? 3 : 0}>
                {pLogo}
              </Grid>
            )}
            {isMob && (
              <Grid item>
                <LanguageSwitch />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={clsx(c.menu, showMenu && c.menuIsOpen)} onClick={handleToggle}>
          <Grid container className={c.menuRow}>
            {isMob && (
              <Grid item>
                <div className={c.menuItem}>
                  <div className={c.menuClose}></div>
                </div>
              </Grid>
            )}
            <Grid item>
              <Link to="/institutions" className={c.menuItem}>
                {t("Grădinițe")}
              </Link>
            </Grid>
            <Grid item>
              <div className={clsx(c.menuItem, c.withSubmenu)} onClick={() => toggleBlockVisibility("info")}>
                <div ref={infoRef}>{t("Informații utile")}</div>
                {activeSubMenu === "info" && (
                  <div className={c.submenuWrap}>
                    <ul className={c.submenu}>
                      <li>
                        <Link to="/gid">{t("Ghid")}</Link>
                      </li>
                      <li>
                        <Link to="/reglament">{t("Regulament")}</Link>
                      </li>
                      <li>
                        <Link to="/question">{t("Întrebări frecvente")}</Link>
                      </li>
                      <li>
                        <Link to="/raport" className={c.menuItem}>
                          {t("Raportează abuz")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item>
              {isAnyAdmin ? (
                <div className={clsx(c.menuItem, c.withSubmenu)} onClick={() => toggleBlockVisibility("cabinet")}>
                  <div ref={regRef}>{t("Cabinet")}</div>
                  {activeSubMenu === "cabinet" && (
                    <div className={c.submenuWrap}>
                      <ul className={c.submenu}>
                        <li>
                          <Link to="/">{t("Cereri")}</Link>
                        </li>
                        <li>
                          <Link to="/users" className={c.menuItem}>
                            {t("Administratori")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/raports">{t("Rapoarte")}</Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <Link to={isAnonymous ? "/login" : "/"} className={c.menuItem}>
                  {t("Cabinet")}
                </Link>
              )}
            </Grid>
            <Grid item>
              <Link to="/contacts" className={c.menuItem}>
                {t("Contacte")}
              </Link>
            </Grid>
            {!isMob && (
              <Grid item>
                <LanguageSwitch />
              </Grid>
            )}
          </Grid>
        </div>
      </Container>
    </Grid>
  );
};
