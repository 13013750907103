import React, { useEffect, useRef, useCallback } from "react";

interface IframeProps extends React.HTMLAttributes<HTMLIFrameElement> {
  inferredClick: (iframe?: HTMLIFrameElement) => void;
  src: string;
}

const common = {
  frameBorder: "0",
  width: "100%",
  height: "700",
};

export default function Iframe(props: IframeProps): React.ReactElement {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const iframeCallbackRef = useCallback((node: null | HTMLIFrameElement): void => {
    iframeRef.current = node;
  }, []);

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === "iframe" &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        props.inferredClick();
      }
    };

    const iframe = iframeRef.current;

    if (iframe) {
      window.addEventListener("blur", onBlur);
    }

    return () => {
      if (iframe) {
        window.removeEventListener("blur", onBlur);
      }
    };
  }, [props.inferredClick]);

  return <iframe {...common} src={props.src} ref={iframeCallbackRef} />;
}
