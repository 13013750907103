import { useLazyQuery } from '@apollo/client';
import { IGetRequest } from "utils/entities/request";
import { GET_REQUEST } from "../requests/getRequest";

export const useGetRequest = () => {
  const [getRequest, { loading, error, data }] = useLazyQuery<IGetRequest>(
    GET_REQUEST
  );

  return {
    getRequest,
    getRequestLoading: loading,
    getRequestError: error,
    getRequestData: data,
  };
};
