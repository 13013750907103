import React, { useContext } from "react";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import { useTranslation } from "react-i18next";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";
import { Container } from "@mui/material";
import { useLogout } from "utils/globalHooks/useLogout";
import { Loader } from "components/ui/loader";
import { useStyles } from "./styles";
import { RequestList } from "./requestList";
import { UpMover } from "components/ui/upMover";
import { EgButton } from "components/ui/button";
import { unauthorizeDgets } from "utils/dgetsAuth";
import { UserLevel } from "utils/entities/user";

const AdminCabinet: React.FC<{}> = () => {
  const currentInfoContext = useContext(CurrentInfoContext);
  const c = useStyles();
  const { t } = useTranslation();
  const { logoutLoading } = useLogout();

  if (logoutLoading) {
    return <Loader />;
  }

  const doLogout = async () => {
    await unauthorizeDgets();
  };

  const user = currentInfoContext.currentInfo.user;
  const adminLevel =
    user.level === UserLevel.super_admin
      ? process.env.REACT_APP_BRANCH_NAME === "balti"
        ? t("Autorizare DÎTS")
        : process.env.REACT_APP_BRANCH_NAME === "ungheni"
        ? t("Autorizare IETU")
        : t("Autorizare DGETS")
      : t("Supervizor");

  return (
    <>
      <PageHeader title={t("Lista de cereri")} subtitle={t("Cererile depuse de către dvs.")} ico={pageHeaderIco} />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
        <div className={c.header}>
          <div className={c.name}>
            {user.firstName} {user.lastName} ({adminLevel})
          </div>
          <EgButton text={t("Ieșire")} onClick={doLogout} variant="logout" />
        </div>
        <RequestList />
        <UpMover />
      </Container>
    </>
  );
};

export default AdminCabinet;
