import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  formGroup: {
    marginBottom: 30,
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  email: {
    marginBottom: 30,
    "& span": {
      color: theme.colors.darkGray,
      marginLeft: 30,
      fontWeight: 400,
    },
  },
  title: {
    marginBottom: 15,
  },
  freePlaces: {
    fontWeight: 700,
    marginBottom: 50,
  },
  freePlacesGrid: {
    justifyContent: "space-between",
  },
  freePlacesItem: {
    "& span": {
      fontWeight: 400,
      marginLeft: 30,
    },
  },
  btns: {
    marginBottom: 100,
    "& button": {
      "&:first-child": {
        marginRight: 20,
      },
    },
  },
}));
