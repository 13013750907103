import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { branchName } from "../constants";

// http://iura.studii.md:3434/
// https://egradinita.studii.md/data/
// http://192.168.1.11:3434/
// http://balti.studii.md:3434/

// "http://chisinau.studii.md:3434/"
// "http://balti.studii.md:3434/"

export const apiRootUrl =
  process.env.NODE_ENV === "development" ? `http://chisinau.studii.md:3434/data/` : `${window.location.origin}/data/`;


export const fileUploadUrl =
  branchName === "balti" 
    ? "https://i.simpalsmedia.com/upload/ac6e7e10-b133-11ed-9240-0242ac110018"
    :  
  `${apiRootUrl}upload`;

export const fileDownloadUrl = `${apiRootUrl}download/request-files`;

export const createApolloClient = () =>
  new ApolloClient({
    link: setContext(() => ({})).concat(
      ApolloLink.from([
        new HttpLink({
          uri: apiRootUrl,
          credentials: "include",
        }),
      ])
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });
