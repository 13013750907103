import { CircularProgress } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface IProps {
  height?: number;
}

export const Loader: FC<IProps> = ({ height = 300 }) => {
  const classes = useStyles({ height });

  return (
    <div className={classes.dimmer}>
      <CircularProgress color="inherit" />
    </div>
  );
};
