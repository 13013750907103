import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  header: {
    display: "flex",
    fontSize: 28,
    fontWeight: 700,
    borderBottom: `1px solid ${theme.colors.darkGray}`,
    paddingBottom: 20,
    marginBottom: 30,
    "@media (min-width: 961px)": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
  },
  name: {
    "@media (max-width: 960px)": {
      marginBottom: 20,
    },
  },
}));
