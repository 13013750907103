import { useLazyQuery } from '@apollo/client';
import { IGetUsers } from "utils/entities/user";
import { GET_ADMINS } from "../requests/getAdmins";

export const useGetAdmins = () => {
  const [getAdmins, { loading, error, data }] = useLazyQuery<IGetUsers>(
    GET_ADMINS
  );

  return {
    getAdmins,
    getAdminsLoading: loading,
    getAdminsError: error,
    getAdminsData: data,
  };
};

export const useGetSupervizors = () => {
  const [getSupervizors, { loading, error, data }] = useLazyQuery<IGetUsers>(
    GET_ADMINS
  );

  return {
    getSupervizors,
    getSupervizorsLoading: loading,
    getSupervizorsError: error,
    getSupervizorsData: data,
  };
};
