import React, { useEffect, useMemo, useState } from "react";
import { Container, FormControl, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetInstitution } from "main/hooks/useGetInstitution";
import { useGetPublicRequests } from "main/hooks/useGetPublicRequest";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import RequestList from "components/modules/RequestList";
import { UpMover } from "components/ui/upMover";
import useMessage from "main/hooks/useMessage";
import { IRequest } from "utils/entities/request";
import { EgInput } from "components/ui/input";
import { EgSelect } from "components/ui/select";
import { Loader } from "components/ui/loader";
import { getBirthYears } from "utils/birthYears";
import { EgButton } from "components/ui/button";
import { deleteUrlParameter, getUrlParameter, setUrlParameter } from "utils/queryParams";

import colors from "assets/colors";

const currentThemeColors = colors[process.env.REACT_APP_BRANCH_NAME] || colors.chisinau;

const customButtom = `${process.env.REACT_APP_BRANCH_NAME !== "ungheni"}`
  ? currentThemeColors.primary
  : currentThemeColors.secondary;

const PublicRegister: React.FC<{}> = () => {
  const { institutionID } = useParams();
  const searchUrlParam = getUrlParameter("search");
  const birthYearUrlParam = getUrlParameter("birthYear");

  const [search, setSearch] = useState<string>(() => searchUrlParam || "");

  const [birthYear, setBirthYear] = useState<number | undefined>(() =>
    birthYearUrlParam ? Number(birthYearUrlParam) : undefined
  );

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [areMoreRequests, setAreMoreRequests] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessage();

  const { getInstitutionError, getInstitutionData } = useGetInstitution(Number(institutionID));

  const { getPublicRequests, getPublicRequestsLoading, getPublicRequestsError, getPublicRequestsData } =
    useGetPublicRequests();

  useEffect(() => {
    setRequests([]);

    getPublicRequests({
      variables: {
        institutionId: Number(institutionID),
        search,
        year: birthYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, birthYear]);

  useEffect(() => {
    if (getPublicRequestsData) {
      setAreMoreRequests(
        requests.length + getPublicRequestsData.getPublicRequests.requests.length <
          getPublicRequestsData.getPublicRequests.count
      );

      setRequests((prevRequests) => [...prevRequests, ...getPublicRequestsData.getPublicRequests.requests]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPublicRequestsData]);

  const birthYearOptions = useMemo(() => getBirthYears(), []);

  if (getInstitutionError) {
    return showMessage(getInstitutionError.message, "error");
  }

  if (getPublicRequestsError) {
    return showMessage(getPublicRequestsError.message, "error");
  }

  const changeSearch = (search: string) => {
    setUrlParameter("search", search);
    setSearch(search);
  };

  const changeBirthYear = (birthYear?: number) => {
    if (birthYear) {
      setUrlParameter("birthYear", birthYear.toString());
    } else {
      deleteUrlParameter("birthYear");
    }

    setBirthYear(birthYear);
  };

  const loadMoreRequests = () => {
    getPublicRequests({
      variables: {
        institutionId: Number(institutionID),
        search,
        year: birthYear,
        start: requests.length,
      },
    });
  };

  return (
    <>
      <PageHeader
        title={getInstitutionData?.getInstitution.name || ""}
        subtitle={t("Registru public")}
        ico={pageHeaderIco}
      />
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
        <Grid container className={classes.filters} spacing={3}>
          <Grid item lg={8} xs={12}>
            <div className={classes.label}>
              {t("Introduceți numărul cererii sau ultimele 4 cifre din IDNP copilului")}
            </div>
            <EgInput value={search} onChangeValue={changeSearch} placeholder={t("Caută...")} error="" />
          </Grid>
          <Grid item lg={4} xs={12}>
            <div className={classes.label}>{t("Anul nașterii")}</div>
            <FormControl variant="outlined" className={classes.formControl}>
              <EgSelect
                value={birthYear}
                onChangeValue={changeBirthYear}
                options={birthYearOptions.map((birthYear) => ({
                  value: birthYear,
                  text: birthYear.toString(),
                }))}
                placeholder={t("Toate")}
                emptyOption={0}
                defaultValue={0}
                hasEmptyOption
                error=""
              />
            </FormControl>
          </Grid>
        </Grid>
        {!requests.length && getPublicRequestsLoading ? (
          <Loader />
        ) : (
          <div className={classes.requests}>
            <RequestList items={requests} />
            {areMoreRequests && (
              <EgButton text={t("Mai multe")} onClick={loadMoreRequests} bgColor={`${customButtom} !important`} />
            )}
          </div>
        )}
        <UpMover />
      </Container>
    </>
  );
};

export default PublicRegister;
