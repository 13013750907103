import { makeStyles } from "@mui/styles";
import rejected from "assets/img/rejected.svg";
import accepted from "assets/img/accepted.svg";
import more_info from "assets/img/more_info.svg";
import dw from "assets/img/download.svg";
import { DefaultTheme } from "@mui/material";

const statusCommon = (theme: DefaultTheme) => ({
  backgroundPosition: "left center",
  backgroundRepeat: "no-repeat",
  paddingLeft: 50,
  color: theme.colors.default,
  lineHeight: 1.3,
});

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    border: `1px solid ${theme.colors.lightGray}`,
    borderRadius: 15,
    marginBottom: 20,

    "@media (min-width: 961px)": {
      padding: 40,
    },
    "@media (max-width: 960px)": {
      padding: 15,
    },
  },
  block: {
    display: "flex",

    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
    "&.unprocessed $status": {
      color: "#b6b6b7",
    },
    "&.registered": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${accepted})`,
      },
      "& $id": {
        backgroundColor: theme.colors.green,
      },
    },
    "&.more_info": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${more_info})`,
      },
      "& $id": {
        backgroundColor: theme.colors.orange,
      },
    },
    "&.rejected": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${rejected})`,
      },
      "& $id": {
        backgroundColor: theme.colors.red,
      },
    },
    "&.accepted": {
      "& $status": {
        backgroundImage: `url(${accepted})`,
        ...statusCommon(theme),
      },
      "& $id": {
        backgroundColor: theme.colors.green,
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    "@media (min-width: 961px)": {
      marginBottom: 30,
    },
    "@media (max-width: 960px)": {
      marginBottom: 15,
    },
  },
  body: {},
  id: {
    background: theme.colors.default,
    color: "white",
    padding: "4px 20px",
    borderRadius: 15,
    marginRight: 20,
  },
  left: {
    "@media (min-width: 961px)": {
      width: "70%",
    },
    "@media (max-width: 960px)": {
      marginBottom: 30,
      paddingBottom: 30,
      borderBottom: `1px solid ${theme.colors.lightGray}`,
    },
  },
  right: {
    "@media (min-width: 961px)": {
      width: "30%",
    },
  },
  time: {
    color: theme.colors.darkGray,
    marginRight: 10,
  },
  date: {},
  idnp: {},
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    fontSize: 18,
  },
  cell: {
    "&:nth-child(1)": {
      width: 180,
      minWidth: 180,
      fontWeight: 700,
    },
  },
  name: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 10,
    textTransform: "capitalize",
  },
  institution: {
    marginBottom: 20,
  },
  status: {
    fontSize: 28,
    fontWeight: 700,
    textTransform: "capitalize",
    marginBottom: 25,
  },
  blockTitle: {
    fontWeight: 700,
    color: theme.colors.primary,
    marginBottom: 20,
  },
  rowWrap: {
    marginBottom: 50,
  },
  bodyFlex: {
    height: "calc(100% - 50px)",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& button": {
      "@media (max-width: 960px)": {
        width: "100%",
      },
    },
  },
  docs: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    margin: "20px 0",
    background: `url(${dw}) center left no-repeat`,
    padding: "5px 0 5px 40px",

    "& .MuiFormControl-root": {
      width: 38,
      height: 20,

      "& .MuiInputBase-root": {
        height: 20,
      },

      "& .MuiSelect-outlined": {
        padding: 0,
      },
    },
  },
  docsCount: {
    display: "flex",
    marginRight: 10,
  },
}));
