import React from "react";
import { useStyles } from "./styles";

interface IProps {
  text?: string;
}

export const Error: React.FC<IProps> = ({ text }) => {
  const classes = useStyles();

  return <div className={classes.error}>{text}</div>;
};
