import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useStyles } from "./styles";

export const UpMover = () => {
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();

  const updateVisibility = () => {
    if (window.pageYOffset > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", updateVisibility);

    return () => {
      document.removeEventListener("scroll", updateVisibility);
    };
  }, []);

  const moveUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <div className={classes.mover} onClick={moveUp}>
          <ExpandLessIcon fontSize="large" />
        </div>
      )}
    </>
  );
};
