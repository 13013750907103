import React from "react";
import Request from "components/modules/Request";

interface IProps {
  items: any;
}

const RequestList = ({ items }: IProps) => (
  <div>
    {items.map((item: any) => (
      <Request key={item.id} item={item} />
    ))}
  </div>
);

export default RequestList;
