import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationBalti from "translations/balti";
import translationChisinau from "translations/chisinau";
import translationUngheni from "translations/ungheni";

import { appSettings } from "./utils/appSettings";
import roJson from "translations/ro.json";
import ruJson from "translations/ru.json";
import { getElseSetCookie } from "./utils/cookies";

const language = getElseSetCookie("language", appSettings.defaultLanguage);

i18n.use(initReactI18next).init({
  resources: {
    ro: {
      translation: roJson,
      baltiNamespace: translationBalti.nameSpaceRo,
      chisinauNamespace: translationChisinau.nameSpaceRo,
      ungheniNamespace: translationUngheni.nameSpaceRo,
    },
    ru: {
      translation: ruJson,
      baltiNamespace: translationBalti.nameSpaceRu,
      chisinauNamespace: translationChisinau.nameSpaceRu,
      ungheniNamespace: translationUngheni.nameSpaceRu,
    },
  },
  lng: language,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
