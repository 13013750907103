import React from "react";
import { useStyles } from "./styles";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import logoConfig from "config/logoConfig";

export const StepInfo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { step_1_logo, step_2_logo, step_3_logo, step_4_logo, step_5_logo } = logoConfig;
  const maxWidth = process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1330px" : "1280px";

  const stepsToShow = [
    {
      imgLogo: step_1_logo,
      imgAlt: "first-step",
      stepName: "Pasul 1",
      stepDescriptor: "Autorizați-vă",
    },
    {
      imgLogo: step_2_logo,
      imgAlt: "second-step",
      stepName: "Pasul 2",
      stepDescriptor: "Alegeți instituția",
    },
    {
      imgLogo: step_3_logo,
      imgAlt: "third-step",
      stepName: "Pasul 3",
      stepDescriptor: "Aplicați cererea",
    },
    {
      imgLogo: step_4_logo,
      imgAlt: "fourth-step",
      stepName: "Pasul 4",
      stepDescriptor: "Verificați rezultatele",
    },
    {
      imgLogo: step_5_logo,
      imgAlt: "fifth-step",
      stepName: "Pasul 5",
      stepDescriptor: "Prezentați-vă la instituție",
    },
  ];

  return (
    <Container maxWidth={false} sx={{ maxWidth: maxWidth }} className={classes.stepsWrapper}>
      {stepsToShow.map(({ imgLogo, imgAlt, stepName, stepDescriptor }, index) => (
        <div key={index} className={classes.stepContainer}>
          <div className={classes.imageContainer}>
            <img src={imgLogo} alt={imgAlt} />
          </div>
          <div className={classes.stepDescription}>
            <div className={classes.stepCount}>{t(stepName)}</div>
            <div className={classes.pathStepDescriptor}>{t(stepDescriptor)}</div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export default StepInfo;
