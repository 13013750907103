import { DistrictChisinau, DistrictBalti, DistrictUngheni } from "./district";
import { IError } from "./error";
import { Language } from "./language";
import { IUser } from "./user";

export interface IPlaces {
  year: number;
  count: number;
  language: string;
}

export interface IGrades {
  superior: number;
  first: number;
  second: number;
  without: number;
}

export enum InstitutionStatus {
  all = "all",
  not_set = "not_set",
  set = "set",
}

export interface IInstitution {
  id: number;
  name: string;
  phone: string;
  address: string;
  //methodistEmail: string;
  language: Language;
  places: IPlaces[];
  available: IPlaces[];
  grades: IGrades;
  infrastructure: string[];
  addresses: string[];
  district: DistrictChisinau | DistrictBalti | DistrictUngheni;
  director: IUser;
}

export interface IGetInstitution {
  getInstitution: IInstitution;
}

export interface ICounter {
  // __typename: 'DistrictCounter';
  district: DistrictChisinau | DistrictBalti | DistrictUngheni;
  count: number;
}

export interface IGetInstitutions {
  counters(arg0: string, counters: any): unknown;
  getInstitutions: {
    count: number;
    institutions: IInstitution[];
    counters: ICounter[];
  };
}

export interface IGetInstitutionsCounters {
  getInstitutions: {
    counters: any;
  };
}

export interface ICreateOrUpdateInstitution {
  institutionCreateOrUpdate: {
    institution: IInstitution;
    errors: IError[];
  };
}
