import { gql } from "@apollo/client";

export const GET_INSTITUTIONS = gql`
  query getInstitutions(
    $start: Int
    $limit: Int
    $district: District
    $search: String
    $year: Int
    $status: InstitutionStatus
  ) {
    getInstitutions(
      start: $start
      limit: $limit
      district: $district
      search: $search
      year: $year
      status: $status
    ) {
      institutions {
        id: _id
        name
        director {
          id: _id
          level
          firstName
          lastName
          idnp
        }
      }
    }
  }
`;
