import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IRequest, RequestsStatus } from "utils/entities/request";
import { EgButton } from "components/ui/button";
import { RequestInfo } from "components/ui/requestInfo";

interface IProps {
  request: IRequest;
  onWithdrawRequest: () => void;
}

export const Request: React.FC<IProps> = ({ request, onWithdrawRequest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const modifyRequest = () => {
    navigate(`/request/${request.id}`);
  };

  const requestStatus = request.status;
  
  return (
    <RequestInfo
      request={request}
      actions={
        <>
          {[RequestsStatus.unprocessed, RequestsStatus.more_info].includes(
            requestStatus
          ) && (
            <EgButton
              variant="cancel"
              text={t("Editează")}
              onClick={modifyRequest}
              className={classes.btnEdit}
            />
          )}
          {[
            RequestsStatus.unprocessed,
            RequestsStatus.more_info,
            RequestsStatus.registered,
            RequestsStatus.accepted,
          ].includes(requestStatus) && (
            <EgButton
              variant="cancel"
              text={t("Retrage")}
              onClick={onWithdrawRequest}
              className={classes.btnRemove}
            />
          )}
        </>
      }
    />
  );
};
