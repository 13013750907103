import { Grid } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "utils/constants/index";
import { IPlaces } from "utils/entities/institution";
import useMessage from "main/hooks/useMessage";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { useUpdateFreePlaces } from "./hooks/useUpdateFreePlaces";
import { useStyles } from "./styles";

interface IProps {
  institutionId: number;
  //email: string;
  places: IPlaces[];
  available: IPlaces[];
  language: string;
}

export const FreePlaces: FC<IProps> = ({
  institutionId,
  //email,
  places,
  available,
  language
}) => {
  //const [methodistEmail, setMethodistEmail] = useState<string>(email);
  //const [methodistEmailError, setMethodistEmailError] = useState<string>("");

  const [availablePlaces, setAvailablePlaces] = useState<IPlaces[]>(() => {
    return available.map(
      (placesEntry) =>
        ({ year: placesEntry.year, count: placesEntry.count, language: placesEntry.language } as IPlaces)
    );
  });

  const [freePlaces, setFreePlaces] = useState<IPlaces[]>(() => {
    return places.map(
      (placesEntry) =>
        ({ year: placesEntry.year, count: placesEntry.count, language: placesEntry.language } as IPlaces)
    );
  });

  const [freePlacesErrors, setFreePlacesErrors] = useState<string[]>(
    Array<string>(CONSTANTS.childMaxAge - CONSTANTS.childMinAge + 1).map(
      () => ""
    )
  );

  //const previousMethodistEmailRef = useRef<string>("");
  const previousFreePlacesRef = useRef<IPlaces[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { updateFreePlaces, updateFreePlacesLoading } = useUpdateFreePlaces();
  const classes = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessage();

  const enterEditMode = () => {
    //previousMethodistEmailRef.current = methodistEmail;

    previousFreePlacesRef.current = freePlaces.map(
      (freePlacesEntry) => ({ ...freePlacesEntry } as IPlaces)
    );

    setIsEditMode(true);
  };

  /* const changeMethodistEmail = (methodistEmail: string) => {
    setMethodistEmail(methodistEmail);
    setMethodistEmailError("");
  }; */

  const changePlacesCount = (placesIndex: number) => (placesCount: string) => {
    setFreePlaces((prevFreePlaces) => {
      const freePlaces = [...prevFreePlaces];

      freePlaces[placesIndex].count = Number(Number(placesCount).toFixed(0));

      return freePlaces;
    });

    setFreePlacesErrors((prevFreePlacesErrors) => {
      const freePlacesErrors = [...prevFreePlacesErrors];

      freePlacesErrors[placesIndex] = "";

      return freePlacesErrors;
    });
  };

  const updateAvailablePlaces = (availablePlaces: IPlaces[]) => {
    setAvailablePlaces(availablePlaces);
  };

  const areFieldsValid = () => {
    let isAnyFieldInvalid = false;   

    freePlaces.forEach((freePlacesEntry, freePlacesEntryIndex) => {
      if (freePlacesEntry.count < 0) {
        isAnyFieldInvalid = true;

        freePlacesErrors[freePlacesEntryIndex] = t("field_min_value", {
          minValue: 0,
        });
      }

      if (freePlacesEntry.count > 1000) {
        isAnyFieldInvalid = true;

        freePlacesErrors[freePlacesEntryIndex] = t("field_max_value", {
          maxValue: 1000,
        });
      }
    });

    if (isAnyFieldInvalid) {
      setFreePlacesErrors([...freePlacesErrors]);
    }

    return !isAnyFieldInvalid;
  };

  const save = async () => {
    if (!updateFreePlacesLoading && areFieldsValid()) {
      try {
        const response = await updateFreePlaces({
          variables: {
            id: institutionId,
            //methodistEmail,
            places: freePlaces,
          },
        });

        if (!response.errors) {
          updateAvailablePlaces(
            response.data.institutionCreateOrUpdate.institution.available
          );

          setIsEditMode(false);
        } else {
          showMessage(response.errors[0].message, "error");
        }
      } catch (error) {
        showMessage(error.message, "error");
      }
    }
  };

  const abort = () => {
    //setMethodistEmail(previousMethodistEmailRef.current);
    //setMethodistEmailError("");
    setFreePlaces(previousFreePlacesRef.current);

    setFreePlacesErrors(
      Array<string>(CONSTANTS.childMaxAge - CONSTANTS.childMinAge + 1).map(
        () => ""
      )
    );

    setIsEditMode(false);
  };

  return (
    <>
      {isEditMode ? (
        <div>
          <Grid container spacing={3}>            
            {freePlaces.map((freePlacesEntry, freePlacesEntryIndex) => {
              if (language !== 'both' && language !== freePlacesEntry.language) return null;

              return (
              <Grid key={`${freePlacesEntry.year}-${freePlacesEntry.language}`} item xs={4}>
                <div className={classes.formGroup}>
                  <div className={classes.formGroupLabel}>
                    {`${t("Locuri p-u născuți în")} ${freePlacesEntry.year} ${freePlacesEntry.language.toUpperCase()}`}
                  </div>
                  <EgInput
                    type="number"
                    value={freePlacesEntry.count.toString()}
                    onChangeValue={changePlacesCount(freePlacesEntryIndex)}
                    placeholder={t("Introduceți")}
                    error={freePlacesErrors[freePlacesEntryIndex]}
                  />
                </div>
              </Grid>
              )})
            }
          </Grid>
          <div className={classes.btns}>
            <EgButton variant="contained"  text={t("Salvează")} onClick={save} />
            <EgButton variant="cancel" text={t("Anulează")} onClick={abort} />
          </div>
        </div>
      ) : (
        <div className={classes.freePlaces}>         
          <div className={classes.title}>{t("Locuri preconizate")}:</div>
          <Grid container spacing={1} className={classes.freePlacesGrid}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                {availablePlaces.filter(item => language === 'both' || language === item.language).map((availablePlacesEntry) => (
                  <Grid
                    key={`${availablePlacesEntry.year}-${availablePlacesEntry.language}`}
                    item
                    xs={6}
                    className={classes.freePlacesItem}
                  >
                    {`${t("Anul nașterii")} ${availablePlacesEntry.year} ${availablePlacesEntry.language.toUpperCase()}`}
                    <span>{availablePlacesEntry.count}</span>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <EgButton
                text={t("Editează")}
                onClick={enterEditMode}
                variant="cancel"
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
