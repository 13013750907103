import { gql } from "@apollo/client";

export const UPDATE_FREE_PLACES = gql`
  mutation institutionCreateOrUpdate($id: Int, $places: [PlacesInput]) {
    institutionCreateOrUpdate(
      _id: $id

      places: $places
    ) {
      errors {
        field
        message
      }
      institution {
        id: _id
        available {
            year
            count
            language
        }
      }
    }
  }
`;
