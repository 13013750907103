import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  dimmer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: 1,
  },
  popup: {
    backgroundColor: "white",
    padding: 60,
    maxHeight: 640,
    width: 640,
    overflowY: "auto",
  },
  btns: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    flex: 1,
    minWidth: "auto",
    maxWidth: "49%",
    width: "100%",
  },
});
