import { gql } from "@apollo/client";

export const GET_REQUEST = gql`
  query getRequest($id: Int!) {
    getRequest(_id: $id) {
      id: _id
      institutionId
      birthDate
      childFirstName
      childIdnp
      childLastName
      files {
        id: _id
        name
        size
      }
      language
      parentAddress
      parentEmail
      parentIdnp
      parentLocation
      parentPhone
      priority
      priorityOther
      sex
    }
  }
`;
