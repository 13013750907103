import { createTheme } from "@mui/material/styles";

import colors from "./colors";
import { Palette } from "@mui/icons-material";

const currentThemeColors = colors[process.env.REACT_APP_BRANCH_NAME] || colors.chisinau;

const theme = createTheme({
  palette: {
    primary: {
      main: `${
        process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? currentThemeColors.primary : currentThemeColors.secondary
      }`,
    },
  },
  colors: {
    ...currentThemeColors,
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni"}`
              ? currentThemeColors.primary
              : currentThemeColors.secondary,
          },
        },
      ],
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          // color: "#fff",
          lineHeight: 1,
          "&:hover": {
            background: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni"}`
              ? currentThemeColors.primary
              : currentThemeColors.secondary,
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flex: "auto",
          fontSize: 24,
          fontWeight: 700,
          textTransform: "none",
        },
        textColorPrimary: {
          color: currentThemeColors.default,
        },
        labelIcon: {
          "& .MuiTab-wrapper > *:first-of-type": {
            marginBottom: 0,
            marginLeft: 15,
          },
        },
        wrapped: {
          flexDirection: "row-reverse",
          alignItems: "center",
        },
      },
    },
  },
});

export default theme;
