import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const common = {
  margin: `0 0 10px 0 !important`,
};

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  btn: {
    ...common,
  },
  btnReg: {
    ...common,
    color: `${theme.colors.green} !important`,
  },
  btnRej: {
    ...common,
    color: `${theme.colors.red} !important`,
  },
}));
