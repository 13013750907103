import { gql } from "@apollo/client";

export const CUD_USER = gql`
  mutation usersCUD(
    $id: Int
    $level: UserLevel
    $firstName: String
    $idnp: String
    $institutionId: Int
    $lastName: String
    $remove: Boolean
  ) {
    usersCUD(
      _id: $id
      level: $level
      firstName: $firstName
      idnp: $idnp
      institutionId: $institutionId
      lastName: $lastName
      remove: $remove
    ) {
      errors {
        message
        field
      }
      user {
        id: _id
        firstName
        lastName
        idnp
        level
      }
    }
  }
`;
