import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  mover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    color: `${process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary}`,
    backgroundColor: "white",
    bottom: 100,
    right: 70,
    width: 50,
    height: 50,
    border: `2px solid ${
      process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary
    }`,
    borderRadius: 30,
    cursor: "pointer",
    zIndex: 1,

    "&:hover": {
      color: "white",
      backgroundColor: `${
        process.env.REACT_APP_BRANCH_NAME !== "ungheni" ? theme.colors.primary : theme.colors.secondary
      }`,
      border: "2px solid #fff",
    },
  },
}));
