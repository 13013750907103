import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";

interface IProps {
  title: string;
  subtitle: string;
  ico?: string;
  icoBottom?: number;
}

const PageHeader: React.FC<IProps> = ({ title, subtitle, ico, icoBottom }) => {
  const classes = useStyles();
  const style = {
    backgroundImage: `url(${ico})`,
    backgroundPositionY: icoBottom,
  };

  return (
    <div className={classes.root}>
      <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={classes.ct} style={style}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </Container>
    </div>
  );
};

export default PageHeader;
