import React from "react";
import { useStyles } from "./styles";
import { Error } from "../error";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  value?: Date;
  onChangeValue: (value?: Date) => void;
  placeholder?: string;
  error?: string;
  maxDate?: Date;
  minDate?: Date;
}

export const EgDatepicker: React.FC<IProps> = ({ value, onChangeValue, placeholder, error, maxDate, minDate }) => {
  const classes = useStyles();

  const changeValue = (value?: Date) => {
    onChangeValue(value);
  };

  return (
    <div className={classes.wrapper}>
      <ReactDatePicker
        className={`${classes.datepicker} datepicker`}
        selected={value}
        onChange={changeValue}
        placeholderText={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat="dd/MM/yyyy"
        showYearDropdown
        onFocus={(e) => (e.target.readOnly = true)}
        onBlur={(e) => (e.target.readOnly = false)}
      />
      <Error text={error} />
    </div>
  );
};
