import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  stepsWrapper: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "1047px !important",
    margin: "170px auto",
    paddingRight: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "30px !important" : ""}`,
    marginTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "163px !important" : ""}`,

    "@media (max-width:900px)": {
      width: "100%",
      height: "auto",
      flexDirection: "column",
      margin: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "32px 0 !important" : "32px 0"}`,
    },
  },
  stepContainer: {
    position: "relative",

    "@media (min-width:901px)": {
      width: 137,
    },
    "@media (max-width:900px)": {
      width: "300px",
      margin: "auto",
      display: "flex",
      marginBottom: "24px",
    },
    "@media (min-width:1050px)": {
      "&:not(:last-child)::after": {
        content: '" "',
        position: "absolute",
        top: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "70px" : "65px"}`,
        left: "103%",
        width: "68px",
        height: 0,
        borderTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "2px solid #A54B7C" : "1px solid #d1d2d4"}`,
      },
    },
  },
  imageContainer: {
    width: "128px",
    height: "128px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (min-width:901px)": {
      margin: "auto",
    },
    "@media (max-width:900px)": {
      "& img": {
        height: "128px",
        width: "auto",
      },
    },
  },
  stepDescription: {
    "@media (max-width:900px)": {
      marginLeft: 16,
    },
  },
  stepCount: {
    display: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "none" : "block"}`,
    color: "#58595b",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 800,
    margin: "30px 0 12px 0",

    "@media (max-width:900px)": {
      textAlign: "left",
    },
  },
  pathStepDescriptor: {
    color: "#58595b",
    fontSize: "18px",
    fontWeight: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? 700 : 500}`,
    textAlign: "center",
    paddingTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "25px" : "none"}`,

    "@media (max-width:900px)": {
      textAlign: "left",
      paddingTop: `${process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "50px" : "none"}`,
    },
  },
});
