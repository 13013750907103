import { IError } from "./error";
import { IFile } from "./file";
import { IInstitution } from "./institution";
import { Language } from "./language";
import { Sex } from "./sex";
import { IUser } from "./user";
import { MatriculationDocument } from "./matriculationDocument";

export enum RequestsPriority {
  parents_work = "parents_work",
  single_parent = "single_parent",
  studying_parents = "studying_parents",
  protected = "protected",
  child_disability = "child_disability",
  parent_disability = "parent_disability",
  parent_military = "parent_military",
  tree_or_more_children = "tree_or_more_children",
  brother_in_same = "brother_in_same",
  parent_employee = "parent_employee",
  other = "other",
}

export enum RequestsStatus {
  withdraw = "withdraw",
  unprocessed = "unprocessed",
  registered = "registered",
  more_info = "more_info",
  rejected = "rejected",
  accepted = "accepted",
}

export enum RequestRejectReason {
  no_district_correspondence = "no_district_correspondence",
  not_all_documents = "not_all_documents",
  no_places = "no_places",
}

export interface IRequestHistory {
  authorId: number;
  author: IUser;
  status: RequestsStatus;
  rejectReason: RequestRejectReason;
  documents: MatriculationDocument[];
  timestamp: Date;
  comment: string;
  isModified: boolean;
}

export interface IRequest {
  id: number;
  number: string;
  birthDate: Date;
  childIdnp: string;
  childFirstName: string;
  childLastName: string;
  files: IFile[];
  institutionId: number;
  institution: IInstitution;
  language: Language;
  parentIdnp: string;
  parentEmail: string;
  parentLocation: string;
  parentAddress: string;
  parentPhone: string;
  priority: RequestsPriority[];
  priorityOther: string;
  creationTime: Date;
  updateTime: Date;
  sex: Sex;
  status: RequestsStatus;
  author: IUser;
  history: IRequestHistory[];
}

export interface ICreateOrUpdateRequest {
  requestCreateOrUpdate: {
    errors: IError[];
  };
}

export interface IGetRequest {
  getRequest: IRequest;
}

export interface IGetRequests {
  getRequests: {
    count: number;
    requests: IRequest[];
  };
}

export interface IGetRequestsCounters {
  getRequests: {
    counters: any;
  };
}

export interface IGetPublicRequests {
  getPublicRequests: {
    count: number;
    requests: IRequest[];
  };
}

export interface IUpdateRequestStatus {
  requestStatusUpdate: {
    errors: IError[];
    request: IRequest;
  };
}
