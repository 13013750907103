import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {},
  label: {
    fontSize: 24,
    marginBottom: 20,
  },
  filters: {
    marginBottom: 30,
  },
  select: {
    borderRadius: 15,
    fontSize: 18,
    color: theme.colors.default,
  },
  formControl: {
    width: "100%",
  },
  requests: {
    marginBottom: 20,
  },
}));
