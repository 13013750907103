import { useQuery } from "@apollo/client";
import { GET_CURRENT_INFO } from "main/requests/getCurrentInfo";
import { useState, useEffect } from "react";
import { IGetCurrentInfo } from "utils/entities/currentInfo";

function useAppData() {
  const [branchName] = useState(process.env.REACT_APP_BRANCH_NAME || "default");
  const { data, loading } = useQuery<IGetCurrentInfo>(GET_CURRENT_INFO);

  return { branchName, userRole: data?.getCurrentInfo?.user?.level || null, loading };
}

export default useAppData;
