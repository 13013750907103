import { makeStyles } from "@mui/styles";
import rejected from "assets/img/rejected.svg";
import accepted from "assets/img/accepted.svg";
import more_info from "assets/img/more_info.svg";
import { DefaultTheme } from "@mui/material";

const statusCommon = (theme: DefaultTheme) => ({
  backgroundPosition: "left center",
  backgroundRepeat: "no-repeat",
  paddingLeft: 50,
  color: theme.colors.default,
  lineHeight: 1.3,
});

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {},
  block: {
    border: `1px solid ${theme.colors.lightGray}`,
    borderRadius: 15,
    padding: 40,
    marginBottom: 20,
    display: "flex",
    "&.unprocessed $status": {
      color: "#b6b6b7",
    },
    "&.registered": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${accepted})`,
      },
      "& $id": {
        backgroundColor: theme.colors.green,
      },
    },
    "&.more_info": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${more_info})`,
      },
      "& $id": {
        backgroundColor: theme.colors.orange,
      },
    },
    "&.rejected": {
      "& $status": {
        ...statusCommon(theme),
        backgroundImage: `url(${rejected})`,
      },
      "& $id": {
        backgroundColor: theme.colors.red,
      },
    },
    "&.accepted": {
      "& $status": {
        backgroundImage: `url(${accepted})`,
        ...statusCommon(theme),
      },
      "& $id": {
        backgroundColor: theme.colors.green,
      },
    },

    "@media (max-width: 480px)": {
      flexDirection: "column",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    fontSize: 18,
  },
  body: {},
  id: {
    background: theme.colors.default,
    color: "white",
    padding: "4px 20px",
    borderRadius: 15,
    marginRight: 20,
  },
  left: {
    width: "70%",
  },
  right: {
    width: "30%",
    "@media (max-width: 480px)": {
      marginTop: 20,
    },
  },
  time: {
    color: theme.colors.darkGray,
    marginRight: 10,
  },
  date: {},
  idnp: {},
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    fontSize: 18,
  },
  cell: {
    "&:nth-child(1)": {
      width: 180,
      fontWeight: 700,
    },
  },
  name: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 25,
    textTransform: "capitalize",
  },
  status: {
    fontSize: 28,
    fontWeight: 700,
    textTransform: "capitalize",
    marginBottom: 25,
  },
}));
