import React, { useContext } from "react";
import { Container, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { EgButton } from "components/ui/button";
import { IInstitution } from "utils/entities/institution";
import { useNavigate } from "react-router-dom";
import { CurrentInfoContext } from "utils/globalContexts/currentInfoContext";

interface IProps {
  item: IInstitution;
}

const InstitutionAbout = ({ item }: IProps) => {
  const c = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentInfoContext = useContext(CurrentInfoContext);
  const {
    id,
    district,
    phone,
    address,
    language,
    available,
    infrastructure,
    addresses,
    grades,
  } = item;

  const { superior, first, second, without } = grades;

  const cadresCount = superior + first + second + without;

  const renderPlace = available.map((item: { count: number; year: number; language: string }) => {
    const { count, year, language } = item;

    return (
      <Grid item sm={6} md={4} lg={3} className={c.item} key={year}>
        <div className={c.itemWrap}>
          <div className={c.itemTitle}>
            {count} {t("locuri")}
          </div>
          <div className={c.itemText}>
            {`${t("p-u născuți în")} ${year} ${language?.toUpperCase()}`}
          </div>
        </div>
      </Grid>
    );
  });

  const renderInfrastructure = infrastructure.length
    ? infrastructure.map((inf) => (
        <li className={c.listItem} key={inf}>
          {inf}
        </li>
      ))
    : null;

  const renderAddresses = addresses.length
    ? addresses.map((adr) => (
        <li className={c.listItem} key={adr}>
          {adr}
        </li>
      ))
    : null;

  const handleRegister = () => {
    navigate(`/public-register/${id}`);
  };

  const handleEdit = () => {
    navigate(`/institution/${id}/edit`);
  };

  return (
    <Container maxWidth={false} sx={ { maxWidth: '1280px' } }>
      <div className={c.block}>
        <Grid container className={c.row}>
          <Grid item md={7} xs={12}>
            <Grid container>
              <Grid item md={6} xs={12} className={c.district}>
                {t(`_${district}`)}
              </Grid>
              <Grid item md={6} xs={12} className={c.phone}>
                {phone}
              </Grid>
              <Grid item md={6} xs={12} className={c.address}>
                {address}
              </Grid>
              <Grid item md={6} xs={12} className={c.language}>
                {(language === "both"
                  ? "Ro/Ру"
                  : t(`_${language}_`)
                ).toUpperCase()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <EgButton
              text={t("Registru public")}
              onClick={handleRegister}
              className={c.btn}
              variant="contained"
            />
            {currentInfoContext.roles.isSuperAdmin() && (
              <EgButton
                text={t("Editează")}
                onClick={handleEdit}
                className={c.btn}
                variant="contained"
              />
            )}
          </Grid>
        </Grid>
      </div>
      <div className={c.block}>
        <div className={c.title}>{t("Locuri libere")}</div>
        <Grid container spacing={2}>
          {renderPlace}
          {/* <div className={c.cadres}>
            <div className={c.itemTitle}>{cadresCount}</div>
            <div className={c.itemText}>{t("cadre didactice")}</div>
          </div> */}
        </Grid>
      </div>
      <div className={c.block}>
        <div className={c.title}>
          <div className={c.cadres}></div>
          {t("Calificarea cadrelor didactice")} ({cadresCount})
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4} lg={3} className={c.degree}>
            <div className={c.itemWrap}>
              <div className={c.itemTitle}>{superior}</div>
              <div className={c.itemText}>{t("Gradul superior")}</div>
            </div>
          </Grid>
          <Grid item xs={6} md={4} lg={3} className={c.degree}>
            <div className={c.itemWrap}>
              <div className={c.itemTitle}>{first}</div>
              <div className={c.itemText}>{t("Gradul I")}</div>
            </div>
          </Grid>
          <Grid item xs={6} md={4} lg={3} className={c.degree}>
            <div className={c.itemWrap}>
              <div className={c.itemTitle}>{second}</div>
              <div className={c.itemText}>{t("Gradul II")}</div>
            </div>
          </Grid>
          <Grid item xs={6} md={4} lg={3} className={c.degree}>
            <div className={c.itemWrap}>
              <div className={c.itemTitle}>{without}</div>
              <div className={c.itemText}>{t("Fără grad")}</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={c.block}>
        <div className={c.title}>{t("Infrastructura")}</div>
        <ul className={c.list}>{renderInfrastructure}</ul>
      </div>
      <div className={c.block}>
        <div className={c.title}>{t("Adresele de deservire")}</div>
        <ul className={c.list}>{renderAddresses}</ul>
      </div>
    </Container>
  );
};

export default InstitutionAbout;
