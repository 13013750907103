import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const Raport: React.FC<{}> = () => {
  const c = useStyles();
  const { t } = useTranslation();

  const iframeSrc =
    process.env.REACT_APP_BRANCH_NAME === "balti"
      ? "https://docs.google.com/forms/d/e/1FAIpQLScDhj05dtTrmCD4KuOZtMXR7sTLBqgtqRypQ3YnIrWlwruNew/viewform?embedded=true"
      : process.env.REACT_APP_BRANCH_NAME === "ungheni"
      ? "https://docs.google.com/forms/d/e/1FAIpQLSfK9CvXbKzlhFp9WUVNZOGHE4KUS5Dk0UpfABy-M5lbN0OVSA/viewform?embedded=true"
      : "https://docs.google.com/forms/d/e/1FAIpQLSfIbnhWdbo2rc_kmZBlb6mQ8-cQ5o3iZ1936HNlEuEfJRDQxA/viewform?embedded=true";

  return (
    <Container maxWidth={false} sx={{ maxWidth: "1280px" }} className={c.root}>
      <div className={c.title}>{t("Raportează abuz")}</div>
      <iframe src={iframeSrc} width="640" height="725" frameBorder="0" title="Raport">
        {t("Se încarcă...")}
      </iframe>
    </Container>
  );
};

export default Raport;
