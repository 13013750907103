import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    paddingBottom: 30,
  },
  title: {
    fontSize: 48,
    fontWeight: 700,
    margin: "50px 0 20px",
  },
  textBlock: {
    marginBottom: 30,
    fontSize: 18,
    lineHeight: 1.4,
    "@media (min-width: 961px)": {
      maxWidth: "70%",
    },
    "& a": {
      color: theme.colors.primary,
    },
  },
  formGroup: {
    "@media (min-width: 961px)": {
      marginBottom: 30,
    },
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
}));
