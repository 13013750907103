import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IInstitution } from "utils/entities/institution";
import { IUser } from "utils/entities/user";
import { regex } from "utils/regex";
import { EgButton } from "components/ui/button";
import { EgInput } from "components/ui/input";
import { useStyles } from "./styles";
import { IErrors } from "../index";
interface IProps {
  admin?: IUser;
  institution?: IInstitution;
  editedUser?: IUser;
  editedUserErrors?: IErrors;
  isSuperAdmin?: boolean;
  onEnterEditMode: () => void;
  onExitEditMode: () => void;
  onChangeField: (fieldName: string, fieldValue: string) => void;
  onSave: () => void;
  onRemoveUser: () => void;
}

export const User: FC<IProps> = ({
  admin,
  institution,
  editedUser,
  editedUserErrors,
  isSuperAdmin,
  onEnterEditMode,
  onExitEditMode,
  onChangeField,
  onSave,
  onRemoveUser,
}) => {
  const { t } = useTranslation();
  const c = useStyles();

  const changeField = (fieldName: string) => (fieldValue: string) => {
    onChangeField(fieldName, fieldValue);
  };

  const person = admin ? admin : institution.director ? institution.director : undefined;

  const userIdnp = person?.idnp || "";
  const userFullName = `${person?.firstName || ""} ${person?.lastName || ""}`;
  const institutionName = institution?.name || "";

  const level = {
    super_admin:
      process.env.REACT_APP_BRANCH_NAME === "balti"
        ? t("Autorizare DÎTS")
        : process.env.REACT_APP_BRANCH_NAME === "ungheni"
        ? t("Autorizare IETU")
        : t("Autorizare DGETS"),
    admin: t("Supervizor"),
    not_set: t("Nu este setat"),
  };

  const defineRole = () => (admin ? level[admin.level] : t("Director"));

  return (
    <div className={c.user}>
      {!!editedUser ? (
        <div>
          <div>
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>{t("Nume")}</div>
              <EgInput
                value={editedUser.lastName}
                onChangeValue={changeField("lastName")}
                placeholder={t("Introduceți")}
                error={editedUserErrors.lastName}
                pattern={regex.name}
              />
            </div>
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>{t("Prenume")}</div>
              <EgInput
                value={editedUser.firstName}
                onChangeValue={changeField("firstName")}
                placeholder={t("Introduceți")}
                error={editedUserErrors.firstName}
                pattern={regex.name}
              />
            </div>
            <div className={c.formGroup}>
              <div className={c.formGroupLabel}>IDNP</div>
              <EgInput
                value={editedUser.idnp}
                onChangeValue={changeField("idnp")}
                placeholder={t("Introduceți")}
                error={editedUserErrors.idnp}
                pattern={regex.idnp}
                maxLength={13}
              />
            </div>
          </div>
          <div className={c.btns}>
            <EgButton className={c.btn} text={t("Salvează")} variant="contained" onClick={onSave} />
            <EgButton className={c.btn} text={t("Anulează")} variant="cancel" onClick={onExitEditMode} />
          </div>
        </div>
      ) : (
        <div className={c.block}>
          <div className={c.blockLeft}>
            <div className={c.header}>
              <div className={c.role}>{defineRole()}</div>
              {userIdnp && <div>IDNP: {userIdnp}</div>}
            </div>
            <div className={c.name}>{person ? userFullName : t("Nesetat")}</div>
            {institution && (
              <div className={c.inst}>
                <b className={c.instLabel}>{t("Instituția")}</b>
                {institutionName}
              </div>
            )}
          </div>
          {isSuperAdmin && (
            <div className={c.blockRight}>
              <EgButton variant="cancel" text={t("Editează")} onClick={onEnterEditMode} className={c.btnEdit} />
              {person && (
                <EgButton variant="cancel" text={t("Elimină")} onClick={onRemoveUser} className={c.btnRemove} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
