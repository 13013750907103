import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  btnEdit: {
    margin: "0 0 10px 0 !important",
  },
  btnRemove: {
    color: theme.colors.red,
    "&:hover": {
      color: theme.colors.red,
    },
  },
}));
