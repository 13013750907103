import { gql } from "@apollo/client";

export const GET_PUBLIC_REQUESTS = gql`
  query getPublicRequests(
    $institutionId: Int!
    $search: String
    $year: Int
    $start: Int
    $limit: Int
  ) {
    getPublicRequests(
      institutionId: $institutionId
      search: $search
      year: $year
      start: $start
      limit: $limit
    ) {
      count
      requests {
        id: _id
        number
        status
        birthDate
        childIdnp
        childFirstName
        creationTime
        updateTime
      }
    }
  }
`;
