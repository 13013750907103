import { gql } from "@apollo/client";

const INSTITUTION_CREATE_OR_UPDATE = gql`
  mutation InstitutionCreateOrUpdate(
    $id: Int
    $address: String
    $addresses: [String]
    $grades: GradesInput
    $infrastructure: [String]
    $language: Language
    $name: String
    $phone: String
    $places: [PlacesInput]
    $district: District
  ) {
    institutionCreateOrUpdate(
      _id: $id
      address: $address
      addresses: $addresses

      grades: $grades
      infrastructure: $infrastructure
      language: $language
      name: $name
      phone: $phone
      places: $places
      district: $district
    ) {
      errors {
        message
        field
      }
      institution {
        id: _id
        name
        phone
        address

        language
        district
        places {
          year
          count
        }
        grades {
          superior
          first
          second
          without
        }
        infrastructure
        addresses
      }
    }
  }
`;

export default INSTITUTION_CREATE_OR_UPDATE;
