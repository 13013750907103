import { DefaultTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import trash from "assets/img/trash.svg";

export const useStyles = makeStyles((theme: DefaultTheme) => ({
  formBlock: {
    marginBottom: 50,
  },
  formBlockLabel: {
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 20,
    color: theme.colors.primary,
  },
  formGroup: {
    "@media (min-width: 961px)": {
      marginBottom: 30,
    },
  },
  formGroupLabel: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 18,
    color: theme.colors.default,
  },
  btns: {
    marginBottom: 50,
  },
  btn: {
    marginRight: "20px !important",
    marginTop: "20px !important",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
  },
  removeInputGroup: {
    width: 40,
    height: 40,
    background: `url(${trash}) center center no-repeat`,
    marginLeft: 10,
  },
  select: {
    borderRadius: 15,
    width: "100%",
    height: 58,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    padding: "0 10px",
    fontSize: 18,
    textTransform: "capitalize",
    outline: "none",
    "&:hover": {
      borderColor: "black",
    },
    "&:focus": {
      borderColor: theme.colors.primary,
      borderWidth: 2,
    },
  },
  selectError: {
    borderColor: theme.colors.red,
  },
}));
