import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "components/ui/pageHeader";
import pageHeaderIco from "assets/img/list_ico.svg";
import InstitutionForm from "components/modules/InstitutionForm";
import { useGetInstitution } from "./hooks/useGetInstitution";
import { Loader } from "components/ui/loader";
import useMessage from "main/hooks/useMessage";

const generateArrayOfYears = () => {
  const max = new Date().getFullYear();
  const min = max - 7;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({
      year: i,
      count: 0,
      language: "ro",
    });

    years.push({
      year: i,
      count: 0,
      language: "ru",
    });
  }
  return years;
};

const initialInstitution = {
  name: "",
  phone: "",
  address: "",
  email: "",
  language: "ro",
  places: generateArrayOfYears(),
  grades: {
    superior: 0,
    first: 0,
    second: 0,
    without: 0,
  },
  infrastructure: [],
  addresses: [],
  district: "",
};

const InstitutionEdit: React.FC<{}> = () => {
  const { institutionID } = useParams();
  const isEditMode = institutionID !== undefined;
  const { t } = useTranslation();
  const showMessage = useMessage();

  const { getInstitution, getInstitutionLoading, getInstitutionError, getInstitutionData } = useGetInstitution();

  useEffect(() => {
    if (isEditMode) {
      getInstitution({
        variables: {
          id: Number(institutionID),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (getInstitutionLoading) {
    return <Loader />;
  }

  if (getInstitutionError) {
    return showMessage(getInstitutionError.message, "error");
  }

  const institution = getInstitutionData?.getInstitution || initialInstitution;

  return (
    <>
      <PageHeader
        title={t(isEditMode ? "Editarea grădiniței" : "Grădiniță nouă")}
        subtitle={t(isEditMode ? "Informații despre grădiniță" : "Completați câmpurile")}
        ico={pageHeaderIco}
      />
      <InstitutionForm id={Number(institutionID)} data={institution} />
    </>
  );
};

export default InstitutionEdit;
