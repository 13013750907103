import { CONSTANTS } from "utils/constants/index";

export const getBirthYears = () => {
  const currentYear = new Date().getFullYear();
  let birthYears = [] as number[];

  for (let i = CONSTANTS.childMinAge; i <= CONSTANTS.childMaxAge; i++) {
    birthYears.push(currentYear - i);
  }

  return birthYears;
};
