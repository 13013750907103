import React from "react";
import { Container, useMediaQuery } from "@mui/material";
import { useStyles } from "./styles";
import { Authorization } from "./authorization";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StepInfo } from "./StepInfo";

const Main: React.FC<{}> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMob = useMediaQuery("(max-width: 960px)");
  const maxWidth = process.env.REACT_APP_BRANCH_NAME === "ungheni" ? "1330px" : "1280px";

  return (
    <>
      {!isMob && (
        <div className={classes.hero}>
          {process.env.REACT_APP_BRANCH_NAME !== "balti" && process.env.REACT_APP_BRANCH_NAME !== "ungheni" && (
            <Container maxWidth={false} sx={{ maxWidth: "1280px" }}>
              <div className={classes.heroText}>
                “Educația este
                <div>un orizont,</div>
                <div>nu o destinație”</div>
                <div>Nicolae Iorga</div>
              </div>
            </Container>
          )}
        </div>
      )}
      <StepInfo />
      <Container maxWidth={false} sx={{ maxWidth: maxWidth }}>
        <div className={classes.heading}>
          <div className={classes.title}>
            {t("Autorizați-vă pentru a depune cererea de înscriere a copilului la grădiniță")}
          </div>
          <div className={classes.subtitle}>
            {t("Înainte de completarea formularului, va rugăm să consulați")}{" "}
            <Link to="/gid">{t("Ghidul părintelui")}</Link> {t("și")}{" "}
            <Link to="/question" color="primary">
              {t("Întrebări frecvente")}
            </Link>
          </div>
        </div>
        <Authorization />
        <div className={classes.mainText}>
          {process.env.REACT_APP_BRANCH_NAME !== "balti" && process.env.REACT_APP_BRANCH_NAME !== "ungheni" && (
            <>
              <p>
                <b>{t("Notă")}</b>{" "}
                {t(
                  "În situaţia în care capacitatea unei instituţii de educație preșcolară nu permite cuprinderea tuturor copiilor din districtul şcolar stabilit, se vor repartiza îndreptări copiilor în instituţiile de educație timpurie din districtele şcolare arondate din vecinătate, iar DGETS anual, poate decide reconfigurarea districtelor şcolare, până la demararea procesului de înmatriculare, astfel încât să se asigure înmatricularea tuturor copiilor de 3 ani uniform, până la începutul anului şcolar."
                )}
              </p>
              <p>
                <b>
                  {t(
                    "În cazurile când în instituţia arondată instruirea este asigurată în limba română, iar părinţii solicită o instituţie cu limba rusă, copiii sunt repartizaţi în instituţii cu predarea în limba rusă şi viceversa."
                  )}
                </b>
              </p>
              <p>
                {t(
                  "Luând în consideraţie faptul, că municipiul Chişinău se extinde, sunt înregistrate cartiere cu blocuri noi, cât şi sectoare private, întru asigurarea cu locuri pentru copii în unitatea preşcolară, DGETS la necesitate va face modificările de rigoare referitor la configurarea, reconfigurarea districtelor."
                )}
              </p>
            </>
          )}
          {process.env.REACT_APP_BRANCH_NAME === "balti" && (
            <>
              <p>
                <b>{t("Notă")}</b>{" "}
                {t(
                  "În situaţia în care capacitatea unei instituţii de educație preșcolară nu permite cuprinderea tuturor copiilor din districtul şcolar stabilit, se vor repartiza îndreptări copiilor în instituţiile de educație timpurie din districtele şcolare arondate din vecinătate, iar DÎTS anual, poate decide reconfigurarea districtelor şcolare, până la demararea procesului de înmatriculare, astfel încât să se asigure înmatricularea tuturor copiilor de 3 ani uniform, până la începutul anului şcolar."
                )}
              </p>
              <p>
                <b>
                  {t(
                    "În cazurile când în instituţia arondată instruirea este asigurată în limba română, iar părinţii solicită o instituţie cu limba rusă, copiii sunt repartizaţi în instituţii cu predarea în limba rusă şi viceversa."
                  )}
                </b>
              </p>
              <p>
                {t(
                  "Luând în consideraţie faptul, că municipiul Bălți se extinde, sunt înregistrate cartiere cu blocuri noi, cât şi sectoare private, întru asigurarea cu locuri pentru copii în unitatea preşcolară, DÎTS la necesitate va face modificările de rigoare referitor la configurarea, reconfigurarea districtelor."
                )}
              </p>
            </>
          )}
          {process.env.REACT_APP_BRANCH_NAME === "ungheni" && (
            <>
              <p>
                <b>{t("Notă")}</b>{" "}
                {t(
                  "În situaţia în care capacitatea unei instituţii de educație preșcolară nu permite cuprinderea tuturor copiilor din districtul şcolar stabilit, se vor repartiza îndreptări copiilor în instituţiile de educație timpurie din districtele şcolare arondate din vecinătate, iar IETU anual, poate decide reconfigurarea districtelor şcolare, până la demararea procesului de înmatriculare, astfel încât să se asigure înmatricularea tuturor copiilor de 3 ani uniform, până la începutul anului şcolar."
                )}
              </p>
              <p>
                <b>
                  {t(
                    "În cazurile când în instituţia arondată instruirea este asigurată în limba română, iar părinţii solicită o instituţie cu limba rusă, copiii sunt repartizaţi în instituţii cu predarea în limba rusă şi viceversa."
                  )}
                </b>
              </p>
              <p>
                {t(
                  "Luând în consideraţie faptul, că municipiul Ungheni se extinde, sunt înregistrate cartiere cu blocuri noi, cât şi sectoare private, întru asigurarea cu locuri pentru copii în unitatea preşcolară, IETU la necesitate va face modificările de rigoare referitor la configurarea, reconfigurarea districtelor."
                )}
              </p>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default Main;
