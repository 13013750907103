import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  autocomplete: {
    borderRadius: 15,
    fontSize: 18,
  },
  input: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 15,
    },
    "& .MuiOutlinedInput-input": {
      fontFamily: "Open Sans",
      fontSize: 18,
    },
  },
});
