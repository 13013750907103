interface IRegex {
  name: RegExp;
  idnp: RegExp;
  email: RegExp;
  phone: RegExp;
}

export const regex = {
  name: /[„`±§<>/|"'\\{}[\]_()*&^%$#@~!№%:,.:;?!+=\-\sа-яёА-ЯЁ\d]/gi,
  idnp: /[„`±§<>/|"'\\{}[\]_()*&^%$#@~!№%:,.:;?!+=\-\sа-яёА-ЯЁa-zA-ZÎîĂăȚțȘșÂâ]/gi,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /[„`±§<>/|"'\\{}[\]_()*&^%$#@~!№%:,.:;?!+=\-\sа-яёА-ЯЁa-zA-Z]/gi,
} as IRegex;
